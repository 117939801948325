<template>
  <b-modal id="fusion-claim-xp-modal"
    :size="action !== 'fusion' ? 'md' : 'xl'"
    @show="clearDefault"
    hide-footer hide-header centered>
    <div class="title" v-if="action === 'fusion'">Choose trainer to fuse</div>
    <div class="close" @click="$emit('close-modal')">x</div>
    <div class="content" v-if="action !== 'fusion'">
      Please continue to {{action}} your Trainer on the Bunicorn Game
      <div class="custom-btn sketchy" @click="redirectToBunicornGame()">
        {{action}} now
      </div>
    </div>
    <template v-if="action === 'fusion'">
      <div class="content-wrapper">
        <template v-if="trainers.length > 0">
          <div
            class="trainer-card"
            :class="selectedTrainer === trainer.trainerId ? 'active' : ''"
            v-for="trainer in trainers"
            :key="trainer.trainerId"
            @click="selectTrainer(trainer.trainerId)"
          >
            <img :src="trainer.metaData.medium_image" />
            <div class="info-wrapper">
              <div class="id">ID: {{ trainer.trainerId }}</div>
              <div class="name">{{ trainer.metaData.name}}</div>
            </div>
            <div class="info-wrapper">
              <div class="id">Level: {{ trainer.level + 1 }}</div>
              <div class="name">{{ trainer.hasFused ? 'Fusion Done' : '' }}</div>
            </div>
          </div>
        </template>
        <template v-else >
          <div class="blank-state">No data</div>
        </template>
      </div>
      <b-form-radio-group v-if="trainers.length > 0" v-model="useScroll" class="option-wrapper">
        <b-form-radio value="true" class="option-detail">
          Use {{ this.requireScrolls }} of your total {{ scrollBalance || '-' }} scroll(s) &#38; guarantee 100% fusion success
          for the level {{ this.trainerLevel + 1 }} trainer
          </b-form-radio>
        <b-form-radio value="false" class="option-detail">
          Fusion anyway. The level {{ this.trainerLevel + 1 }} trainer has the fusion
          fail probability of {{ this.fusionFailureRatio }}
        </b-form-radio>
      </b-form-radio-group>
      <div class="pagination-wrapper">
        <b-pagination
          v-model="page"
          :total-rows="totalItem"
          :per-page="limit"
        ></b-pagination>
      </div>
      <div class="footer-wrapper">
        <div
          class="confirm-btn sketchy"
          :class="isDisable ? 'disable' : ''"
          @click="fusionTrainer"
        >
          CONFIRM
          <br />
          {{this.fuseFee | formatWeiAmount}} BUR
        </div>
        <div
          class="cancel-btn sketchy"
          @click="$emit('close-modal')"
        >
          CANCEL
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import rf from '@/requests/RequestFactory';
import { cleanObject } from '@/utils/helpers';
import { mapState } from 'vuex';
import BigNumber from 'bignumber.js';

export default {
  props: {
    action: {
      default: ''
    },
    trainerId: {
      default: null
    },
    trainerElement: {
      default: null
    },
    trainerLevel: {
      type: Number,
      require: false,
      default: 0
    }
  },
  data(){
    return {
      trainers: [],
      selectedTrainer: null,
      fuseFee: -1,
      fusionFailureRatio: '',
      requireScrolls: 0,
      minimumFusionLevel: 0,
      useScroll: 'false',
      page: 1,
      totalItem: 0,
      limit: 4,
    };
  },
  computed: {
    ...mapState('account', ['profile']),
    burBalance() {
      return this.profile.burInGame;
    },
    scrollBalance() {
      return this.profile.scroll;
    },
    isDisable() {
      return !this.selectedTrainer;
    },
  },
  watch: {
    page() {
      this.fetchData();
    }
  },
  mounted(){
    this.fetchFuseFee();
  },
  methods: {
    cleanObject,
    clearDefault(){
      this.page = 1;
      this.totalItem = 0;
      this.selectedTrainer = null;
      this.trainers = [];

      this.fetchData();
    },
    selectTrainer(trainerId) {
      this.selectedTrainer = this.selectedTrainer === trainerId ? null : trainerId;
    },
    redirectToBunicornGame() {
      if(this.action === '' || this.trainerId === null || this.trainerElement === null) return;
      const routeData = process.env.VUE_APP_BUNICORN_GAME
        + `trainers/detail?id=${this.trainerId}&element=${this.trainerElement}`;
      window.open(routeData, '_blank');
    },
    async fetchFuseFee(){
      const res = await rf.getRequest('PureTrainerRequest').getFuseFee(this.trainerId);
      if(res){
        this.fuseFee = res.burRequired;
        this.requireScrolls = res.scrollRequired;
        this.minimumFusionLevel = res.fusionMinLevel;
        this.fusionFailureRatio = res.fusionFailurePercent;
      }
    },
    async fetchData() {
      const params = {
        page: this.page,
        limit: 4,
        levelFrom: this.minimumFusionLevel,
        levelTo: this.trainerLevel,
        fused: this.trainerId
      };
      // Change API later
      const res = await rf.getRequest('TrainerRequest').getAvailableToFuseTrainers(this.cleanObject(params));
      if (res) {
        this.trainers = res.docs;
        this.totalItem = res.totalDocs;
      }
    },
    async fusionTrainer(){
      if(this.isDisable) return;
      if(new BigNumber(this.burBalance).comparedTo(this.fuseFee) < 0){
        this.$dialog.notify.warning('Your BUR is not enough!');
        return;
      }
      if(this.useScroll === 'true' && this.requireScrolls > this.scrollBalance){
        this.$dialog.notify.warning('Not enough scroll(s)!');
        return;
      }
      try {
        const res = await rf.getRequest('TrainerRequest')
          .fuseTrainer(this.trainerId, this.selectedTrainer, this.useScroll === 'true');
        if (res) {
          if(res.fuseSuccess){
            this.$dialog.notify.success(`You've fused trainer ${this.trainerId}!`);
            this.$emit('onFuseTrainersSuccessfully');
          }else{
            this.$dialog.notify.warning('Trainer fusion failure!');
            this.clearDefault();
          }
          this.$store.dispatch('account/getUserProfile');
        }else {
          this.$dialog.notify.error('An error has occurred. Please try again!');
        }
      } catch (error) {
        this.$dialog.notify.error(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/config/_variables";

#fusion-claim-xp-modal {
  .title {
    font-size: 2.5rem;
    text-align: center;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px;
    font-size: 1.25rem;
    cursor: pointer;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .custom-btn {
      margin: 10px 20px 0;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: #e0e0e0;
      }
      &.disable {
        cursor: not-allowed;
        opacity: 0.7;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    padding: 20px 0;
    overflow-x: auto;
    .trainer-card {
      width: 20%;
      min-width: 210px;
      margin: 0 8px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 5px 0 #000;
        transform: scale(1.05);
      }
      &.active {
        box-shadow: 1px 1px 5px 0 #000;
        transform: scale(1.05);
      }
      img {
        width: 100%;
        height: auto;
        margin: 0 auto 20px auto;
        object-fit: contain;
      }
      .info-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .blank-state {
      height: 150px;
      font-size: 2rem;
      display: flex;
      align-items: center;
    }
  }
  .option-wrapper{
    display: flex;
    flex-direction: column;

    .option-detail{
      margin: 4px 0 4px 20%;
      font-size: 1.5rem;
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .confirm-btn,
    .cancel-btn {
      margin: 0 8px;
      padding: 8px;
      min-width: 140px;
      min-height: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 8px;
      font-size: 1.4rem;
      cursor: pointer;
      &:hover {
        background-color: #e0e0e0;
      }
      &.disable {
        cursor: not-allowed;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  #fusion-claim-xp-modal {
    .content {
      padding: 30px 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  #fusion-claim-xp-modal {
    .content-wrapper {
      justify-content: flex-start;
    }
    .option-wrapper{
      .option-detail{
        margin: 4px 0 4px 0%;
      }
    }
  }
}
</style>
