<template>
  <b-modal id="deposit-nft-modal" size="xl" hide-footer hide-header centered>
    <div class="title">Deposit NFT</div>
    <div class="close" @click="closeModal">x</div>
    <vue-step
      :now-step="currentStep"
      :step-list="stepCreateNewList"
      :style-type="'style2'"
      :active-color="'#269aa1'"
    />
    <div class="content-wrapper" v-if="currentStep === 1">
      <div
        class="type-wrapper"
        :class="depositeType === 'trainer' ? 'selected' : ''"
        @click="chooseType('trainer')"
      >
        <img
          class="type-img"
          src="/images/trainer-bunicorn/empty-trainer.png"
        />
        <div class="type-name">Trainer</div>
      </div>
      <div
        class="type-wrapper"
        :class="depositeType === 'bunicorn' ? 'selected' : ''"
        @click="chooseType('bunicorn')"
      >
        <img
          class="type-img"
          src="/images/trainer-bunicorn/empty-bunicorn.png"
        />
        <div class="type-name">Bunicorn</div>
      </div>
    </div>
    <template v-if="currentStep === 2">
      <ChooseBunicorn
        @handleSelectNft="handleSelectNft"
        v-if="depositeType === 'bunicorn'"
      />
      <ChooseTrainer
        @handleSelectNft="handleSelectNft"
        v-if="depositeType === 'trainer'"
      />
    </template>
    <div class="button-wrapper">
      <template v-if="currentStep === 1">
        <custom-button @handleClick="closeModal">Cancel</custom-button>
        <custom-button :isDisabled="!depositeType" @handleClick="handleNextStep">Next</custom-button>
      </template>
      <template v-if="currentStep === 2">
        <custom-button @handleClick="previousStep">Back</custom-button>
        <custom-button
          @handleClick="handleConfirm"
          :isDisabled="isDisabledConfirm()"
          :isLoading="isDepositing"
        >
          Confirm
        </custom-button>
      </template>
    </div>
  </b-modal>
</template>

<script>
import vueStep from 'vue-step';
import ChooseBunicorn from '@/components/DepositNftModal/ChooseBunicorn.vue';
import ChooseTrainer from '@/components/DepositNftModal/ChooseTrainer.vue';
import { depositNft, isUserDenyTxMetaMask } from '@/utils/utils';
import config from '@/config';
import CustomButton from '@/components/CustomButton.vue';

const stepName = ['Choose Type', 'Choose NFT'];
const stepCreateNewList = ['Step 1', 'Complete'];
// import rf from '@/requests/RequestFactory';

export default {
  data() {
    return {
      stepName,
      currentStep: 1,
      stepCreateNewList,
      depositeType: null,
      nftContract: null,
      selectedNftIds: null,
      isDepositing: false
    };
  },
  components: {vueStep, ChooseBunicorn, ChooseTrainer, CustomButton},
  methods: {
    depositNft,
    closeModal() {
      this.selectedNftIds = null;
      this.currentStep = 1;
      this.$bvModal.hide('deposit-nft-modal');
    },
    handleNextStep() {
      if(!this.depositeType) return;
      this.currentStep += 1;
    },
    previousStep() {
      this.currentStep -= 1;
    },
    chooseType(type) {
      this.depositeType = type;
    },
    handleSelectNft(nftList) {
      this.selectedNftIds = nftList;
    },
    isDisabledConfirm() {
      return !this.selectedNftIds || this.selectedNftIds <= 0 || this.currentStep < 2;
    },
    async handleConfirm() {
      if(this.isDisabledConfirm()) return;
      this.isDepositing = true;
      if(this.depositeType === 'trainer') {
        this.nftContract = config.addresses.trainerContract;
      } else if (this.depositeType === 'bunicorn') {
        this.nftContract = config.addresses.bunicornContract;
      } else {
        return;
      }
      try {
        const res = await depositNft(this.nftContract, this.selectedNftIds);
        if(res) {
          this.$dialog.notify.success(`You've deposited ${this.selectedNftIds.length} ${this.depositeType}(s) successfully!`);
          this.closeModal();
        }
      } catch(error) {
        console.log(error);
        if(isUserDenyTxMetaMask(error)){
          this.$dialog.notify.error('You\'ve rejected this transaction');
        }
      }
      this.isDepositing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/config/_variables";

.title {
  font-size: 2.5rem;
  text-align: center;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px;
  font-size: 1.25rem;
  cursor: pointer;
}
.content-wrapper {
  display: flex;
  justify-content: center;

  .type-wrapper {
    margin: 40px 40px 0;
    text-align: center;
    cursor: pointer;
    &.selected {
      .type-img {
        box-shadow: 1px 1px 5px 0 #000;
        border-radius: 8px;
        transform: scale(1.05);
      }
    }
    .type-img {
      height: 235px;
      width: auto;
    }
    .type-name {
      margin-top: 20px;
    }
  }
}
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin: 20px 8px;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .title {
    font-size: 1.5rem;
  }
  .content-wrapper{
    .type-wrapper {
      margin: 40px 6px 0;
    }
  }

}
</style>
