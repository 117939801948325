import BaseRequest from './BaseRequest';
import config from '@/config';

export default class BattleRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.authenticationApi;
  }
  getUserAccount(){
    const url = '/auth/account';
    return this.get(url, {});
  }
  updateUserAccount(params: any) {
    const url = '/auth/account';
    return this.put(url, params);
  }
  checkExistedUsername(username: string) {
    const url = `/auth/${username}/existing`;
    return this.get(url, {});
  }
}