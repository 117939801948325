import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VTooltip from 'v-tooltip';
import BigNumber from 'bignumber.js';
import BootstrapVue, { BootstrapVueIcons, ToastPlugin } from 'bootstrap-vue';
// @ts-ignore
import BootstrapVueDialog from 'bootstrap-vue-dialog';
import VueClipboard from 'vue-clipboard2';
// @ts-ignore
import { VueReCaptcha } from 'vue-recaptcha-v3';

import Web3 from 'web3';
import store from './store/index';
import createRouter from './router';

import App from './App.vue';
import MaintainMode from './views/MaintainMode.vue';
import { Dropdown } from 'floating-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'floating-vue/dist/style.css';
import config from '@/config';

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN });
BigNumber.config({ EXPONENTIAL_AT: 100 });

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VTooltip);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueDialog);
Vue.use(ToastPlugin);
Vue.use(BootstrapVueIcons);
Vue.component('VDropdown', Dropdown);
const router = createRouter();

if (process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY) {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  });
}

Vue.filter(
  'formatWeiAmount',
  function (wei: string, roundMode = BigNumber.ROUND_HALF_CEIL) {
    if (new BigNumber(wei || 0).isZero()) return '0';
    let numberStr = new BigNumber(wei).dividedBy(1e18).toFixed(4, roundMode);
    numberStr = new BigNumber(numberStr).toString();

    return new BigNumber(numberStr).toFormat();
  }
);

Vue.filter('formatNumber', function (dec: string) {
  if (new BigNumber(dec || 0).isZero()) return '-';
  let numberStr = new BigNumber(dec).toFixed(4, BigNumber.ROUND_DOWN);
  numberStr = new BigNumber(numberStr).toString();

  return new BigNumber(numberStr).toFormat();
});

Vue.filter('formatPercent', function (value: string) {
  if (new BigNumber(value || 0).isZero()) return '- %';
  const numberStr = new BigNumber(value).multipliedBy(100).toFixed(2);

  return `${numberStr} %`;
});

const web3 = new Web3(
  Web3.givenProvider ||
    new Web3.providers.HttpProvider(config.configWallet.rpcUrl[0])
);

new Vue({
  provide: {
    web3,
  },
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
