<template>
  <div v-tooltip="tooltipText">
    <button
      class="custom-button sketchy"
      @click="$emit('handleClick')"
      :disabled="isDisabled || isLoading"
      :class="[isDisabled ? 'disabled' : '']"
    >
      <span v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></span>
      <slot v-else></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: null,
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/config/_variables";
.custom-button {
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: #e0e0e0;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      background-color: #ffffff;
    }
  }
}
</style>