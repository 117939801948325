<template>
  <div class="detail-container">
    <div class="back-button" @click="$emit('handleBackButton')">
      <img src="/images/back-button.png"/>
    </div>
    <div v-if="isWarningHalvingRate" class="warning">
      ⚠️ Skip this gold halving or your gold rate will decrease!
    </div>
    <div class="info">
      <div class="image-wrapper">
        <img class="image" :src="imageUrl" />
        <div class="id" v-if="id">ID: {{ id }}</div>
      </div>
      <div class="summary">
        <div class="name">
          <div class="first-block">
            {{ name }}
            <slot name="more-info" />
          </div>
          <slot name="config-page" />
        </div>
        <div class="description">
          {{ description }}
        </div>
        <slot name="more-action" />
      </div>
    </div>
    <div class="row-wrapper" v-for="(row, index) in rows" :key="index">
      <div class="header-wrapper">
        <div class="title">{{ row.title }}</div>
        <slot name="row-action" :data="row"/>
      </div>
      <div class="content">
        <slot
          v-for="(item, index) in row.data"
          :index="index"
          name="row-data"
          :item="item"
        />
      </div>
      <div>
        <template v-if="!row.data || row.data.length === 0">
          <div class="no-data">
            No display data
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['isWarningHalvingRate', 'imageUrl', 'id', 'name', 'description', 'rows'],
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';

.detail-container {
  display: flex;
  flex-direction: column;
  position: relative;
  .back-button {
    cursor: pointer;
    img {
      width: auto;
      height: 40px;
    }
  }
  .warning {
    margin: 10px 0;
    text-align: center;
    background-color: #fefe97;
    border-radius: 4px;
    padding: 8px 16px;
  }
  .info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .image-wrapper {
      width: 30%;
      .image {
        width: 100%;
        max-height: 300px;
        object-fit: contain;
      }
      .id {
        width: max-content;
        margin: 20px auto 0;
        padding: 4px 20px;
        border-radius: 8px;
        border: 1px solid black;
      }
    }
    .summary {
      margin-left: 60px;
      flex: 1;
      .name {
        width: 100%;
        font-size: 3rem;
        word-spacing: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .first-block {
          display: flex;
          align-items: center;
        }
      }
      .description {
        font-size: 1.25rem;
        word-spacing: 4px;
      }
    }
  }
  .row-wrapper {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    min-height: 300px;
    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .title {
        font-size: 2.5rem;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  .detail-container {
    width: 95vw;
    margin: auto;
    .info {
      flex-direction: column;
      align-items: center;
      .image {
        width: 50%;
        object-fit: contain;
      }
      .summary {
        width: 95vw;
        margin: auto;
        .name {
          margin-top: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .detail-container {
    .info {
      .image {
        width: 80%;
      }
      .summary {
        .name {
          margin-top: 16px;
          text-align: center;
          .first-block {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
.no-data {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  line-height: 200px;
  text-align: center;
  font-size: 20px;
}
</style>
