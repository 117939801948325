<template>
  <b-modal id="win-count-info" hide-footer hide-header size="xl" centered>
    <div class="close" @click="$emit('close-modal')">x</div>
    <div class="content">
      <div class="row">
        <div class="title">Win Count:</div>
        <div class="info" v-for="(item, index) in winCount" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="row">
        <div class="title">Bonus Stats:</div>
        <div class="info" v-for="(item, index) in bonusStats" :key="index">
          {{ item | formatPercent }}
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      winCount: [5, 15, 35, 60, 90, 120, 160, 200, 250],
      bonusStats: [0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.5],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';

#win-count-info {
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px;
    font-size: 1.25rem;
    cursor: pointer;
  }
  .content {
    padding: 30px;
    overflow-x: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      .title,.info {
        text-align: center;
        border: 1px solid #e0e0e0;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        min-width: 150px;
        white-space: nowrap;
        font-size: 2rem;
      }
      .info {
        min-width: 70px;
        text-align: center;
        font-size: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
#win-count-info {
   .content {
     padding: 30px 20px;
     align-items: flex-start;
   }
}
}
</style>
