import LockConnector from '../connector';

export default class Connector extends LockConnector {
  async connect() {
    let provider;
    // @ts-ignore
    if (window['ethereum']) {
      const connectorOptions = this.options.options;

      if (connectorOptions.chainId === 56 && this.options.id === 'metamask') {
        await this.connectToCustomNetwork();
      } else {
        await this.connectNormally();
      }
      //@ts-ignore
      provider = window['ethereum'];
      // @ts-ignore
    } else if (window['web3']) {
    // @ts-ignore
      provider = window['web3'].currentProvider;
    }

    return provider;
  }

  async isLoggedIn() {
    // @ts-ignore
    if (!window['ethereum']) return false;
    // @ts-ignore
    if (window['ethereum'].selectedAddress) return true;
    await new Promise(r => setTimeout(r, 400));
    // @ts-ignore
    return !!window['ethereum'].selectedAddress;
  }

  connectNormally() {
    // @ts-ignore
    if (!window['ethereum']) {
      return;
    }
    // @ts-ignore
    return window['ethereum'].enable();
  }
  async connectToCustomNetwork() {
    // @ts-ignore
    if (!window['ethereum']) {
      return;
    }
    const connectorOptions = this.options.options;
    const chainId = '0x' + connectorOptions.chainId.toString(16);

    //@ts-ignore
    await window['ethereum'].request({
      method: 'wallet_addEthereumChain',
      params: [
        Object.assign({}, connectorOptions, {
          chainId
        })
      ]
    });

    // @ts-ignore
    return window['ethereum'].enable();
  }
}
