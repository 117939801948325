<template>
  <div v-if="!isMobileScreen" class="main-nav">
    <div class="left-block">
      <router-link to="/" class="nav-logo">
        <img
          src="../assets/icon/logo.png"
          class="logo d-inline-block align-top"
          alt="Logo"
        />
      </router-link>
      <div class="player-level" v-if="profile">
        Player Level
        <progress-circle
          :completed-steps="Number(profile.exp)"
          :total-steps="Number(profile.expNextLevel)"
          :start-color="'#2A850C'"
          :end-color="'#2A850C'"
          :stop-color="'#B8E847'"
          :circle-color="'#a6a4a5'"
          :circle-width="6"
          :diameter="50"
        >
          {{ profile.level + 1 }}
        </progress-circle>
         EXP: {{ profile.exp }}/{{ profile.expNextLevel }}
      </div>
    </div>

    <div class="user-profile">
      <template v-if="profile">
        <BurInfoDropdown :burBalance="burBalance"/>
         <div class="assets">BUNI: {{ buniBalance | formatWeiAmount }}</div>
        <div class="assets">BUR: {{ burBalance | formatWeiAmount }}</div>
        <div class="assets">GOLD: {{ goldBalance | formatNumber }}</div>
        <div class="assets">FOOD: {{ foodBalance | formatNumber }}</div>
      </template>
      <div class="connect-btn sketchy" @click="$bvModal.show('account-modal')">
        <template v-if="!myAccount"> Connect to a wallet</template>
        <template v-else>
        {{ !wrongNetwork ? shortenAddress(myAccount) : 'Wrong Network' }}
        </template>
      </div>
    </div>
    <account-modal
      @close-connect-wallet-modal="$bvModal.hide('account-modal')"
    />
  </div>
  <div class="main-nav" v-else>
    <router-link to="/" class="nav-logo">
      <img
        src="../assets/icon/logo.png"
        class="logo d-inline-block align-top"
        alt="Logo"
      />
    </router-link>
    <div @click="visible = !visible" class="navbar-toggle">
      <template>
        <div v-if="visible">
          <div class="close-toggle">X</div>
        </div>
        <div v-else>
          <img
            src="../assets/icon/menu-mobile.png"
            class="logo-mobile d-inline-block align-top"
          />
        </div>
      </template>
    </div>
    <b-collapse is-nav v-model="visible">
      <template v-if="profile">
         <b-navbar-nav class="ml-auto">
          <div class="assets d-flex">
            BUR: {{ burBalance | formatWeiAmount }}
            <BurInfoDropdown class="ml-2" :burBalance="burBalance"/> 
          </div>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <div class="assets">BUNI: {{ buniBalance | formatWeiAmount }}</div>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <div class="assets">GOLD: {{ goldBalance | formatNumber }}</div>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <div class="assets">FOOD: {{ foodBalance | formatNumber }}</div>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <div class="player-level" v-if="profile">
            Player Level
            <progress-circle
              :completed-steps="Number(profile.exp)"
              :total-steps="Number(profile.expNextLevel)"
              :start-color="'#2A850C'"
              :end-color="'#2A850C'"
              :stop-color="'#B8E847'"
              :circle-color="'#a6a4a5'"
              :circle-width="6"
              :diameter="50"
              class="progress"
            >
              {{ profile.level + 1 }}
            </progress-circle>
          </div>
        </b-navbar-nav>
      </template>
      <div class="connect-btn sketchy" @click="$bvModal.show('account-modal')">
        {{ myAccount ? shortenAddress(myAccount) : 'Connect to a wallet' }}
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Vue from 'vue';
import AccountModal from './AccountModal.vue';
import { mapState } from 'vuex';
import { convertWeiToDec } from '@/utils/helpers';
// import rf from '@/requests/RequestFactory';
import { ProgressCircle } from 'vue-progress-circle';
import BurInfoDropdown from '@/components/BurInfoDropdown.vue';

export default Vue.extend({
  data() {
    return {
      windowWidth: window.innerWidth,
      resizeEvent: null,
      fill: { gradient: ['red', 'green', 'blue'] },
      visible: false,
    };
  },
  computed: {
    ...mapState('account', ['account', 'currentNetworkId', 'profile']),
    myAccount() {
      return this.account.address;
    },
    burBalance() {
      return this.profile.burInGame;
    },
    buniBalance() {
      return this.profile.buniInGame;
    },
    goldBalance() {
      return this.profile.gold;
    },
    foodBalance() {
      return this.profile.food;
    },
    isMobileScreen() {
      if (this.windowWidth > 768) return false;
      return true;
    },
    myAccessToken() {
      return this.$store.state.account.account.accessToken;
    },
    tooltip() {
      return '<img src="/public/images/elements/air.png">';
    },
    wrongNetwork() {
      return this.$store.state.account.account.wrongNetwork;
    }
  },
  mounted() {
    this.resizeEvent = window.addEventListener('resizeWindow', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  watch: {
    myAccessToken() {
      this.$store.dispatch('account/getUserProfile');
    },
    // myAccount(){
    //   this.$store.dispatch('account/getUserProfile');
    // }
  },
  methods: {
    convertWeiToDec,
    shortenAddress(str) {
      return str ? `${str.slice(0, 6)}...${str.slice(str.length - 4)}` : str;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resizeWindow', this.resizeEvent);
  },
  components: {
    AccountModal,
    ProgressCircle,
    BurInfoDropdown
  },
});
</script>

<style scoped lang="scss">
@import '@/config/_variables';
::v-deep .navbar-collapse {
  margin-top: 16px;
}
::v-deep .tooltip-inner {
  padding: 20px 20px;
  width: 260px;
  min-width: none;
  display: flex;
  justify-content: space-between;
}
.close-toggle {
  width: 24px;
  height: 24px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
}
.main-nav {
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 1366px;
  margin: auto;
  ::v-deep .navbar-nav {
    .assets {
      margin: 8px;
    }
  }

  .player-level {
    display: flex;
    align-items: center;
    margin: 0 8px;
    .progress {
      margin-left: 8px;
    }
  }

   .nav-logo {
      img {
        height: 40px;
        width: auto;
      }
    }
  .left-block,
  .navbar-toggle {
    display: flex;
    align-items: center;

    .player-level {
      margin-left: 60px;
      display: flex;
      align-items: center;
    }
  }
  .button-wrapper {
    .battle {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .user-profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .assets {
    margin: 0 8px;
    .expand-icon {
      cursor: pointer;
    }
  }
  .connect-btn {
    padding: 8px 20px;
    margin: 0 8px;
    border-radius: 8px;
    height: fit-content;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  .main-nav {
    width: 95vw;
    margin: 0 20px;
    .player-level {
      .progress {
        background: none;
      }
    }
    .user-profile {
      flex-wrap: wrap;
      justify-content: flex-end;
      div {
        margin: 8px;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-tablet) {
  .main-nav {
    flex-wrap: wrap;
  }
}
</style>
