import BigNumber from 'bignumber.js';
// @ts-ignore
import numeral from 'numeral';
import Vue from 'vue';
import * as trainerXp from '../trainer-xp.json';
import config from '@/config';
import store from '@/store';
import { Contract as BscContract } from '@ethersproject/contracts';
import abi from '../../abi';
import Web3 from 'web3';
import { MaxUint256 } from '@ethersproject/constants';
import { estimateGas, fetchUserBalance, sleep, convertDecToWei } from '@/utils/helpers';

const xpBylevel: Record<string, string[]> = (trainerXp as any).default || trainerXp;

const retry = require('async-retry');

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function formatNumber(number: number | string, key: string) {
  if (new BigNumber(number || 0).isZero()) return '-';
  if (number < 1e-8 && !key) return '< 0.00000001';

  let format = '0.[000]';
  if (number > 100000) format = '0.[0]a';
  if (number < 1) {
    number = new BigNumber(number).toFixed(4, BigNumber.ROUND_DOWN);
    format = '0.[0000]';
  }

  if (key === 'long') {
    format = '0,000.[00]';
    if (number < 1) format = '0.[0000]';
  }

  if (key === 'usd') {
    format = '(0.[0000])';
    if (number > 100000) format = '(0.[0]a)';
    if (number < 1) format = '(0.[0000])';
    if (number < 1e-4) return '< 0.0001';
  }
  if (key === 'usd-long' && number < 1e-4) return '< 0.0001';

  if (key === 'usd-long') {
    format = '$(0,000.[00])';
    if (number < 1) format = '$(0.[0000])';
  }

  if (key === 'percent') format = '(0.[00])%';
  if (key === 'percent-short') format = '(0)%';
  if (key === 'percent-large') format = '(0,000.[00])%';

  if (number < 0.0000000001 && key === 'percent') {
    return '< 0.0000000001%';
  }

  if (number < 0.0001 && !key) {
    const roundNumber = new BigNumber(number).toFixed(8, 1);
    return new BigNumber(roundNumber).toString();
  }

  if (!key && format === '0.[000]') {
    const roundNumber = new BigNumber(number).toFixed(3, BigNumber.ROUND_DOWN);
    return new BigNumber(roundNumber).toString();
  }

  return numeral(number)
    .format(format)
    .toUpperCase();
}

export function eventGoogleAnalytic(
  action: any,
  category: any,
  label: any,
  data: any
) {
  Vue.prototype.$gtag.event(action, {
    event_category: category,
    event_label: label,
    value: data
  });
  return;
}
export const getExchangeUrl = (buyCurrency: string) => {
  if (buyCurrency === 'bur') {
    return 'https://bunicorn.exchange/#/trade/tokens?assetIn=bnb&assetOut=0xc1619D98847CF93d857DFEd4e4d70CF4f984Bd56';
  }
  if (buyCurrency === 'buni') {
    return 'https://bunicorn.exchange/#/trade/tokens?assetIn=bnb&assetOut=0x0E7BeEc376099429b85639Eb3abE7cF22694ed49';
  }
  return 'https://bunicorn.exchange/#/trade/tokens';
};

export const isMobile = () => {
  return window.screen.width <= 768;
};
export const isMMobile = () => {
  return window.screen.width <= 425;
};

export const isZero = (number: any) => {
  return new BigNumber(number || 0).isZero();
};

export const customRetry = async (callback: any, numberRetry: number = 3) => {
  await retry(callback, {
    retries: numberRetry,
    minTimeout: 2 * config.configWallet.blockTime,
    maxTimeout: 3 * config.configWallet.blockTime
  });
};

export function nFormatterRoundUp(num: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? Math.round((num / item.value) * 10) / 10 + item.symbol : '0';
}

export function shortenAddress(address: string) {
  return address
    ? `${address.slice(0, 6)}...${address.slice(address.length - 4)}`
    : address;
}

export function getSocialLink() {
  return window.location.href + '&type=sharelink';
}

export function isShareLink() {
  const url = window.location.href;
  return url.includes('&type=sharelink');
}

export function getBoardDateString(timestamp: any) {
  return new Date(timestamp * 1000).toISOString().substring(0, 19).replace('T', ' ') + ' UTC';
}

export function getIslandSourceImage(key: string) {
  switch (key) {
  case 'main_island':
    return '/images/islands/main_island.png';
  case 'kala_island':
    return '/images/islands/desert_island.png';
  case 'moana_island':
    return '/images/islands/ice_island.png';
  case 'keone_island':
    return '/images/islands/krypton_island.png';
  case 'bane_island':
    return '/images/islands/lava_island.png';
  case 'lala_island':
    return '/images/islands/water_island.png';
  }
}

export function getBuniHouseSourceImage(key: string) {
  switch (key) {
  case 'earth':
    return '/images/buni-houses/buni_house_lv_7_earth.png';
  case 'fire':
    return '/images/buni-houses/buni_house_lv_7_fire.png';
  case 'water':
    return '/images/buni-houses/buni_house_lv_7_water.png';
  case 'air':
    return '/images/buni-houses/buni_house_lv_7_air.png';
  }
}

export function getXpBylevel(level: number, sum: any) {
  if (level === null) {
    return null;
  }
  let sumXp = 0;
  if (sum) {
    for (let i = 0; i <= level; i++) {
      sumXp += parseInt(xpBylevel[i + 1].toString(), 10);
    }
    return sumXp;
  }
  return xpBylevel[level + 1];
}

export async function depositNft(nftContractAddr: string, nftIds: []) {
  if (!nftIds || nftIds.length === 0) return;
  const userProvider = await store.getters['account/userProvider'];
  const NFTContract =
    nftContractAddr === config.addresses.bunicornContract
      ? new BscContract(
        config.addresses.bunicornContract,
        abi['BunicornsV2'],
        userProvider.getSigner()
      )
      : new BscContract(
        config.addresses.trainerContract,
        abi['TrainersV2'],
        userProvider.getSigner()
      );
  const islandContract = new BscContract(
    config.addresses.islandContract,
    abi['IslandNFTLocker'],
    userProvider.getSigner()
  );
  try {
    const isApprovedAll = await NFTContract.isApprovedForAll(
      //@ts-ignore
      store.state.account.account.address || '',
      config.addresses.islandContract
    );

    if (!isApprovedAll) {
      const approveRes = await NFTContract.setApprovalForAll(
        config.addresses.islandContract,
        true
      );
      await userProvider.waitForTransaction(
        approveRes.hash,
        Web3.givenProvider ? 1 : 6
      );
    }
  } catch (error: any) {
    console.log(error);
    if (error.code && error.code === 4001) {
      throw error;
    }
  }
  const gasLimit = await estimateGas(islandContract, 'deposits', [
    nftContractAddr,
    nftIds
  ]);

  const overrides = {
    gasLimit
  };

  const res = await islandContract.deposits(
    nftContractAddr,
    nftIds,
    overrides
  );
  await sleep(3 * config.configWallet.blockTime);
  const transactionHash = res.hash;
  return transactionHash;
}

export async function fetchBurBalance() {
  //@ts-ignore
  if (!store.state.account.account.address) return;
  try {
    //@ts-ignore
    const burBalance = await fetchUserBalance(config.addresses.burTokenContract, store.state.account.account.address);
    if(burBalance || burBalance === 0) {
      return burBalance;
    }
  } catch(error: any) {
    console.log(error);
  }
}

export async function fetchBuniBalance() {
  //@ts-ignore
  if (!store.state.account.account.address) return;
  try {
    //@ts-ignore
    const buniBalance = await fetchUserBalance(config.addresses.buniTokenContract, store.state.account.account.address);
    if(buniBalance || buniBalance === 0) {
      return buniBalance;
    }
  } catch(error: any) {
    console.log(error);
  }
}

export async function fetchScrollBalance() {
  //@ts-ignore
  if (!store.state.account.account.address) return;
  try {
    //@ts-ignore
    const scrollBalance = await fetchUserBalance(config.addresses.scrollTokenContract, store.state.account.account.address);
    if(scrollBalance || scrollBalance === 0) {
      return scrollBalance;
    }
  } catch(error: any) {
    console.log(error);
  }
}

export async function withdrawNft(params: any) {
  if (!params.selectedNftIds || params.selectedNftIds.length === 0) return;
  const userProvider = await store.getters['account/userProvider'];
  const islandContract = new BscContract(
    config.addresses.islandContract,
    abi['IslandNFTLocker'],
    userProvider.getSigner()
  );
  const gasLimit = await estimateGas(islandContract, 'withdraws', [
    params.nftContract,
    params.selectedNftIds,
    params.requestId,
    params.signature
  ]);

  const overrides = {
    gasLimit
  };
  try {
    const res = await islandContract.withdraws(
      params.nftContract,
      params.selectedNftIds,
      params.requestId,
      params.signature,
      overrides
    );
    if (res && res.hash) {
      await sleep(3 * config.configWallet.blockTime);
      const transactionHash = res.hash;
      return transactionHash;
    }
  } catch (error: any) {
    console.log(error);
    if (error.code && error.code === 4001) {
      throw error;
    }
  }
}

async function handleApprove(
  provider: any,
  contract: any,
  tokenContract: any,
  from: any,
  feeIn: any
) {
  const allowance = await tokenContract.allowance(from, contract.address);
  if(new BigNumber(feeIn).lte(allowance.toString())) {
    return null;
  }
  try {
    const approveRes = await new BscContract(tokenContract.address, abi['ERC20'], provider.getSigner())
      .approve(contract.address, MaxUint256.toString());
    await provider.waitForTransaction(
      approveRes.hash,
      2
    );
  } catch (e: any) {
    console.error('handleApprove error:', e);
    console.error('handleApprove error code:', e.code, feeIn.toString(), tokenContract.address, MaxUint256.toString());
    if (e.code && e.code === 4001) {
      throw e;
    }
    const approveRes = await new BscContract(tokenContract.address, abi['ERC20'], provider.getSigner())
      .approve(contract.address, feeIn.toString());
    await provider.waitForTransaction(
      approveRes.hash,
      2
    );
  }
}

async function approveBuniFee(
  provider: any,
  gameContract: any,
  BuniToken: any,
  from: any,
  priceBuni: string
) {
  const feeInBuni = new BigNumber(priceBuni);

  await handleApprove(
    provider,
    gameContract,
    BuniToken,
    from,
    feeInBuni
  );
}

export function isUserDenyTxMetaMask(metamaskRes: any){
  if(metamaskRes === null || typeof metamaskRes === 'undefined')
    return false;

  if(metamaskRes.code === 4001 && typeof metamaskRes.message === 'string'
    && metamaskRes.message.indexOf('User denied transaction signature') > 0)
    return true;

  return false;
}

export async function moveToOffAsset(tokenAddress: any, tokenAmount: any) {
  const userProvider = await store.getters['account/userProvider'];
  const gameContract = new BscContract(
    config.addresses.gameContract,
    abi['BuniUniversalV2'],
    userProvider.getSigner()
  );
  const tokenContract = new BscContract(
    tokenAddress,
    abi['ERC20'],
    userProvider.getSigner()
  );

  await approveBuniFee(
    userProvider,
    gameContract,
    tokenContract,
    //@ts-ignore
    store.state.account.account.address || '',
    new BigNumber(1).toString()
  );

  // Gas estimation
  const gasLimit = await estimateGas(gameContract, 'depositERC20ToIsland', [
    tokenAddress,
    tokenAmount
  ]);

  const overrides = {
    gasLimit
  };

  const res = await gameContract.depositERC20ToIsland(tokenAddress, tokenAmount, overrides);
  await userProvider.waitForTransaction(res.hash, Web3.givenProvider ? 1 : 6);

  // TODO update balance
  return res;
}

export async function moveToOnChain(tokenAddress: string, method: any, tokenAmount: any, requestId: any, signature: any) {
  if (!tokenAmount || !method || !requestId || !signature) return;
  const userProvider = await store.getters['account/userProvider'];
  const tokenContract = new BscContract(
    tokenAddress,
    abi['ERC20'],
    userProvider.getSigner()
  );
  const gameContract = new BscContract(
    config.addresses.gameContract,
    abi['BuniUniversalV2'],
    userProvider.getSigner()
  );
  await approveBuniFee(
    userProvider,
    gameContract,
    tokenContract,
    //@ts-ignore
    store.state.account.account.address || '',
    new BigNumber(1).toString()
  );
  if (method === 'claimBurInGameBuniIslandWithSig') {
    // Gas estimation
    const gasLimit = await estimateGas(gameContract, 'claimTokenRewardsWithSig', [
      tokenAmount,
      requestId,
      signature
    ]);
    const overrides = {
      gasLimit
    };
    const res = await gameContract.claimTokenRewardsWithSig(tokenAmount, requestId, signature, overrides);
    await userProvider.waitForTransaction(res.hash, Web3.givenProvider ? 1 : 6);
    return res;
  } else if (method === 'claimBuniInGameBuniIslandWithSig') {
    // Gas estimation
    const gasLimit = await estimateGas(gameContract, 'claimBuniRewardsWithSig', [
      tokenAmount,
      requestId,
      signature
    ]);
    const overrides = {
      gasLimit
    };
    const res = await gameContract.claimBuniRewardsWithSig(tokenAmount, requestId, signature, overrides);
    await userProvider.waitForTransaction(res.hash, Web3.givenProvider ? 1 : 6);
    return res;
  } else {
    return;
  }
}

export function stringIsNullOrEmpty(input: string){
  if(typeof input === 'undefined' || input === null || input === '') return true;
  if(input.replace(/\s+/g, '') === '') return true;
  return false;
}

export function convertTimeISO(timeISOString: string) {
  return timeISOString.substring(0, 19).replace('T', ' ') + ' UTC';
}


