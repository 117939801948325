import BaseRequest from './BaseRequest';
import config from '@/config';

export default class UserRequestV2 extends BaseRequest {
  getUrlPrefix() {
    return config.api.usersApi;
  }
  withdrawBurInGame() {
    const url = '/my/users/claim-bur-ingame-buni-island';
    return this.post(url, {});
  }
  withdrawBuniInGame() {
    const url = '/my/users/claim-buni-ingame-buni-island';
    return this.post(url, {});
  }
  getSignatureRequests(status: string, page: number, limit: number) {
    const url = '/my/signature-requests';
    return this.get(url, {status, page, limit});
  }
}
