import Vue from 'vue';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import config from '@/config';
import store from '@/store';
import abi from '../../abi';
import { Contract as BscContract } from '@ethersproject/contracts';
import {estimateGas, convertDecToWei, sleep, handleTransactionError} from '@/utils/helpers';
import provider from '@/utils/providers';

const MAX_MARKET_PRICE = 100000;
const ONE_HUNDRED_YEARS = 3153600000;

export async function listOnChainNft(nftContractAddr: string, nftId: string | number, price: string | number) {
  if(!nftId) return;
  if(nftContractAddr !== config.addresses.bunicornContract && nftContractAddr !== config.addresses.trainerContract) return;
  let sellPrice: string | number = Math.min(+price, MAX_MARKET_PRICE);
  sellPrice = convertDecToWei(new BigNumber(sellPrice || 0).toString());
  if(new BigNumber(sellPrice || 0).isZero()) return;

  try {
    const userProvider = await store.getters['account/userProvider'];
    const sellerAddress = store.getters['account/userAddress'];
    const NFTContract =
      nftContractAddr === config.addresses.bunicornContract
        ? new BscContract(
          config.addresses.bunicornContract,
          abi['BunicornsV2'],
          userProvider.getSigner()
        )
        : new BscContract(
          config.addresses.trainerContract,
          abi['TrainersV2'],
          userProvider.getSigner()
        );
    const marketContract = new BscContract(
      config.addresses.marketContract,
      abi['NFTMarketV2'],
      userProvider.getSigner()
    );

    try {
      const isApprovedAll = await NFTContract.isApprovedForAll(
        sellerAddress || '',
        config.addresses.marketContract
      );

      if (!isApprovedAll) {
        const approveRes = await NFTContract.setApprovalForAll(
          config.addresses.marketContract,
          true
        );
        await provider.waitForTransaction(
          approveRes.hash,
          Web3.givenProvider ? 1 : 6
        );
      }
    } catch (e) {
      //@ts-ignore
      if (e.code && e.code === 4001) {
        throw e;
      }
      const approveRes = await NFTContract.approve(
        config.addresses.marketContract,
        nftId
      );
      await provider.waitForTransaction(
        approveRes.hash,
        Web3.givenProvider ? 1 : 6
      );
    }

    // Gas estimation
    const gasLimit = await estimateGas(marketContract, 'addListing', [
      nftContractAddr,
      nftId,
      sellPrice,
      config.addresses.buniTokenContract,
      Math.floor(new Date().valueOf()/ 1000),
      Math.floor(new Date().valueOf()/ 1000)+ONE_HUNDRED_YEARS,
      false
    ]);
    const overrides = {
      gasLimit
    };

    let res = await marketContract.addListing(
      nftContractAddr,
      nftId,
      sellPrice,
      config.addresses.buniTokenContract,
      Math.floor(new Date().valueOf()/ 1000),
      Math.floor(new Date().valueOf()/ 1000)+ONE_HUNDRED_YEARS,
      false,
      overrides
    );
    res = await provider.waitForTransaction(
      res.hash,
      Web3.givenProvider ? 1 : 6
    );

    await sleep(3 * config.configWallet.blockTime);
    return { seller: sellerAddress, nftId, price: sellPrice, transactionHash: res.transactionHash } as {
      seller: string;
      nftId: string;
      price: string;
      transactionHash: string;
    };
  } catch (e) {
    const message = handleTransactionError(e);
    Vue.prototype.$dialog.notify.error(message);
  }
}