//@ts-nocheck
import config from '@/config';
// import {abi as multicallAbi} from '../../abi/Multicall.json';
import {Contract as BscContract, Contract} from '@ethersproject/contracts';
import {Interface} from '@ethersproject/abi';
import {Provider} from '@ethersproject/providers';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import abi from '../../abi';
import myProvider from '@/utils/providers';
import store from '@/store';
import { EGG_META_DATA } from '@/contants';

const MULTICALL = config.configWallet.addresses.multicall;

export function isTxRejected(error: {code: number, message: string}) {
  if (!error) {
    return false;
  }
  return error.code === 4001;
}

export function handleTransactionError(transactionError: any, useOffChain = false) {
  let messages = 'Oops, something went wrong. Please reload page';
  if (isTxRejected(transactionError)) {
    messages = 'You\'ve rejected this transaction';
    if (useOffChain) {
      messages = 'You have rejected this transaction. Check your transactions in Pending';
    }
  } else {
    console.error('transactionError', transactionError);
  }

  return messages;
}

// export async function multicall(provider: Provider, abi: [], calls: Call[]) {
//   try {
//     const multi = new Contract(MULTICALL, multicallAbi, provider);
//     const itf = new Interface(abi);
//     const calldata = calls.map((call: Call) => {
//       return [
//         call.address.toLowerCase(),
//         itf.encodeFunctionData(call.name, call.params)
//       ];
//     });

//     const { returnData } = await multi.aggregate(calldata);

//     return returnData.map((call: string, i: number) =>
//       itf.decodeFunctionResult(calls[i].name, call)
//     );
//   } catch (e) {
//     console.error('[multicall]', e.message);
//     return null;
//   }
// }

export function convertDecToWei(amount: string) {
  return new BigNumber(amount.toString()).multipliedBy(1e18).toString();
}

export function convertWeiToDec(wei: string, decimalPlaces: number = 4) {
  if (wei === undefined) {
    wei = '';
  }
  return new BigNumber(wei.toString()).dividedBy(1e18).toFixed(decimalPlaces);
}

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function estimateGas(
  contract: Contract,
  action: string,
  params: any[]
) {
  let gasLimitNumber = 300000;
  try {
    const rawValue = await contract.estimateGas[action](...params);
    gasLimitNumber = rawValue.toNumber();
  } catch (e: any) {
    console.error('estimateGas ERROR:', e.message);
  }

  return '' + (new BigNumber(gasLimitNumber).times(1.4).integerValue().toFixed(BigNumber.ROUND_UP));
}

export async function fetchUserBalance(
  contractAddress: string,
  userAddress: string,
) {
  const userProvider = await store.getters['account/userProvider'];
  const contract = await new BscContract(
    contractAddress,
    abi['ERC20'],
    userProvider || myProvider
  );

  const userBalance = await contract.balanceOf(userAddress);
  return userBalance.toString();
}
export function getFusionFailRatio(fusionFailRatio: any,level: number) {
  if (level < 11) return fusionFailRatio.level11;
  if (level < 21) return fusionFailRatio.level21;
  if (level < 31) return fusionFailRatio.level31;
  if (level < 41) return fusionFailRatio.level41;
  if (level < 51) return fusionFailRatio.level51;
  if (level < 101) return fusionFailRatio.level101;
  if (level < 151) return fusionFailRatio.level151;
  if (level < 201) return fusionFailRatio.level201;
}
interface Call {
  address: string,
  name: string,
  params: []
}
export function cleanObject (obj: any) {
  const newObject = {};
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '' || obj[key]?.length > 0) {
      //@ts-ignore
      newObject[key] = obj[key];
    }
  }
  return newObject;
}
function findBuniHouseElement(buniHouse: any){
  const attr = buniHouse.attributes.find((attr: any) => attr.traitType === 'element');
  return attr.value || '';
}
export function getSpaceNeeded(category: string, element?: string) {
  //@ts-ignore
  const buildingsConfig = store.state.metadata.buildings;
  if (!buildingsConfig) return 0;
  if (category === 'farm' || category === 'temple') {
    const building =  buildingsConfig.find((building: any) => building.category === category);
    if (building) return building.buildFee.space;
    return 0;
  }
  if (!element) return 0;
  const buniHouses = buildingsConfig.filter((building: any) => building.category === category);
  const building = buniHouses.find((buniHouse: any) => findBuniHouseElement(buniHouse) === element);
  return building.buildFee.space || 0;
}

export function getElementName(elementNumber: number) {
  switch(elementNumber) {
  case 0: return 'fire';
  case 1: return 'earth';
  case 2: return 'air';
  case 3: return 'water';
  case 4: return 'neautral';
  default: return '';
  }
}
export function getEggImage(element: string, stars: number) {
  switch(stars) {
  case 0: return EGG_META_DATA[element][0];
  case 1: return EGG_META_DATA[element][0];
  case 2: return EGG_META_DATA[element][1];
  case 3: return EGG_META_DATA[element][1];
  case 4: return EGG_META_DATA[element][2];
  }
}
