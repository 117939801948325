<template>
  <b-modal
    id="claim-bur-modal"
    size="md"
    hide-footer
    hide-header
    centered
  >
    <div class="title">Withdraw BUR</div>
    <div class="close" @click="closeModal">x</div>
    <div class="content-wrapper">
      <div v-if="burBalance">Do you want to withdraw your total rewards of {{ burBalance | formatWeiAmount  }} BUR?</div>
      <div v-else>You have no BUR to withdraw</div>
      <div class="button-wrapper">
        <custom-button @handleClick="closeModal">
          Cancel
        </custom-button>
        <custom-button
          :isDisabled="isDisabledConfirm()"
          :isLoading="isWithdrawing"
          @handleClick="handleConfirm"
        >
          Confirm
        </custom-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
import {mapState} from 'vuex';
import rf from '@/requests/RequestFactory';
import { moveToOnChain } from '@/utils/utils';
import { handleTransactionError } from '@/utils/helpers';
import config from '@/config';

export default {
  data() {
    return {
      isWithdrawing: false
    };
  },
  components: { CustomButton },
  computed: {
    ...mapState('account', ['profile']),
    burBalance() {
      return this.profile.burInGame || 0;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('claim-bur-modal');
    },
    isDisabledConfirm() {
      return this.burBalance <= 0;
    },
    async handleConfirm() {
      if (this.isDisabledConfirm()) return;
      this.isWithdrawing = true;
      try {
        const res = await rf.getRequest('UserRequestV2').withdrawBurInGame();
        if (res) {
          const moveToOnChainRes = await moveToOnChain(
            config.addresses.burTokenContract,
            res.method,
            res.data[0],
            res.requestId,
            res.signature
          );
          if (moveToOnChainRes) {
            await this.$store.dispatch('account/getUserProfile');
            this.closeModal();
            this.$dialog.notify.success('You have withdrawn BUR successfully');
          }
        }
      } catch(error) {
        const msg = handleTransactionError(error);
        this.$dialog.notify.error(msg);
      }
      this.isWithdrawing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/config/_variables';

.title {
  font-size: 2.5rem;
  text-align: center;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px;
  font-size: 1.25rem;
  cursor: pointer;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      margin: 20px 8px;
    }
  }

}
@media screen and (max-width: $breakpoint-mobile) {
  .title {
    font-size: 1.5rem;
  }

}
</style>
