<template>
  <div class="pending-tx">
    <div class="back-button" @click="$router.push('/')">
      <img src="/images/back-button.png" />
    </div>
    <PendingClaimToken/>
    <PendingWithdrawNFT/>
  </div>
</template>

<script>
import PendingClaimToken from '@/components/PendingTransactions/PendingClaimToken.vue';
import PendingWithdrawNFT from '@/components/PendingTransactions/PendingWithdrawNFT.vue';

export default {
  name: 'PendingTransactions',
  components: {
    PendingClaimToken,
    PendingWithdrawNFT
  }
};
</script>

<style lang="scss" scoped>
@import "@/config/_variables";
.pending-tx {
  padding: 30px 0;
  flex-wrap: wrap;
  .back-button {
    cursor: pointer;
    img {
      height: 40px;
      width: auto;
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  .pending-tx {
    width: 95vw;
    margin: auto;
  }
}
</style>