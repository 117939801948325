<template>
  <b-modal id="power-info" hide-footer hide-header size="md" centered>
    <div class="close" @click="$emit('close-modal')">x</div>
    <div class="content" v-if="buniStars !== null && buniStars !== undefined">
      <div class="stat-wrapper" v-for="(power, index) in [1,2,3,4,5]" :key="index">
        <div class="power">Power {{power}}/5</div>
        <div>➜</div>
        <div>bonus Stat {{getBonusStats(buniStars)[index] | formatPercent }}</div>
        <div><b>+</b></div>
        <div>max level {{getMaxLevel(buniStars)[index]}}</div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ['buniStars'],
  methods: {
    getBonusStats(buniStars) {
      if (buniStars === 0) {
        return [0.12, 0.24, 0.36, 0.48, 0.59];
      } else if (buniStars === 1) {
        return [0.12, 0.24, 0.36, 0.48, 0.59];
      } else if (buniStars === 2) {
        return [0.13, 0.26, 0.39, 0.53, 0.66];
      } else if (buniStars === 3) {
        return [0.17, 0.34, 0.51, 0.68, 0.85];
      } else if (buniStars === 4) {
        return [0.38, 0.76, 1.14, 1.52, 1.91];
      } else {
        return [];
      }
    },
    getMaxLevel(buniStars) {
      if (buniStars === 0) {
        return [45, 50, 55, 60, 70];
      } else if (buniStars === 1) {
        return [45, 50, 55, 60, 70];
      } else if (buniStars === 2) {
        return [45, 50, 55, 60, 70];
      } else if (buniStars === 3) {
        return [45, 50, 55, 60, 70];
      } else if (buniStars === 4) {
        return [45, 50, 55, 60, 70];
      } else {
        return [];
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/config/_variables";

#power-info {
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px;
    font-size: 1.25rem;
    cursor: pointer;
  }
  .content {
    padding: 30px;
    overflow-x: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .stat-wrapper {
      width: 100%;
      max-width: 320px;
      display: flex;
      justify-content: space-around;
      line-height: 30px;
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  #power-info {
    .content {
      padding: 30px 20px;
    }
  }
}
</style>
