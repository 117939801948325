<template>
  <b-modal id="direct-sell-nft-modal" size="xl" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc>
    <div class="title">Selling {{ typeDisplay }}</div>
    <div class="close" @click="closeModal">x</div>
    <VueStep
      :now-step="currentStep"
      :step-list="steps"
      :style-type="'style2'"
      :active-color="'#269aa1'"
      v-if="currentStep < 5"
    />

    <div class="content-wrapper" v-if="currentStep === 1">
      <div class="confirmation-wrapper">Do you want to sell this {{ typeDisplay }} ?</div>
    </div>
    <div class="content-wrapper" v-if="currentStep === 2">
      <div class="confirmation-wrapper">Removing your {{ typeDisplay }} from bunihouse!</div>
    </div>
    <div class="content-wrapper" v-if="currentStep === 3">
      <div class="confirmation-wrapper">Transferring your {{ typeDisplay }} back to your wallet!</div>
    </div>
    <div class="content-wrapper" v-if="currentStep === 4">
      <div class="selling-stage-wrapper">
        <div class="content-wrapper">
          <span :style="{whiteSpace: 'nowrap', marginRight: '12px'}">Sell price:</span>
          <input
            v-model="sellPrice"
            type="number"
            placeholder="List Price (BUNI)"
            class="form-control priceInput"
            :readonly="isProcessing"
          />
        </div>
        <span class="message-description" v-if="messageError">{{messageError}}</span>
      </div>
    </div>
    <div class="content-wrapper" v-if="currentStep === 5">
      <div class="confirmation-wrapper">Finish!</div>
    </div>
    <div class="content-wrapper" v-if="currentStep === 6">
      <div class="confirmation-wrapper"> {{ errorDisplay ? errorDisplay : 'Process selling failed!'}} </div>
    </div>

    <div class="footer-wrapper">
      <template v-if="currentStep < 4">
        <div
          class="confirm-btn sketchy"
          :class="isProcessing ? 'disable' : ''"
          @click="handleWithdrawProcess"
        >
          <span v-if="!isProcessing">CONFIRM</span>
          <span v-if="isProcessing"><i class="fas fa-spinner fa-spin"></i></span>
        </div>
        <div
          class="cancel-btn sketchy"
          :class="isProcessing ? 'disable' : ''"
          @click="closeModal"
        >
          CANCEL
        </div>
      </template>
      <template v-if="currentStep === 4">
        <div
          class="confirm-btn sketchy"
          :class="isProcessing ? 'disable' : ''"
          @click="handleSellingProcess"
        >
          <span v-if="!isProcessing">CONFIRM</span>
          <span v-if="isProcessing"><i class="fas fa-spinner fa-spin"></i></span>
        </div>
        <div
          class="cancel-btn sketchy"
          :class="isProcessing ? 'disable' : ''"
          @click="closeModal"
        >
          CANCEL
        </div>
      </template>
      <template v-if="currentStep === 5">
        <div
          class="confirm-btn sketchy"
          :class="isProcessing ? 'disable' : ''"
          @click="emitCloseModal('onSellNftSuccess')"
        >
          COMPLETED
        </div>
      </template>
      <template v-if="currentStep === 6">
        <div
          class="confirm-btn sketchy"
          :class="isProcessing ? 'disable' : ''"
          @click="closeModal"
        >
          BACK
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import VueStep from 'vue-step';
import { withdrawNft } from '@/utils/utils';
import { listOnChainNft } from '@/utils/market-utils';
import rf from '@/requests/RequestFactory';
import config from '@/config';

const steps = ['Confirmation', 'Removing From bunihouse', 'Withdraw', 'Listing', 'Complete'];
const preDefinedNftType = {bunicorn: 'bunicorn', trainer: 'trainer'};

export default {
  components: {VueStep},
  props: {
    nftType: { type: String, require: true },
    typeDisplay: { type: String, require: true },
    buniHouseId: { type: Number, require: false },
    inGameNftId: { type: Number, require: true },
    needToRemoveFromBunihouse: {type: Boolean, require: true, default: true}
  },
  data(){
    return {
      steps,
      currentStep: 1,
      sellPrice: null,
      minPrice: 1,
      maxPrice: 100000,
      withdrawParams: {
        nftContract: null,
        selectedNftIds: null,
        requestId: null,
        signature: null
      },
      messageError: null,
      errorDisplay: null,
      isProcessing: false
    };
  },
  watch: {
    sellPrice() {
      this.messageError = null;
    }
  },
  methods: {
    closeModal() {
      this.currentStep = 1;
      this.sellPrice = null;
      this.withdrawParams = {
        nftContract: null,
        selectedNftIds: null,
        requestId: null,
        signature: null
      };
      this.messageError = null;
      this.errorDisplay = null;
      this.isProcessing = false;
      this.$bvModal.hide('direct-sell-nft-modal');
    },
    emitCloseModal(eventName){
      this.$bvModal.hide('direct-sell-nft-modal');
      this.$emit(eventName);
    },
    async removeNftFromBunihouse(){
      try{
        let res;
        if(this.nftType === preDefinedNftType.bunicorn){
          res = await rf
            .getRequest('IsLandRequest')
            .removeBunicornsFromBuniHouse( this.buniHouseId, {bunicornIds: [this.inGameNftId] });
        }
        if(this.nftType === preDefinedNftType.trainer){
          res = await rf
            .getRequest('IsLandRequest')
            .removeTrainersFromBuniHouse(this.buniHouseId, {trainerIds: [this.inGameNftId]});
        }

        if (res) {
          this.$dialog.notify.success(`You've removed the ${this.typeDisplay} from the house!`);
          return true;
        }
      }catch(error){
        this.$dialog.notify.error(error);
      }

      this.errorDisplay = `An error has occurred when removing ${this.typeDisplay} from the house!`;
      return false;
    },
    async prepareWithdraw(){
      if(this.nftType === preDefinedNftType.bunicorn){
        this.withdrawParams.selectedNftIds = [this.inGameNftId];
        this.withdrawParams.nftContract = config.addresses.bunicornContract;
        try {
          const res = await rf.getRequest('UserRequest')
            .getMyBunicornsSignatureWithdraw({bunicornIds: [this.inGameNftId]});
          if (res) {
            this.withdrawParams.requestId =  res.requestId;
            this.withdrawParams.signature =  res.signature;
          }
          return true;
        } catch (error) {
          this.$dialog.notify.error(error);
        }
      }

      if(this.nftType === preDefinedNftType.trainer){
        this.withdrawParams.selectedNftIds = [this.inGameNftId];
        this.withdrawParams.nftContract = config.addresses.trainerContract;
        try {
          const res = await rf.getRequest('UserRequest')
            .getMyTrainersSignatureWithdraw({trainerIds: [this.inGameNftId]});
          if (res) {
            this.withdrawParams.requestId =  res.requestId;
            this.withdrawParams.signature =  res.signature;
          }
          return true;
        } catch (error) {
          this.$dialog.notify.error(error);
        }
      }

      return false;
    },
    async handleWithdrawProcess(){
      this.currentStep = 2;
      this.isProcessing = true;
      if(this.needToRemoveFromBunihouse && this.buniHouseId !== null && typeof this.buniHouseId !== 'undefined'){
        const removingFromBunihouseResult = await this.removeNftFromBunihouse();
        if(!removingFromBunihouseResult){
          this.currentStep = 6;
          this.isProcessing = false;
          return;
        }
      }

      this.currentStep = 3;
      const preparingResult = await this.prepareWithdraw();
      if(!preparingResult){
        this.currentStep = 6;
        this.isProcessing = false;
        return;
      }

      try {
        const res = await withdrawNft(this.withdrawParams);
        if(res) {
          this.currentStep = 4;
          this.$dialog.notify.success(`You've withdraw ${this.typeDisplay} successfully!`);
        }else{
          this.currentStep = 6;
          this.errorDisplay = `Withdraw ${this.typeDisplay} has encoutered error!`;
          this.isProcessing = false;
          return;
        }
      } catch(error) {
        console.log(error);
        this.currentStep = 6;
        this.isProcessing = false;
        return;
      }

      this.isProcessing = false;
    },
    async handleSellingProcess(){
      this.isProcessing = true;
      if (parseFloat(this.sellPrice) > this.maxPrice || parseFloat(this.sellPrice) < this.minPrice){
        this.messageError = 'Price must be between 1 and ' + this.maxPrice;
        this.isProcessing = false;
        return;
      }

      const targetContractAddress = this.nftType === preDefinedNftType.bunicorn
        ? config.addresses.bunicornContract
        : this.nftType === preDefinedNftType.trainer
          ? config.addresses.trainerContract : undefined;
      const listingResult = await listOnChainNft(targetContractAddress, this.inGameNftId, this.sellPrice);
      if(listingResult){
        this.$dialog.notify.success(`You've listed ${this.typeDisplay} on market successfully!`);
      }else{
        this.currentStep = 6;
        this.errorDisplay = `Withdraw ${this.typeDisplay} has encoutered error!`;
        this.isProcessing = false;
        return;
      }
      this.currentStep = 5;
      this.isProcessing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 2.5rem;
  text-align: center;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px;
  font-size: 1.25rem;
  cursor: pointer;
}

.content-wrapper {
  display: flex;
  justify-content: center;
}

.confirmation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin: auto;
  font-size: 2rem;
}

.selling-stage-wrapper{
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px auto;
    font-size: 2rem;
  }
}

.priceInput {
  margin: 8px 0;
  border-radius: 10px;
  text-transform: initial;
  font-size: 1.5rem;
}

.message-description {
  padding: 0 8px;
  font-style: italic;
  text-align: center;
  color: #FF5A3A;
  font-size: 1.5rem;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .confirm-btn,
  .cancel-btn {
    margin: 0 8px;
    padding: 8px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
    &.disable {
      cursor: not-allowed;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}
</style>
