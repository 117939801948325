import BaseRequest from './BaseRequest';
import config from '@/config';

export default class TrainerRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getMyTrainer(trainerId: number){
    const url = `/my/trainers/${trainerId}`;
    return this.get(url,{});
  }
  move(params: any){
    const url = '/my/buni-houses/move-trainer';
    return this.post(url,params);
  }
  avoidHalvingRate(trainerId: any) {
    const url = `/my/trainers/${trainerId}/avoid-halving-rate`;
    return this.put(url, {});
  }
  getAvailableToFuseTrainers(params: any){
    const url = '/my/trainers/fuse-offchain';
    return this.get(url, params);
  }
  fuseTrainer(trainerId: number, trainerToFuse: number, useScroll: boolean){
    const url = `/my/trainers/${trainerId}/fuse-offchain`;
    return this.patch(url,{burnId: trainerToFuse, useScroll});
  }
}

export class PureTrainerRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.trainerApi;
  }
  getFuseFee(trainerId: number){
    const url = `/public/trainers/${trainerId}/fuse-fee`;
    return this.get(url,{});
  }
}
