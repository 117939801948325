import BaseRequest from './BaseRequest';
import config from '@/config';

export default class StatisticsBoardRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getMyStorage() {
    const url = '/my/storage';
    return this.get(url, {});
  }
  upgrade(params: any) {
    const url = '/my/storage/levelup';
    return this.patch(url, params);
  }
  getLevelUp() {
    const url = '/my/storage/levelup';
    return this.get(url, {});
  }
  updateDefendTeam(params: any) {
    const url = '/my/team-protect-gold';
    return this.put(url, params);
  }
  deleteDefendTeam() {
    const url = '/my/team-protect-gold';
    return this.del(url, {});
  }
  getDefendTeam() {
    const url = '/my/team-protect-gold';
    return this.get(url, {});
  }
  updateAttackTeam(body: any) {
    const url = '/my/team-steal-gold';
    return this.put(url, body);
  }
  deleteAttackTeam() {
    const url = '/my/team-steal-gold';
    return this.del(url, {});
  }
  getAttackTeam() {
    const url = '/my/team-steal-gold';
    return this.get(url, {});
  }
  getSpeedUpFee() {
    const url = '/my/storage/speedup';
    return this.get(url, {});
  }
  speedUp() {
    const url = '/my/storage/speedup';
    return this.patch(url);
  }
  getStealLogs(params: any) {
    const url = '/my/storage/stealing-logs';
    return this.get(url, params);
  }
  getStolenLogs(params: any) {
    const url = '/my/storage/stolen-logs';
    return this.get(url, params);
  }
  replay(battleID: string) {
    const url = `/my/storage/battle-logs/${battleID}`;
    return this.get(url, {});
  }
  revenge(battleID: string) {
    const url = `/my/storage/stolen-logs/${battleID}/revenge`;
    return this.post(url, {});
  }
  getBunicornForFunction(params: any) {
    const url = '/my/bunicorns/for-function';
    return this.get(url, params);
  }
  getBattleLogDetail(id: string) {
    const url = `/my/storage/battle-logs/${id}`;
    return this.get(url, {});
  }
  steal() {
    const url = '/my/team-steal-gold/steal';
    return this.post(url, {});
  }
  markSeenNotifications() {
    const url = '/my/storage/notifications';
    return this.patch(url, {});
  }
  getStorageNotification() {
    const url = '/my/storage/notifications';
    return this.get(url, {});
  }
}
