<template>
  <div>
    <div class="search-filter">
      <b-form-select
        class="level-filter"
        v-model="selectedLevel"
        :options="levels"
      ></b-form-select>
      <div class="clear-filter sketchy" @click="clearFilter">Clear filter</div>
    </div>
    <div class="content-wrapper">
      <template v-if="trainers.length > 0">
      <div
        class="trainer-card"
        :class="selectedTrainers.includes(trainer.trainerId) ? 'active' : ''"
        v-for="trainer in trainers"
        :key="trainer.trainerId"
        @click="selectTrainer(trainer.trainerId)"
      >
        <img
          :src="
            trainer.metaData.medium_image
          "
        />
        <div class="info-wrapper">
          <div class="id">ID: {{ trainer.trainerId }}</div>
          <div class="name">{{ trainer.metaData.name}}</div>
        </div>
        <div class="info-wrapper">
          <div class="id">Level: {{ trainer.level + 1 }}</div>
          <div class="name">{{ trainer.hasFused ? 'Fusion Done' : '' }}</div>
        </div>
      </div>
      </template>
      <template v-else >
        <div class="blank-state">
        There is no trainer matched this filter.
        </div>
      </template>
    </div>
    <div class="pagination-wrapper">
      <b-pagination
        v-model="params.page"
        :total-rows="totalItem"
        :per-page="params.limit"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import rf from '@/requests/RequestFactory';
import { cleanObject } from '@/utils/helpers';
export default {
  props: ['buniHouseId', 'buniHouseElement'],
  data() {
    return {
      trainers: [],
      selectedTrainers: [],
      openModal: false,
      page: 1,
      totalItem: 0,
      limit: 4,
      selectedLevel: '',
      params: {
        page: 1,
        limit: 4,
        levelFrom: '',
        levelTo: '',
        inBuniHouse: false,
        isNft: true
      },
      levels: [
        {
          text: 'Level',
          value: '',
        },
        {
          text: '1 - 10',
          value: {
            levelFrom: 0,
            levelTo: 9,
          },
        },
        {
          text: '11 - 20',
          value: {
            levelFrom: 10,
            levelTo: 19,
          },
        },
        {
          text: '21 - 30',
          value: {
            levelFrom: 20,
            levelTo: 29,
          },
        },
        {
          text: '31 - 40',
          value: {
            levelFrom: 30,
            levelTo: 39,
          },
        },
        {
          text: '41 - 50',
          value: {
            levelFrom: 40,
            levelTo: 49,
          },
        },
        {
          text: '51 - 100',
          value: {
            levelFrom: 50,
            levelTo: 99,
          },
        },
        {
          text: '101 - 150',
          value: {
            levelFrom: 100,
            levelTo: 149,
          },
        },
        {
          text: '151 - 200',
          value: {
            levelFrom: 150,
            levelTo: 199,
          },
        },
        {
          text: '201 - 250',
          value: {
            levelFrom: 200,
            levelTo: 249,
          },
        },
        {
          text: '251 - 256',
          value: {
            levelFrom: 250,
            levelTo: 255,
          },
        },
      ],
    };
  },
  async mounted() {
    this.fetchData();
  },
  watch: {
    selectedLevel(){
      this.params = {
        ...this.params,
        levelFrom: this.selectedLevel.levelFrom,
        levelTo: this.selectedLevel.levelTo
      };
    },
    params: {
      deep: true,
      handler(){
        this.fetchData();
      }
    }
  },
  computed: {
    isDisable() {
      return this.selectedTrainers.length === 0;
    },
  },
  methods: {
    cleanObject,
    clearFilter(){
      this.params = {
        page: 1,
        limit: 4,
        levelFrom: '',
        levelTo: '',
        inBuniHouse: false,
        isNft: true,
      };
      this.selectedLevel = '';
    },
    selectTrainer(trainerId) {
      if (this.selectedTrainers.includes(trainerId)) {
        this.selectedTrainers = this.selectedTrainers.filter(
          (id) => id !== trainerId
        );
      } else {
        this.selectedTrainers.push(trainerId);
      }
      this.$emit('handleSelectNft', this.selectedTrainers);
    },
    async fetchData() {
      const res = await rf.getRequest('UserRequest').getMyTrainerInGameV2(this.cleanObject(this.params));
      if (res) {
        this.trainers = res.docs;
        this.totalItem = res.totalDocs;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';
.blank-state {
  height: 150px;
  text-align: center;
  margin: auto;
  font-size: 2rem;

}
.search-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px;
  .level-filter {
    width: 180px;
  }
  .element-filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    ::v-deep .select-box {
      margin: 0 8px;
      input {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      .custom-control-label::before,
      .custom-control-label::after {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .element {
      width: 26px;
      height: 26px;
      object-fit: contain;
    }
  }
  .clear-filter {
    padding: 8px;
    width: 120px;
    text-transform: uppercase;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    &:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
  }
}
.content-wrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  padding: 20px 0;
  .trainer-card {
    width: 20%;
    min-width: 210px;
    margin: 0 8px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 5px 0 #000;
      transform: scale(1.05);
    }
    &.active {
      box-shadow: 1px 1px 5px 0 #000;
      transform: scale(1.05);
    }
    img {
      width: 100%;
      height: auto;
      margin: 0 auto 20px auto;
      object-fit: contain;
    }
    .info-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
}
.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .confirm-btn,
  .cancel-btn {
    margin: 0 8px;
    padding: 8px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
    &.disable {
      cursor: not-allowed;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  .content-wrapper {
    overflow-x: auto;
    justify-content: flex-start;
  }
  .search-filter {
    flex-wrap: wrap;
    .level-filter,
    .element-filter,
    .clear-filter {
      margin: 8px;
    }
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .search-filter {
    flex-wrap: wrap;
    .level-filter,
    .element-filter {
      width: 100%;
    }
    .element-filter {
      justify-content: center;
    }
    .clear-filter {
      margin: 8px auto;
    }
  }
}
</style>
