import BaseRequest from './BaseRequest';
import config from '@/config';
import Vue from 'vue';
import axios from 'axios';
export default class IsLandRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getMyList() {
    const url = '/my/islands';
    return this.get(url, {});
  }
  getDetail(islandId: number) {
    const url = `/my/islands/${islandId}`;
    return this.get(url, {});
  }
  getBuniHouseDetail(houseId: number) {
    const url = `/my/buni-houses/${houseId}`;
    return this.get(url, {});
  }
  getBuildingList() {
    const url = '/my/shop/buildings';
    return this.get(url, {});
  }
  createBuilding(params: any) {
    const url = '/my/shop/buildings';
    return this.post(url, params);
  }
  getObstaclesList(islandId: any) {
    const url = `/my/islands/${islandId}/obstacles`;
    return this.get(url, {});
  }
  removeObstacle(islandId: any, obstacleId: number) {
    const url = `/my/islands/${islandId}/obstacles/${obstacleId}`;
    return this.patch(url, {});
  }
  speedupRemoveObstacle(islandId: any, obstacleId: number) {
    const url = `/my/islands/${islandId}/obstacles/${obstacleId}/speedup`;
    return this.patch(url, {});
  }
  addBunicornToBuniHouse(buniHouseId: any, params: any){
    const url = `/my/buni-houses/${buniHouseId}/bunicorns`;
    return this.post(url, params);
  }
  removeBunicornsFromBuniHouse(buniHouseId: number, body: {bunicornIds: number[]}){
    const url = `/my/buni-houses/${buniHouseId}/bunicorns`;
    return this.del(url, body);
  }
  removeTrainersFromBuniHouse(buniHouseId: number, body: {trainerIds: number[]}){
    const url = `/my/buni-houses/${buniHouseId}/trainers`;
    return this.del(url, body);
  }
  addTrainerToBuniHouse(buniHouseId: any, params: any){
    const url = `/my/buni-houses/${buniHouseId}/trainers`;
    return this.post(url, params);
  }
  getTrainers(params: any) {
    const url = '/public/trainers';
    return this.get(url, params);
  }
  getBunicorns(params: any) {
    const url = '/public/bunicorns';
    return this.get(url, params);
  }
  getExpandableSlot(islandId: any) {
    const url = `/my/islands/${islandId}/expandable-slots`;
    return this.get(url, {});
  }
  expandIsland(islandId: any, params: any) {
    const url = `/my/islands/${islandId}/expandable-slots`;
    return this.patch(url, params);
  }
  async collectGold(buniHouseId: number){
    if (Vue.prototype.$recaptchaLoaded) {
      await Vue.prototype.$recaptchaLoaded();
    }
    const recaptcha = await Vue.prototype.$recaptcha('recaptcha');
    axios.defaults.headers.common['recaptchaV3'] = recaptcha;
    const url = `/my/buni-houses/${buniHouseId}/harvest`;
    return this.post(url,{});
  }
  unlockIsland(islandId: number) {
    const url = `/my/islands/${islandId}/unlock-island`;
    return this.post(url,{});
  }
  getUnlockIsland(islandId: number) {
    const url = `/my/islands/${islandId}/unlock-island`;
    return this.get(url,{});
  }
  getFeeLevelUpFarm(farmId: number){
    const url = `/my/farms/${farmId}/levelup`;
    return this.get(url,{});
  }
  getFeeLevelUpBuniHouse(buniHouseId: number) {
    const url = `/my/buni-houses/${buniHouseId}/levelup`;
    return this.get(url,{});
  }
  upgradeBuniHouse(buniHouseId: number, params: any){
    const url = `/my/buni-houses/${buniHouseId}/levelup`;
    return this.patch(url,params);
  }
  moveBuniHouse(buniHouseId: number,params: any){
    const url = `/my/buni-houses/${buniHouseId}/move-building`;
    return this.patch(url, params);
  }
  getSpeedUpCostBuniHouse(buniHouseId: number) {
    const url = `/my/buni-houses/${buniHouseId}/speedup`;
    return this.get(url, {});
  }
  speedUp(buniHouseId: number) {
    const url = `/my/buni-houses/${buniHouseId}/speedup`;
    return this.patch(url,{});
  }
  sellBuniHouse(buniHouseId: number) {
    const url = `/my/buni-houses/${buniHouseId}/sell`;
    return this.del(url, {});
  }
  getMyBunicorn(bunicornId: number){
    const url = `/my/bunicorns/${bunicornId}`;
    return this.get(url, {});
  }
  moveBunicorn(params: any){
    const url = '/my/buni-houses/move-bunicorn';
    return this.post(url,params);
  }
  avoidHalvingRate(bunicornId: number){
    const url = `/my/bunicorns/${bunicornId}/avoid-halving-rate`;
    return this.put(url,{});
  }
  getNextBattleTimes(params: any) {
    const url = '/my/bunicorns/next-battle-times';
    return this.get(url,params);
  }
  getSkipWaitingFees(params: any) {
    const url = '/my/bunicorns/skip-waiting-fees';
    return this.get(url,params);
  }
  skipBattleTime(params: any) {
    const url = '/my/bunicorns/skip-battle-times';
    return this.post(url, params);
  }
  getWinCounts(params: any) {
    const url = '/my/bunicorns/list-details';
    return this.get(url,params);
  }
  getAvailableForEnhanceBunicorns(params: any){
    const url = '/my/bunicorns/enhance-offchain';
    return this.get(url,params);
  }
  enhanceBunicorn(bunicornId: number, bunicornToBurn: number){
    const url = `/my/bunicorns/${bunicornId}/enhance-offchain`;
    return this.patch(url,{burnIDs: [bunicornToBurn]});
  }
  getSignatureRequests(status: string, page: number, limit: number) {
    const url = '/my/signature-requests';
    return this.get(url, {status, page, limit});
  }
  getAvoidAllHalvingBunicornFee() {
    const url = '/my/bunicorns/avoid-all-halving';
    return this.get(url, {});
  }
  avoidAllHalvingBunicorn() {
    const url = '/my/bunicorns/avoid-all-halving';
    return this.put(url, {});
  }
  getAvoidAllHalvingTrainerFee() {
    const url = '/my/trainers/avoid-all-halving';
    return this.get(url, {});
  }
  avoidAllHalvingTrainer() {
    const url = '/my/trainers/avoid-all-halving';
    return this.put(url, {});
  }
}
