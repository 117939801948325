import BaseRequest from './BaseRequest';
import config from '@/config';

export default class HatcheryRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }

  getSpeedUpCostHatchery(hatcheryId: number) {
    const url = `/my/hatchery/${hatcheryId}/speedup`;
    return this.get(url, {});
  }
  speedUp(hatcheryId: number) {
    const url = `/my/hatchery/${hatcheryId}/speedup`;
    return this.put(url,{});
  }
  getEggs(params: any){
    const url = '/my/eggs';
    return this.get(url, params);
  }
  updateEgg(hatcheryId: number,params: any) {
    const url = `/my/hatchery/${hatcheryId}/eggs`;
    return this.put(url, params);
  }
  getIncubatingEgg(hatcheryId: number) {
    const url = `/my/hatchery/${hatcheryId}/eggs`;
    return this.get(url, {});
  }
  getSpeedupFee(eggId: number) {
    const url = `/my/eggs/${eggId}/speedup`;
    return this.get(url, {});
  }
  speedUpIncubating(eggId: number){
    const url = `/my/eggs/${eggId}/speedup`;
    return this.put(url, {});
  }
  moveHatchery(hatcheryId: number, params: any) {
    const url = `/my/hatchery/${hatcheryId}/move-building`;
    return this.patch(url, params);
  }
  getHatcheryDetail(hatcheryId: number) {
    const url = `/public/hatcheries/${hatcheryId}`;
    return this.get(url, {});
  }
}
