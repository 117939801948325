import TrainerRequest, {PureTrainerRequest} from './TrainerRequest';
import BunicornRequest from './BunicornRequest';
import IsLandRequest from './IsLandRequest';
import UserRequest  from './UserRequest';
import UserRequestV2 from './UserRequestV2';
import FarmRequest  from './FarmRequest';
import TempleRequest  from './TempleRequest';
import BuniHouseRequest from './BuniHouseRequest';
import BattleRequest from './BattleRequest';
import StatisticsBoardRequest from './StatisticsBoardRequest';
import TrainingCenterRequest from './TrainingCenterRequest';
import BreedingHouseRequest from './BreedingHouseRequest';
import HatcheryRequest from './HatcheryRequest';
import MentoringHouseRequest from './MentoringHouseRequest';
import StorageRequest from './StorageRequest';
import AuthenticationRequest from './AuthenticationRequest';

const requestMap = {
  TrainerRequest,
  PureTrainerRequest,
  BunicornRequest,
  IsLandRequest,
  UserRequest,
  UserRequestV2,
  FarmRequest,
  TempleRequest,
  BuniHouseRequest,
  BattleRequest,
  StatisticsBoardRequest,
  TrainingCenterRequest,
  BreedingHouseRequest,
  HatcheryRequest,
  MentoringHouseRequest,
  StorageRequest,
  AuthenticationRequest
};

const instances = {};

export default class RequestFactory {
  static getRequest (classname: any) {
    // @ts-ignore
    const RequestClass = requestMap[classname];
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${classname}`);
    }

    // @ts-ignore
    let requestInstance = instances[classname];
    if (!requestInstance) {
      requestInstance = new RequestClass();
      // @ts-ignore
      instances[classname] = requestInstance;
    }

    return requestInstance;
  }
}
