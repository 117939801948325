<template>
  <div class="app">
    <NavBar />
    <div class="content-container">
      <router-view v-if="myAccount && !wrongNetwork" />
      <div v-else class="connect-wallet">
        <div>Welcome to Buni Island.</div>
        <div
          class="connect-btn sketchy"
          @click="$bvModal.show('account-modal')"
        >
          {{ 'Connect to a wallet' }}
        </div>
      </div>
    </div>
    <account-modal
      @close-connect-wallet-modal="$bvModal.hide('account-modal')"
    />
    <inventory-modal
      @close-modal="$bvModal.hide('inventory-modal')"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Storage from '@/utils/storage';
import NavBar from '@/components/NavBar.vue';
import AccountModal from './components/AccountModal.vue';
import InventoryModal from './components/InventoryModal.vue';

export default {
  components: {
    NavBar,
    AccountModal,
    InventoryModal,
  },
  computed: {
    myAccount() {
      return this.$store.state.account.account.address;
    },
    accessToken() {
      return this.$store.state.account.account.accessToken;
    },
    wrongNetwork() {
      return this.$store.state.account.account.wrongNetwork;
    }
  },
  watch: {
    myAccount() {
      if (this.myAccount && !Storage.getToken() && !this.wrongNetwork) {
        this.$store.dispatch('account/generateToken');
      }
    },
    accessToken() {
      if (this.accessToken) {
        this.$store.dispatch('metadata/getMetadata');
      }
    },
  },
  async mounted() {
    if (this.myAccount && !Storage.getToken() && !this.wrongNetwork) {
      await this.$store.dispatch('account/generateToken');
    }
    if (Vue.prototype.$recaptchaLoaded) {
      await Vue.prototype.$recaptchaLoaded();
    }
  },
  async created() {
    try {
      const connectorId = Storage.getConnectorId();
      if (connectorId) {
        await this.$store.dispatch('account/connect', connectorId);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';

.app {
  .content-container {
    width: 1366px;
    margin: auto;
    padding: 0px 100px;
    .connect-wallet {
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 3rem;

      .connect-btn {
        padding: 8px 20px;
        margin: 0 8px;
        border-radius: 8px;
        height: fit-content;
        font-size: 1.5rem;
        cursor: pointer;
        &:hover {
          background-color: #e0e0e0;
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  .app {
    .content-container {
      width: 100vw;
      margin: auto;
      padding: 0px;
    }
  }
}
</style>
