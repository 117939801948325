<template>
  <div>
    <base-detail
      v-if="bunicornData && bunicornData.metaData"
      :rows="rows"
      :imageUrl="bunicornData.metaData.image"
      :id="bunicornId"
      :name="bunicornData.metaData.name"
      :isWarningHalvingRate="bunicornData.halvingRate && bunicornData.halvingRate.halvingWarning"
      @handleBackButton="goToBuniHouse()"
    >
      <template #more-info>
        <span class="level-wrapper">
          (
            Level
            <progress-circle
              v-if="amountFoodForOneTime && amountFoodForNextLevel"
              :completed-steps="(amountFoodForOneTime * feedingNumber - amountFoodForNextLevel)"
              :total-steps="amountFoodForOneTime * feedingNumber"
              :start-color="'#2A850C'"
              :end-color="'#2A850C'"
              :stop-color="'#B8E847'"
              :circle-color="'#a6a4a5'"
              :circle-width="6"
              :diameter="50"
              class="progress-level"
            >
              <span style="font-size: 25px">{{ bunicornData.level + 1 }}</span>
            </progress-circle>
            <progress-circle
              v-else
              :completed-steps="1"
              :total-steps="1"
              :start-color="'#2A850C'"
              :end-color="'#2A850C'"
              :stop-color="'#B8E847'"
              :circle-color="'#a6a4a5'"
              :circle-width="6"
              :diameter="50"
              class="progress-level"
            >
              <span style="font-size: 25px">{{ bunicornData.level + 1 }}</span>
            </progress-circle>
          )
          <span v-if="bunicornData.element" :class="`element ${bunicornData.element.toLowerCase()}`" />
        </span>
      </template>
      <template #more-action>
        <div class="stars">
          <img
            v-for="index in bunicornData.stars + 1"
            :key="index"
            src="/images/star.png"
          />
        </div>
        <div class="info-wrapper first-row">
          <div>HP: {{ bunicornHpAsString }}</div>
          <div>Damage: {{ bunicornDamageAsString }}</div>
          <div>
            Win Count: {{ bunicornData.winCount }}/250
            <template v-if="bunicornData.bonusWinCount">
              (+ {{ bunicornData.bonusWinCount | formatPercent }})
            </template>
            <b-icon class="win-count-info" icon="exclamation-circle-fill" variant="secondary" @click="$bvModal.show('win-count-info')"></b-icon>
          </div>
          <div>
            Bonus Attr: {{ bunicornData.bonusAttr }}
          </div>
          <div>
            Power: {{ bunicornData.power }}/5
            <template v-if="bunicornData.bonusPowers">
              (+ {{ bunicornData.bonusPowers | formatPercent }})
            </template>
            <b-icon class="win-count-info" icon="exclamation-circle-fill" variant="secondary" @click="$bvModal.show('power-info')"></b-icon>
          </div>
        </div>
        <div class="info-wrapper second-row">
          <div>Gold Rate: {{ currentGoldRate | formatNumber }} gold/minute</div>
          <div class="avoid-halving" v-if="bunicornData.buniHouseId">
            <div>
              <div v-if="bunicornData.halvingRate && bunicornData.halvingRate.currentMilestoneEnd">
                Next Halving Countdown:
                <countdown
                  :date="
                    new Date(
                      bunicornData.halvingRate.currentMilestoneEnd * 1000
                    )
                  "
                  :isPaused="bunicornData.halvingRate.isPauseHalvingCountDown"
                ></countdown>
              </div>
              <div>
                Next Halving Gold Rate: {{ goldRateAfterHalving | formatNumber }} gold/minute
              </div>
            </div>
            <!-- <div
              class="custom-btn sketchy"
              :class="disableAvoidHalving ? 'disable' : ''"
              @click="avoidHalvingRate"
              v-b-tooltip.hover.top="tooltipAvoidHalving"
            >
              Avoid Halving
              <template v-if="!isSkipHalving">
                ({{ bunicornData.halvingRate.burWeiNeedToAvoid | formatWeiAmount }} BUR)
              </template>
            </div> -->
          </div>
        </div>
        <div v-if="bunicornId > 0" class="info-wrapper second-row">
          Breeding Time: {{bunicornData.breedingTime || 0}}
        </div>
        <div class="button-wrapper">
<!--          <div class="custom-btn sketchy">Sell</div>-->
          <!-- <div
            v-if="bunicornData.buniHouseId"
            class="custom-btn sketchy"
            @click="$bvModal.show('select-buni-house-modal')"
          >
            Move
          </div> -->
          <!-- <div
            v-if="bunicornData.bunicornId > 0"
            class="custom-btn sketchy"
            @click="$bvModal.show('enhance-modal')"
          >
            Enhance
          </div> -->
          <!-- <div class="custom-btn sketchy"
               :class="disableFeeding ? 'disable' : ''"
               v-b-tooltip.hover.top="tooltipFeeding"
               @click="feedBunicorn">
            Feed ({{amountFoodForOneTime}} Foods)
          </div> -->
          <div
            v-if="bunicornData.bunicornId > 0"
            class="custom-btn sketchy"
            @click="$bvModal.show('direct-sell-nft-modal')"
          >
            Sell
          </div>
        </div>
      </template>
      <template #row-data="props">
        <div class="skill" :class="props.item.levelRequired > bunicornData.level ? 'disabled': ''">
          <div class="name">{{ props.item.name }}</div>
          <div class="skill-info">
            <img
              class="element"
              :src="
                props.item.element === 4
                ? '/images/bunicorn-skill/punch_icon.png'
                : `/images/elements/${getElementName(props.item.element)}.png`
              "
            />
            <div class="info">
              Damage: {{ props.item.baseDamage }}
              <span
                class="txt-require-level"
                v-if="props.item && bunicornData && props.item.levelRequired > bunicornData.level">
                Require level {{ props.item.levelRequired + 1 }}
              </span>
            </div>
          </div>
          <!-- <div class="btn-wrapper">
            <div class="custom-btn disable sketchy">
              Train
            </div>
          </div> -->
        </div>
      </template>
    </base-detail>
    <select-buni-house-modal
      v-if="bunicornId && bunicornData && bunicornData.element"
      :buniHouseIdOld="bunicornData.buniHouseId"
      :name="'Bunicorn'"
      :element="bunicornData.element.toLowerCase()"
      :moveNft="moveBunicorn"
      @onMove="fetchData(bunicornId)"
      @close-modal="$bvModal.hide('select-buni-house-modal')"
    />
    <enhance-bunicorn-modal
      v-if="bunicornId && bunicornData && bunicornData.element"
      @close-modal="$bvModal.hide('enhance-modal')"
      @onEnhanceBunicornSuccessfully="goToBuniHouse"
      :bunicornId="bunicornId"
      :bunicornElement="bunicornData.element"
      :bunicornStar="bunicornData.stars"
    />
    <DirectSellingNftModal
      v-if="bunicornId && bunicornData"
      :nftType="'bunicorn'"
      :typeDisplay="'Bunicorn'"
      :buniHouseId="bunicornData.buniHouseId"
      :inGameNftId="bunicornData.bunicornId"
      @onSellNftSuccess="goToBuniHouse"
    />
    <WinCountInfoModal @close-modal="$bvModal.hide('win-count-info')"/>
    <PowerInfoModal v-if="bunicornId && bunicornData" :buniStars="bunicornData.stars" @close-modal="$bvModal.hide('power-info')"/>
  </div>
</template>
<script>
import BaseDetail from '@/components/BaseDetail.vue';
import rf from '@/requests/RequestFactory';
import Countdown from '@/components/CountDown.vue';
import SelectBuniHouseModal from '@/components/SelectBuniHouseModal.vue';
import WinCountInfoModal  from '@/components/WinCountInfoModal.vue';
import PowerInfoModal  from '@/components/PowerInfoModal.vue';
import { ProgressCircle } from 'vue-progress-circle';
import { convertWeiToDec, getElementName } from '@/utils/helpers';
import BigNumber from 'bignumber.js';
import EnhanceBunicornModal from '@/components/EnhanceBunicornModal.vue';
import DirectSellingNftModal from '@/components/DirectSellingNftModal.vue';

const FEEDING_NUMBER = 4;
export default {
  components: {
    BaseDetail,
    Countdown,
    ProgressCircle,
    WinCountInfoModal,
    PowerInfoModal,
    EnhanceBunicornModal,
    DirectSellingNftModal,
    SelectBuniHouseModal
  },
  data() {
    return {
      bunicornData: {},
      bunicornId: null,
      amountFoodForOneTime: 0,
      amountFoodForNextLevel: 0,
      feedingNumber: FEEDING_NUMBER,
      rows: [
        {
          title: 'SKILLS',
          data: [],
        },
      ],
    };
  },
  computed: {
    burBalance() {
      if(!this.$store.state.account?.profile) return 0;
      return this.$store.state.account.profile.burInGame;
    },
    foodBalance(){
      if(!this.$store.state.account?.profile) return 0;
      return Number(this.$store.state.account.profile.food);
    },
    disableAvoidHalving() {
      if (
        !this.bunicornData ||
        !this.burBalance ||
        !this.bunicornData.halvingRate?.burWeiNeedToAvoid ||
        this.bunicornData.halvingRate?.isPauseHalvingCountDown
      ) return true;
      return new BigNumber(this.burBalance).comparedTo(this.bunicornData.halvingRate.burWeiNeedToAvoid) < 0;
    },
    disableFeeding(){
      return (
        this.bunicornData === 69 ||
        this.foodBalance < this.amountFoodForOneTime
      );
    },
    tooltipAvoidHalving(){
      if (!this.bunicornData || !this.bunicornData.halvingRate?.burWeiNeedToAvoid)
        return 'Can not get avoid halving fee.';
      if (!this.burBalance) return 'Can not get your balance';
      if (new BigNumber(this.burBalance).comparedTo(this.bunicornData.halvingRate.burWeiNeedToAvoid) < 0){
        return 'You don\'t have enough fee to avoid halving.';
      }
      return '';
    },
    tooltipFeeding(){
      if (this.bunicornData.level === 69) return 'Maximum Level reached';
      if (!this.amountFoodForOneTime) return 'Can not get food requirement';
      if (this.foodBalance < this.amountFoodForOneTime){
        return 'You don\'t have enough food to feed bunicorn.';
      }
      return '';
    },
    currentGoldRate() {
      if (!this.bunicornData) {
        return 0;
      }
      if (!this.bunicornData.halvingRate) {
        return this.bunicornData.goldRate;
      }
      return new BigNumber(this.bunicornData.goldRate || 0)
        .multipliedBy(this.bunicornData.halvingRate.rate || 0)
        .toString();
    },
    goldRateAfterHalving() {
      if (!this.bunicornData) {
        return 0;
      }
      if (!this.bunicornData.halvingRate) {
        return this.currentGoldRate;
      }
      return new BigNumber(this.bunicornData.goldRate || 0)
        .multipliedBy(this.bunicornData.halvingRate.nextRate || 0)
        .toString();
    },
    isSkipHalving() {
      return new BigNumber(this.bunicornData?.halvingRate?.nextRate || 0)
        .comparedTo(this.bunicornData?.halvingRate?.rate || 0) === 0;
    },
    bunicornDamageAsString() {
      if (!this.bunicornData) {
        return 0;
      }
      let damage = this.bunicornData.damage;
      let isBonus = false;
      if (this.bunicornData.bonusPowers) {
        damage = new BigNumber(damage).multipliedBy(1 + this.bunicornData.bonusPowers).toString();
        isBonus = true;
      }
      if (this.bunicornData.bonusWinCount) {
        damage = new BigNumber(damage).multipliedBy(1 + this.bunicornData.bonusWinCount).toString();
        isBonus = true;
      }
      if (isBonus) {
        return `${damage}↑↑`;
      }
      return damage;
    },
    bunicornHpAsString() {
      if (!this.bunicornData) {
        return 0;
      }
      let hp = this.bunicornData.hp;
      let isBonus = false;
      if (this.bunicornData.bonusPowers) {
        hp = new BigNumber(hp).multipliedBy(1 + this.bunicornData.bonusPowers).toNumber().toFixed(2);
        isBonus = true;
      }
      if (this.bunicornData.bonusWinCount) {
        hp = new BigNumber(hp).multipliedBy(1 + this.bunicornData.bonusWinCount).toNumber().toFixed(2);
        isBonus = true;
      }
      if (isBonus) {
        return `${+hp}↑↑`;
      }
      return +hp;
    }
  },
  methods: {
    convertWeiToDec,
    getElementName,
    getSkillElement(element){
      switch(element) {
      case 1: return 'fire';
      case 2: return 'earth';
      case 3: return 'air';
      case 4: return 'water';
      default: return '';
      }
    },
    async feedBunicorn(){
      if (this.disableFeeding) return;
      try {
        const res = await rf.getRequest('BunicornRequest').feed(this.bunicornId);
        if (res) {
          this.$dialog.notify.success('You\'ve fed bunicorn successfully');
          await this.fetchData(this.bunicornId);
          await this.getRequirementFood(this.bunicornId);
          this.$store.dispatch('account/getUserProfile');
        }
      } catch(error) {
        this.$dialog.notify.error(error);
      }
    },
    async avoidHalvingRate() {
      if (this.disableAvoidHalving) return;
      if (this.isSkipHalving) {
        return this.$dialog.notify.warning('You skipped the halving success rate this time, please wait for the next halving');
      }
      try {
        const res = await rf
          .getRequest('IsLandRequest')
          .avoidHalvingRate(this.bunicornId);
        if (res) {
          await this.$store.dispatch('account/getUserProfile');
          await this.fetchData(this.bunicornId);
          this.$dialog.notify.success('You\'ve avoid halving rate successfully');
        }
      } catch (error) {
        this.$dialog.notify.error(error);
      }
    },
    async moveBunicorn(buniHouseId) {
      try {
        const params = {
          bunicornIds: [Number(this.bunicornId)],
          buniHouseIdOld: this.bunicornData.buniHouseId,
          buniHouseIdNew: buniHouseId,
        };
        const res = await rf.getRequest('IsLandRequest').moveBunicorn(params);
        if (res) {
          console.log('move', res);
          this.$dialog.notify.success('You\'ve moved successfully!');
        }
      } catch (error) {
        this.$dialog.notify.error(error);
      }
    },
    async fetchData(bunicornId) {
      const res = await rf
        .getRequest('IsLandRequest')
        .getMyBunicorn(bunicornId);
      if (res) {
        this.bunicornData = res;
        this.rows[0].data = res.skills;
      }
    },
    async getRequirementFood(){
      const res = await rf.getRequest('BunicornRequest').getRequirementFood(this.bunicornId);
      if (res) {
        this.amountFoodForOneTime = res.amountFoodForOneTime;
        this.amountFoodForNextLevel = res.amountFoodForNextLevel;
      }
    },
    goToBuniHouse() {
      return this.$router.back();
    }
  },
  created() {
    this.bunicornId = this.$route.query.id;
  },
  async mounted() {
    await this.fetchData(this.bunicornId);
    await this.getRequirementFood(this.bunicornId);
  },
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';
::v-deep .name {
  display: flex;
  align-items: center;
  .level-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;
    .element {
      margin-left: 8px;
      margin-top: -8px;
    }
    .progress-level {
      margin-top: -8px;
      margin-left: 8px;
      height: inherit !important;
    }
  }
}
.stars {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin: 0 2px;
    object-fit: contain;
  }
}
.info-wrapper,
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-wrapper {
  font-size: 1.5rem;
  width: 100%;
  .win-count-info {
    margin-left: 4px;
    cursor: pointer;
  }
  &.first-row {
    margin-top: 30px;
  }
  &.second-row {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .avoid-halving {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .custom-btn {
      width: fit-content;
      padding: 4px 20px;
      font-size: 1rem;
      border-radius: 8px;
      cursor: pointer;
      &.disable {
        cursor: not-allowed;
        &:hover {
          background: #fff;
        }
      }
      &:hover {
        background-color: #e0e0e0;
      }
    }
    div {
      margin: 0 10px;
    }
  }
}
.button-wrapper {
  .custom-btn {
    margin: 30px 20px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0;
    border-radius: 8px;
    cursor: pointer;
    &.disable {
        cursor: not-allowed;
        &:hover {
          background: #fff;
        }
      }
    &:hover {
      background-color: #e0e0e0;
    }
  }
}
.skill {
  width: 24%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid #000;
  position: relative;
  &.disabled {
    opacity: 0.7;
  }
  .name {
    font-size: 1.25rem;
  }
  .skill-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.5rem;
    flex-wrap: nowrap !important;
    padding: 16px;
    img {
      width: 40%;
      height: auto;
      max-height: 60px;
      object-fit: contain;
    }
    .info {
      white-space: nowrap;
      margin-left: 16px;
      flex-direction: column;
      justify-content: space-between;
    }
    .txt-require-level {
      font-size: 14px;
    }
  }
  .btn-wrapper {
    position: absolute;
    width: 100%;
    bottom: -20px;
    .custom-btn {
      width: 150px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      border-radius: 8px;
      &.disable {
        cursor: not-allowed;
        &:hover {
          background-color: #ffffff;
        }
      }
      cursor: pointer;
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-tablet) {
  .stars {
    justify-content: center;
  }
  .info-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    &.first-row {
      margin-top: 10px;
    }
    &.second-row {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    div {
      margin: 10px 30px;
    }
  }
  .button-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    .custom-btn {
      margin: 10px 10px;
    }
  }
  ::v-deep .detail-container .row-wrapper .content {
    overflow-x: auto;
    flex-wrap: nowrap;
    .skill {
      margin: 20px;
    }
  }
  .skill {
    width: 48%;
    min-width: fit-content;
    .btn-wrapper {
      .custom-btn {
        width: 100px;
      }
    }
  }
}
</style>
