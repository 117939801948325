import homestead from '@/config/homestead.json';
import staging from '@/config/staging.json';
import dev from '@/config/dev.json';
import canary from '@/config/canary.json';

const env = process.env.VUE_APP_ENV || 'production';
const configs = { homestead, dev, staging, canary };
// @ts-ignore
const config: Config = configs[env];

export interface Config {
  configWallet: {
    chainId: number;
    chainIdHex: string;
    chainName: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    explorer: string;
    buniCurrency: string;
    burCurrency: string;
    blockExplorerUrls: string;
    blockTime: number;
    type: string;
    options: {
      address: string;
      symbol: string;
      decimals: number;
      image: string;
    };
    burOptions: {
      address: string;
      symbol: string;
      decimals: number;
      image: string;
    };
    connectors: ConnectorConfig;
    addresses: {
      multicall: string;
    };
    rpcUrl: string[];
    wsUrl: string;
  };
  addresses: {
    gameContract: string,
    marketContract: string,
    buniTokenContract: string,
    trainerContract: string,
    bunicornContract: string,
    islandContract: string,
    burTokenContract: string,
    scrollTokenContract: string
  },
  api: {
    islandApi: string,
    trainerApi: string,
    bunicornApi: string,
    usersApi: string,
    authenticationApi: string
  }
  nftMetadataDebugUrl: string,
  referralLink: string,
  metamaskDeeplink: string,
  token: tokenConfig,
  webSocketUrl: string,
  battleApi: string,
  userApi: string,
}
export interface ConnectorConfig {
  [key: string]: ConnectorMetadata;
}
export interface ConnectorMetadata {
  id: string;
  name: string;
  options: any;
}

export interface gachaIds {
  normaHunt: number;
  epicHunt: number;
  eventHunt: number;
  shardHunt: number;
}

export interface tokenConfig {
  daysExpire: number;
  secret: string;
}

export const SCREEN_SIZE = {
  MOBILE: 576,
  TABLET: 768,
  DESKTOP: 1024
};

export default config;
