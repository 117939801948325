<template>
  <div>
  <b-dropdown size="sm" id="bur-info-dropdown"
    variant="link"
    toggle-class="text-decoration-none custom-toggle" no-caret
    class="custom-dropdown"
    @show="fetchWalletBurBalance">
    <template #button-content>
       ▼
    </template>
    <b-dropdown-item disabled>
      <div class="mb-2 d-block item-connect">
        <div class="connector custom-item">
          <div class="connector-name">
            BUR in Wallet: {{ walletBurBalance }}
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <!-- <b-dropdown-group id="dropdown-group-2" header="BUR rewards" class="dropdown-group-header">
      <b-dropdown-item-button>
        <div class="mb-2 d-block item-connect">
          <div class="connector custom-item-rewards">
            <div class="connector-name">
              <div v-b-tooltip="'BUR rewards before the zero gas fee system is deployed'">
                Onchain Balance: 0 BUR
              </div>
              <div v-b-tooltip.bottom="'BUR rewards after the zero gas fee system is deployed'">
                Offchain Balance: 0 BUR
              </div>
            </div>
          </div>
        </div>
      </b-dropdown-item-button>
    </b-dropdown-group> -->
    <b-dropdown-item>
      <div class="mb-2 d-block item-connect custom-claim-bur action-bur">
        <div class="connector custom-item" @click="claimBuni">
          <div class="connector-name">
            Claim BUNI
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item>
      <div class="mb-2 d-block item-connect custom-claim-bur action-bur">
        <div class="connector custom-item" @click="$bvModal.show('deposit-buni-popup')">
          <div class="connector-name">
            Deposit BUNI
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item>
      <div class="mb-2 d-block item-connect custom-claim-bur action-bur">
        <div class="connector custom-item" @click="claimBur">
          <div class="connector-name">
            Claim BUR
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item>
      <div class="mb-2 d-block item-connect custom-claim-bur action-bur">
        <div class="connector custom-item" @click="$bvModal.show('deposit-bur-popup')">
          <div class="connector-name">
            Deposit BUR
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item>
      <div class="mb-2 d-block item-connect custom-claim-bur action-bur">
        <div class="connector custom-item" @click="onBuyMore('bur')">
          <div class="connector-name">
            Buy BUR
          </div>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
  <DepositBurModal />
  <DepositBuniModal />
  <ClaimBurModal />
  <ClaimBuniModal />
  </div>
</template>

<script>
import { getExchangeUrl, fetchBurBalance } from '@/utils/utils';
import { convertWeiToDec,convertDecToWei } from '@/utils/helpers';
import { mapActions } from 'vuex';
import { handleTransactionError } from '@/utils/helpers';
import DepositBurModal from '@/components/DepositBurModal.vue';
import DepositBuniModal from '@/components/DepositBuniModal.vue';
import ClaimBurModal from '@/components/ClaimBurModal.vue';
import ClaimBuniModal from '@/components/ClaimBuniModal.vue';

export default {
  name: 'BurInfoDropdown',
  data(){
    return {
      walletBurBalance: 0
    };
  },
  components: {
    DepositBurModal,
    DepositBuniModal,
    ClaimBurModal,
    ClaimBuniModal
  },
  methods: {
    ...mapActions('account', ['account', 'currentNetworkId', 'profile']),
    convertWeiToDec,
    convertDecToWei,
    handleTransactionError,
    onBuyMore(currency) {
      window.open(getExchangeUrl(currency), '_blank');
    },
    viewPendingTransactions() {
      this.$router.push({name: 'pending-transactions'});
    },
    claimBur() {
      this.$bvModal.show('claim-bur-modal');
    },
    claimBuni() {
      this.$bvModal.show('claim-buni-modal');
    },
    async fetchWalletBurBalance(){
      const latestBurBalance = await fetchBurBalance();
      this.walletBurBalance = convertWeiToDec(latestBurBalance);
    }
  }
};
</script>

<style lang="scss" scoped>
#bur-info-dropdown {
  ::v-deep button {
    all: unset;
    cursor: pointer;
  }
}
.dropdown-group-header .dropdown-header {
  color: white;
}

.custom-item, .custom-item-rewards {
  min-width: unset;
  margin: 0 8px;
}

.custom-claim-bur {
  margin-top: 10px!important;
}

.action-bur {
  margin-bottom: 4px !important;
}

.add-button {
  position: absolute;
  top: 0;
  left: 0;
}
.connector.custom-item.disable {
  cursor: not-allowed;
  opacity: 0.8;
}
.add-button:hover {
  cursor: pointer;
}
</style>
