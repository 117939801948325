<template>
  <div class="inventory-container">
    <!-- <div class="back-button" @click="$router.push({ name: 'home' })">
      <img src="/images/back-button.png" />
    </div> -->
    <!-- <div class="title">Inventory</div> -->
    <div class="button-wrapper">
      <custom-button
        @handleClick="$bvModal.show('deposit-nft-modal')"
      >
        Deposit NFT
      </custom-button>
      <custom-button
        @handleClick="$bvModal.show('withdraw-nft-modal')"
      >
        Withdraw NFT
      </custom-button>
      <custom-button
        @handleClick="$router.push('/pending-transactions')"
      >
        Pending Transactions
      </custom-button>
    </div>
    <div class="content-container">
      <div class="section-title">
        My Account
      </div>
      <div class="account-wrapper">
        <template v-if="userAccount">
          username: <span class="username">{{userAccount}}</span>/ password: *******
          <custom-button @handleClick="$bvModal.show('change-password-popup');">
            Change password
          </custom-button>
        </template>
        <template v-else>
          You have no account yet.
          <custom-button @handleClick="$bvModal.show('sign-up-account-popup');">
            Resgister a new one
          </custom-button>
        </template>
      </div>
      <div class="section-title">
        My Items
        <custom-button class='button-deposit' @handleClick="$bvModal.show('deposit-item-popup');">
          Deposit Scrolls
        </custom-button>
      </div>
      <div class="stone-wrapper">
        <template>
          <div class="stone-item">
            <img
              class="element"
              :src="'/images/game-items/scroll.png'"
            />
            <div class="mt-1">{{ scrollBalance ? scrollBalance : '-' }}</div>
            <div class="item-name">Scrolls</div>
          </div>
        </template>
      </div>
      <div class="section-title">Element Stone</div>
      <div class="stone-wrapper">
        <template v-for="(value, elementToken) in elementTokens">
          <div class="stone-item" :key="elementToken.toLowerCase()">
            <img
              class="element"
              :src="`/images/${elementToken.toLowerCase()}.png`"
            />
            <div class="mt-1">{{ value }}</div>
            <div class="item-name">{{ elementToken.toLowerCase() }} stone</div>
          </div>
        </template>
      </div>
      <div class="section-title">
        My Bunicorns
        <!-- <custom-button
          v-if="avoidAllHalvingBunicornFee"
          class='button-deposit'
          @handleClick="avoidAllHalvingBunicorn()">
          <span>Avoid Halving For All Bunicorn</span>
          <span v-if="avoidAllHalvingBunicornFee.burWei > 0">({{avoidAllHalvingBunicornFee.burWei | formatWeiAmount}} BUR)</span>
        </custom-button> -->
      </div>
      <div class="bunicorn-container">
        <div class="search-filter">
          <div class="element-filter">
            <b-form-checkbox-group v-model="bunicornParams.element">
              <b-form-checkbox class="select-box" value="air">
                <img class="element" src="@/assets/icon/air.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="earth">
                <img class="element" src="@/assets/icon/earth.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="fire">
                <img class="element" src="@/assets/icon/fire.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="water">
                <img class="element" src="@/assets/icon/water.png" />
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
          <div class="clear-filter-wrapper">
            <div class="stars-filter">
              <div
                v-for="starNumber in [1, 2, 3, 4, 5]"
                :key="starNumber"
                @click="filterStar(starNumber)"
                @mouseover="hoverStarFilter = starNumber"
                @mouseout="hoverStarFilter = 0"
                :class="
                  hoverStarFilter <= starFilter &&
                  starFilter > starNumber - 1 &&
                  (hoverStarFilter > starNumber - 1 || hoverStarFilter == 0)
                    ? 'active-star'
                    : hoverStarFilter > starNumber - 1 &&
                      hoverStarFilter >= starFilter
                    ? 'active-star'
                    : ''
                "
              />
            </div>
            <div class="clear-filter sketchy" @click="clearBunicornFilter">
              Clear filter
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <template v-if="bunicorns.length > 0">
            <div
              class="card-wrapper"
              v-for="bunicorn in bunicorns"
              :key="bunicorn.bunicornId"
            >
              <div class="bunicorn-card" @click="redirectBunicornDetail(bunicorn.bunicornId)">
                <div class="image-wrapper">
                  <div class="warning" v-if="bunicorn.halvingRate && bunicorn.halvingRate.halvingWarning">
                    ⚠️ Hurry up and skip this halving!
                  </div>
                  <img :src="bunicorn.metaData.medium_image" />
                </div>
                <div class="info-wrapper">
                  <div class="id">ID:{{ bunicorn.bunicornId }}</div>
                  <div class="name">{{ bunicorn.metaData.name }}</div>
                </div>
                <div class="info-wrapper">
                  <div v-if="bunicorn.island">
                    {{ bunicorn.island ? bunicorn.island.element : '' }} island
                  </div>
                  <div v-if="bunicorn.buniHouseId">
                    Bunihouse Id: {{ bunicorn.buniHouseId || 0 }}
                  </div>
                </div>
                <div class="info-wrapper">
                  <div v-if="bunicorn.displayGoldRate">
                    Gold Rate: {{ bunicorn.displayGoldRate | formatNumber }}
                  </div>
                </div>
              </div>
              <div class="btn-wrapper" :style="{visibility: bunicorn.bunicornId < 0 ? 'hidden' : 'visible'}">
                <custom-button
                  @handleClick="sellSpecificNft('bunicorn', bunicorn.bunicornId, bunicorn.buniHouseId)"
                >
                  Sell
                </custom-button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="blank-state">
              There is no Bunicorn matched this filter.
            </div>
          </template>
        </div>
        <div class="pagination-wrapper">
          <b-pagination
            v-model="bunicornParams.page"
            :total-rows="totalBunicorns"
            :per-page="bunicornParams.limit"
          ></b-pagination>
        </div>
      </div>
      <div class="section-title">
        My Trainers
        <!-- <custom-button
          v-if="avoidAllHalvingTrainerFee"
          class='button-deposit'
          @handleClick="avoidAllHalvingTrainer()">
          <span>Avoid Halving For All Trainer</span> 
          <span v-if="avoidAllHalvingTrainerFee.burWei > 0">({{avoidAllHalvingTrainerFee.burWei | formatWeiAmount}} BUR)</span>
        </custom-button> -->
      </div>
      <div class="bunicorn-container">
        <div class="search-filter">
          <div class="element-filter">
            <b-form-checkbox-group v-model="trainerParams.element">
              <b-form-checkbox class="select-box" value="air">
                <img class="element" src="@/assets/icon/air.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="earth">
                <img class="element" src="@/assets/icon/earth.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="fire">
                <img class="element" src="@/assets/icon/fire.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="water">
                <img class="element" src="@/assets/icon/water.png" />
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
          <div class="clear-filter-wrapper">
            <b-form-select
              class="level-filter"
              v-model="selectedLevel"
              :options="levels"
            ></b-form-select>
            <div class="clear-filter sketchy" @click="clearTrainerFilter">
              Clear filter
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <template v-if="trainers.length > 0">
            <div
              class="card-wrapper"
              v-for="trainer in trainers"
              :key="trainer.trainerId"
            >
              <div class="trainer-card" @click="redirectTrainerDetail(trainer.trainerId)">
                <div class="image-wrapper">
                  <div class="warning" v-if="trainer.halvingRate && trainer.halvingRate.halvingWarning">
                    ⚠️ Hurry up and skip this halving!
                  </div>
                  <img :src="trainer.metaData.medium_image" />
                </div>
                <div class="info-wrapper">
                  <div class="id">ID: {{ trainer.trainerId }}</div>
                  <div class="name">{{ trainer.metaData.name }}</div>
                </div>
                <div class="info-wrapper">
                  <div class="id">Level: {{ trainer.level + 1 }}</div>
                  <div v-if="trainer.displayBaselineRate">
                    Baseline Rate: {{ trainer.displayBaselineRate | formatNumber }}
                  </div>
                </div>
                <div class="info-wrapper">
                  <div :style="{color: trainer.island && trainer.island.element ? '' : 'transparent'}">
                    {{ trainer.island ? trainer.island.element : '' }} island
                  </div>
                  <div :style="{color: trainer.buniHouseId ? '' : 'transparent'}">
                    Bunihouse Id: {{ trainer.buniHouseId || 0 }}
                  </div>
                </div>
              </div>
              <div class="btn-wrapper">
                <custom-button
                  @handleClick="sellSpecificNft('trainer', trainer.trainerId, trainer.buniHouseId)"
                  :style="{visibility: trainer.trainerId < 0 ? 'hidden' : 'visible'}"
                >
                  Sell
                </custom-button>
              </div>
            </div>

          </template>
          <template v-else>
            <div class="blank-state">
              There is no trainer matched this filter.
            </div>
          </template>
        </div>
        <div class="pagination-wrapper">
          <b-pagination
            v-model="trainerParams.page"
            :total-rows="totalTrainers"
            :per-page="trainerParams.limit"
          ></b-pagination>
        </div>
      </div>
      <div class="section-title">My Eggs</div>
      <div class="bunicorn-container">
        <div class="search-filter">
          <div class="element-filter">
            <b-form-checkbox-group v-model="eggParams.element">
              <b-form-checkbox class="select-box" value="air">
                <img class="element" src="@/assets/icon/air.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="earth">
                <img class="element" src="@/assets/icon/earth.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="fire">
                <img class="element" src="@/assets/icon/fire.png" />
              </b-form-checkbox>
              <b-form-checkbox class="select-box" value="water">
                <img class="element" src="@/assets/icon/water.png" />
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
          <div class="clear-filter-wrapper">
            <div class="stars-filter">
              <div
                v-for="starNumber in [1, 2, 3, 4, 5]"
                :key="starNumber"
                @click="filterStarEgg(starNumber)"
                @mouseover="hoverStarFilterEgg = starNumber"
                @mouseout="hoverStarFilterEgg = 0"
                :class="
                  hoverStarFilterEgg <= starFilterEgg &&
                  starFilterEgg > starNumber - 1 &&
                  (hoverStarFilterEgg > starNumber - 1 || hoverStarFilterEgg == 0)
                    ? 'active-star'
                    : hoverStarFilterEgg > starNumber - 1 &&
                      hoverStarFilterEgg >= starFilter
                    ? 'active-star'
                    : ''
                "
              />
            </div>
            <div class="clear-filter sketchy" @click="clearEggFilter">
              Clear filter
            </div>
          </div>
        </div>
         <div class="content-wrapper">
          <template v-if="eggs.length > 0">
            <div
              class="card-wrapper"
              v-for="egg in eggs"
              :key="egg.eggId"
            >
              <div class="bunicorn-card">
                <img :src="getEggImage(egg.element,egg.stars)" />
                <div class="info-wrapper">
                  <div class="id">ID:{{ egg.eggId }}</div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="blank-state">
              There is no Egg matched this filter.
            </div>
          </template>
        </div>
        <div class="pagination-wrapper">
          <b-pagination
            v-model="eggParams.page"
            :total-rows="totalEggs"
            :per-page="eggParams.limit"
          ></b-pagination>
        </div>
       </div>
    </div>
    <DepositItemModal />
    <WithdrawNftModal />
    <DirectSellingNftModal
      :nftType="specificSellNftType"
      :typeDisplay="specificSellNftType"
      :inGameNftId="specificSellNftId"
      :buniHouseId="specificSellNftBunihouseId"
      @onSellNftSuccess="reloadPage"
    />
    <SignUpAccountModal @refreshUsername="fetchUserData" />
    <ChangePasswordModal v-if="userAccount" :userAccount="userAccount"/>
  </div>
</template>

<script>
import rf from '@/requests/RequestFactory';
import { mapState } from 'vuex';
import BigNumber from 'bignumber.js';
import { cleanObject, getEggImage } from '@/utils/helpers';
import WithdrawNftModal from '@/components/WithdrawNftModal/WithdrawNftModal.vue';
import DirectSellingNftModal from '@/components/DirectSellingNftModal.vue';
import CustomButton from '@/components/CustomButton.vue';
import DepositItemModal from '@/components/DepositItemModal.vue';
import { handleTransactionError } from '@/utils/helpers';
import SignUpAccountModal from '@/components/SignUpAccountModal.vue';
import ChangePasswordModal from '@/components/ChangePasswordModal.vue';

export default {
  data() {
    return {
      bunicorns: [],
      trainers: [],
      eggs: [],
      totalBunicorns: 0,
      totalTrainers: 0,
      totalEggs: 0,
      hoverStarFilter: 0,
      selectedLevel: '',
      starFilter: '',
      specificSellNftId: 0,
      specificSellNftType: '',
      userAccount: '',
      specificSellNftBunihouseId: null,
      avoidAllHalvingBunicornFee: null,
      avoidAllHalvingTrainerFee: null,
      bunicornParams: {
        limit: 4,
        page: 1,
        element: [],
        sortById: 1,
        sortByStar: 1,
      },
      trainerParams: {
        page: 1,
        limit: 4,
        levelFrom: '',
        levelTo: '',
        element: [],
      },
      eggParams: {
        page: 1,
        limit: 4,
        element: [],
      },
      hoverStarFilterEgg: 0,
      starFilterEgg: '',
      levels: [
        {
          text: 'Level',
          value: '',
        },
        {
          text: '1 - 10',
          value: {
            levelFrom: 0,
            levelTo: 9,
          },
        },
        {
          text: '11 - 20',
          value: {
            levelFrom: 10,
            levelTo: 19,
          },
        },
        {
          text: '21 - 30',
          value: {
            levelFrom: 20,
            levelTo: 29,
          },
        },
        {
          text: '31 - 40',
          value: {
            levelFrom: 30,
            levelTo: 39,
          },
        },
        {
          text: '41 - 50',
          value: {
            levelFrom: 40,
            levelTo: 49,
          },
        },
        {
          text: '51 - 100',
          value: {
            levelFrom: 50,
            levelTo: 99,
          },
        },
        {
          text: '101 - 150',
          value: {
            levelFrom: 100,
            levelTo: 149,
          },
        },
        {
          text: '151 - 200',
          value: {
            levelFrom: 150,
            levelTo: 199,
          },
        },
        {
          text: '201 - 250',
          value: {
            levelFrom: 200,
            levelTo: 249,
          },
        },
        {
          text: '251 - 256',
          value: {
            levelFrom: 250,
            levelTo: 255,
          },
        },
      ],
    };
  },
  mounted() {
    this.fetchBunicornData();
    this.fetchTrainerData();
    this.fetchEggData();
    this.fetchAvoidAllHalvingBunicornFee();
    this.fetchAvoidAllHalvingTrainerFee();
    this.fetchUserData();
  },
  watch: {
    starFilter() {
      this.bunicornParams = {
        ...this.bunicornParams,
        starsFrom: this.starFilter,
        starsTo: this.starsTo,
      };
    },
    starFilterEgg() {
      this.fetchEggData();
    },
    bunicornParams: {
      deep: true,
      handler() {
        this.fetchBunicornData();
      },
    },
    selectedLevel() {
      this.trainerParams.levelFrom = this.selectedLevel?.levelFrom || '';
      this.trainerParams.levelTo = this.selectedLevel?.levelTo || '';
    },
    trainerParams: {
      deep: true,
      handler(){
        this.fetchTrainerData();
      }
    },
    eggParams: {
      deep: true,
      handler(){
        this.fetchEggData();
      }
    }
  },
  components: { WithdrawNftModal, DirectSellingNftModal, CustomButton, DepositItemModal, SignUpAccountModal, ChangePasswordModal },
  computed: {
    ...mapState('account', ['profile']),
    elementTokens() {
      if (!this.profile) {
        return [];
      }
      return {
        fire: this.profile.fireStone,
        water: this.profile.waterStone,
        earth: this.profile.earthStone,
        air: this.profile.airStone,
      };
    },
    scrollBalance() {
      if (!this.profile) {
        return null;
      }
      return this.profile.scroll;
    },
    burBalance() {
      if(!this.$store.state.account?.profile) return 0;
      return this.$store.state.account.profile.burInGame;
    },
  },
  methods: {
    getEggImage,
    cleanObject,
    clearBunicornFilter() {
      this.bunicornParams = {
        page: 1,
        limit: 4,
        element: [],
      };
      this.starFilter = '';
    },
    clearEggFilter() {
      this.eggParams = {
        page: 1,
        limit: 4,
        element: [],
      };
      this.starFilterEgg = '';
    },
    filterStar(starNumber) {
      this.starFilter = starNumber;
    },
    filterStarEgg(starNumber) {
      this.starFilterEgg = starNumber;
    },
    clearTrainerFilter() {
      this.trainerParams = {
        page: 1,
        limit: 4,
        levelFrom: '',
        levelTo: '',
        element: [],
      };
      this.selectedLevel = '';
    },
    getBunicornGoldRate(bunicorn) {
      if (!bunicorn) {
        return 0;
      }
      if (!bunicorn.halvingRate) {
        return bunicorn.goldRate;
      }
      return new BigNumber(bunicorn.goldRate || 0)
        .multipliedBy(bunicorn.halvingRate.rate || 0)
        .toString();
    },
    getTrainerBaselineRate(trainer) {
      if (!trainer) {
        return 0;
      }
      return trainer.baseline * trainer.halvingRate.rate;
    },
    redirectBunicornDetail(bunicornId){
      this.$router.push({
        name: 'bunicorn-detail',
        query: { id: bunicornId },
      });
    },
    redirectTrainerDetail(trainerId){
      this.$router.push({
        name: 'trainer-detail',
        query: { id: trainerId },
      });
    },
    sellSpecificNft(nftType, ingameNftId, bunihouseId){
      this.specificSellNftId = ingameNftId;
      this.specificSellNftType = nftType;
      this.specificSellNftBunihouseId = bunihouseId;
      this.$bvModal.show('direct-sell-nft-modal');
    },
    async fetchUserData() {
      const res = await rf
        .getRequest('AuthenticationRequest')
        .getUserAccount();
      if (res) {
        this.userAccount = res.username;
        console.log('this.userAccount', this.userAccount);
      }
    },
    async fetchBunicornData() {
      const params = {
        ...this.bunicornParams,
        starsFrom: this.starFilter ? Number(this.starFilter) - 1 : '',
        starsTo: this.starFilter ? Number(this.starFilter) - 1 : '',
        element: this.bunicornParams.element.toString()
      };
      const res = await rf
        .getRequest('UserRequest')
        .getMyBunicornInGameV2(this.cleanObject(params));
      if (res) {
        this.bunicorns = res.docs.map(b => {return {...b, displayGoldRate: this.getBunicornGoldRate(b)};});
        this.totalBunicorns = res.totalDocs;
      }
    },
    async fetchTrainerData() {
      const res = await rf
        .getRequest('UserRequest')
        .getMyTrainerInGameV2(this.cleanObject({...this.trainerParams, element: this.trainerParams.element.toString()}));
      if (res) {
        this.trainers = res.docs.map(t => {return {...t, displayBaselineRate: this.getTrainerBaselineRate(t)};});
        this.totalTrainers = res.totalDocs;
      }
    },
    async fetchEggData() {
      const params = {
        ...this.eggParams,
        starsFrom: this.starFilterEgg ? this.starFilterEgg - 1 : '',
        starsTo: this.starFilterEgg ? this.starFilterEgg - 1 : '',
        element: this.eggParams.element.toString(),
      };
      const res = await rf
        .getRequest('HatcheryRequest')
        .getEggs(this.cleanObject(params));
      if (res) {
        this.eggs = res.docs;
        this.totalEggs = res.totalDocs;
      }
    },
    async fetchAvoidAllHalvingBunicornFee() {
      const res = await rf
        .getRequest('IsLandRequest')
        .getAvoidAllHalvingBunicornFee();
      if (res) {
        this.avoidAllHalvingBunicornFee = res;
      }
    },
    async fetchAvoidAllHalvingTrainerFee() {
      const res = await rf
        .getRequest('IsLandRequest')
        .getAvoidAllHalvingTrainerFee();
      if (res) {
        this.avoidAllHalvingTrainerFee = res;
      }
    },
    async avoidAllHalvingBunicorn() {
      if (this.avoidAllHalvingBunicornFee.burWei <= 0) {
        this.$dialog.notify.warning('None of your Bunicorn needs avoiding halving rate');
        return;
      }
      if (new BigNumber(this.burBalance).comparedTo(this.avoidAllHalvingBunicornFee.burWei ) < 0){
        this.$dialog.notify.error('Not enough BUR balance');
        return;
      }
      try {
        const res = await rf
          .getRequest('IsLandRequest')
          .avoidAllHalvingBunicorn();
        if (res) {
          await this.fetchAvoidAllHalvingBunicornFee();
          this.$store.dispatch('account/getUserProfile');
          this.$dialog.notify.success('You have avoided all of your bunicorns\' halving rate');
        }
      } catch(error) {
        const msg = handleTransactionError(error);
        this.$dialog.notify.error(msg);
      }
    },
    async avoidAllHalvingTrainer() {
      if (this.avoidAllHalvingTrainerFee.burWei <= 0) {
        this.$dialog.notify.warning('None of your Trainer needs avoiding halving rate');
        return;
      }
      if (new BigNumber(this.burBalance).comparedTo(this.avoidAllHalvingTrainerFee.burWei ) < 0){
        this.$dialog.notify.error('Not enough BUR balance');
        return;
      }
      try {
        const res = await rf
          .getRequest('IsLandRequest')
          .avoidAllHalvingTrainer();
        if (res) {
          await this.fetchAvoidAllHalvingTrainerFee();
          this.$store.dispatch('account/getUserProfile');
          this.$dialog.notify.success('You have avoided all of your trainers\' halving rate');
        }
      } catch(error) {
        const msg = handleTransactionError(error);
        this.$dialog.notify.error(msg);
      }
    },
    reloadPage(){
      window.location.reload();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/config/_variables";
.inventory-container {
  display: flex;
  flex-direction: column;
  position: relative;
  .back-button {
    cursor: pointer;
    img {
      width: auto;
      height: 40px;
    }
  }
  .title {
    font-size: 2.5rem;
    text-align: center;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin: 2px;
    }
  }
  .content-container {
    .account-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .username {
        padding: 0 4px;
        font-size: larger;
        text-transform: initial;
      }
      div {
        margin: 4px;
      }
    }
    .section-title {
      font-size: 1.8rem;
      margin-top: 16px;
      margin-right: 10px;
      display: flex;
      .button-deposit {
        margin-left: 20px;
        font-size: 1rem;
      }
    }
    .stone-wrapper {
      display: flex;
      margin: 0 0;
      padding: 20px 0;
      .stone-item {
        padding: 20px 10px;
        width: 100px;
        text-align: center;
      }
    }
  }
  .bunicorn-container {
    margin-top: 20px;
    .blank-state {
      height: 150px;
      text-align: center;
      margin: auto;
      font-size: 2rem;
    }
    .search-filter {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 20px;
      .level-filter {
        width: 180px;
      }
      .element-filter {
        ::v-deep .custom-control {
          input {
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
          }
          label::before,
          label::after {
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
          }
          .element {
            margin-left: 8px;
          }
        }
      }
      .stars-filter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        & > div {
          margin: 0 3px;
          background-image: url("../assets/icon/star.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          transition: 0.5s ease;
          width: 22px;
          height: 22px;
        }
        & > div:hover,
        .active-star {
          background-image: url("../assets/icon/rate-star.png") !important;
        }
        & > img {
          margin: 0 1px;
          opacity: 0.4;
          cursor: pointer;
          transition: 0.5s ease;
          &:hover {
            opacity: 1;
          }
        }
      }
      .clear-filter {
        padding: 8px;
        width: 120px;
        text-transform: uppercase;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30px;
        &:hover {
          background-color: #e0e0e0;
          cursor: pointer;
        }
      }
      .clear-filter-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    .content-wrapper {
      display: flex;
      justify-content: center;
      margin: 30px auto;
      padding: 20px 0;
      overflow: auto;
      flex-wrap: wrap;
      .card-wrapper{
        display: flex;
        flex-direction: column;
        width: 25%;
        margin: 20px 0;
        .bunicorn-card, .trainer-card {
          .image-wrapper {
            position: relative;
            .warning {
              position: absolute;
              width: fit-content;
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: #fefe97;
              border-radius: 4px;
              padding: 8px 16px;
            }
          }
        }
        .bunicorn-card {
          min-width: 210px;
          margin: 0 10px;
          flex-grow: 1;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          cursor: pointer;
          &:hover {
            box-shadow: 1px 1px 5px 0 #000;
            transform: scale(1.05);
          }
          &.active {
            box-shadow: 1px 1px 5px 0 #000;
            transform: scale(1.05);
          }
          img {
            width: 100%;
            height: auto;
            margin: 0 auto 20px auto;
            object-fit: contain;
          }
          .info-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .trainer-card {
          min-width: 210px;
          margin: 0 8px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          cursor: pointer;
          &:hover {
            box-shadow: 1px 1px 5px 0 #000;
            transform: scale(1.05);
          }
          &.active {
            box-shadow: 1px 1px 5px 0 #000;
            transform: scale(1.05);
          }
          img {
            width: 100%;
            height: auto;
            margin: 0 auto 20px auto;
            object-fit: contain;
          }
          .info-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .btn-wrapper{
          width: 100%;
          margin-top: 18px;
          display: flex;
          justify-content: center;
        }
      }
    }
    .pagination-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}


@media screen and (max-width: $breakpoint-tablet) {
  .inventory-container {
    width: 95vw;
    margin: auto;

    .bunicorn-container{
      .search-filter{
        flex-direction: column;
      }
      .content-wrapper{
        max-width: 95vw;
        display: flex;
        flex-wrap: wrap;

        .card-wrapper{
          .bunicorn-card {
            min-width: 140px;
          }
          .trainer-card{
            min-width: 140px;
          }
        }
      }
      .blank-state{
        grid-column: 1 / span 2;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .inventory-container .bunicorn-container .content-wrapper .card-wrapper {
    width: 50%;
    align-items: center;
    .bunicorn-card, .trainer-card {
      .image-wrapper {
        .warning {
          background-color: #fefe97e3;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
