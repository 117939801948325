<template>
  <b-modal id="withdraw-nft-modal" size="xl" hide-footer hide-header centered>
    <div class="title">Withdraw NFT</div>
    <div class="close" @click="closeModal">x</div>
    <vue-step
      :now-step="currentStep"
      :step-list="stepCreateNewList"
      :style-type="'style2'"
      :active-color="'#269aa1'"
    />
    <div class="content-wrapper" v-if="currentStep === 1">
      <div
        class="type-wrapper"
        :class="withdrawType === 'trainer' ? 'selected' : ''"
        @click="chooseType('trainer')"
      >
        <img
          class="type-img"
          src="/images/trainer-bunicorn/empty-trainer.png"
        />
        <div class="type-name">Trainer</div>
      </div>
      <div
        class="type-wrapper"
        :class="withdrawType === 'bunicorn' ? 'selected' : ''"
        @click="chooseType('bunicorn')"
      >
        <img
          class="type-img"
          src="/images/trainer-bunicorn/empty-bunicorn.png"
        />
        <div class="type-name">Bunicorn</div>
      </div>
    </div>
    <template v-if="currentStep === 2">
      <ChooseBunicorn
        @handleSelectNft="handleSelectNft"
        v-if="withdrawType === 'bunicorn'"
      />
      <ChooseTrainer
        @handleSelectNft="handleSelectNft"
        v-if="withdrawType === 'trainer'"
      />
    </template>
    <div class="button-wrapper">
      <template v-if="currentStep === 1">
        <custom-button @handleClick="closeModal">Cancel</custom-button>
        <custom-button @handleClick="handleNextStep">Next</custom-button>
      </template>
      <template v-if="currentStep === 2">
        <custom-button @handleClick="previousStep">Back</custom-button>
        <custom-button
          @handleClick="handleConfirm"
          :isDisabled="isDisabledConfirm()"
          :isLoading="isDepositing"
        >
          Confirm
        </custom-button>
      </template>
    </div>
  </b-modal>
</template>

<script>
import vueStep from 'vue-step';
import ChooseBunicorn from '@/components/WithdrawNftModal/ChooseBunicorn.vue';
import ChooseTrainer from '@/components/WithdrawNftModal/ChooseTrainer.vue';
import { withdrawNft, isUserDenyTxMetaMask } from '@/utils/utils';
import rf from '@/requests/RequestFactory';
import config from '@/config';
import CustomButton from '@/components/CustomButton.vue';
import {sleep} from '@/utils/helpers';

const stepName = ['Choose Type', 'Choose NFT'];
const stepCreateNewList = ['Step 1', 'Complete'];
// import rf from '@/requests/RequestFactory';

export default {
  data() {
    return {
      stepName,
      currentStep: 1,
      stepCreateNewList,
      withdrawType: null,
      withdrawParams: {
        nftContract: null,
        selectedNftIds: null,
        requestId: null,
        signature: null
      },
      isDepositing: false
    };
  },
  components: {vueStep, ChooseBunicorn, ChooseTrainer, CustomButton},
  methods: {
    withdrawNft,
    closeModal() {
      this.withdrawParams.selectedNftIds = null;
      this.withdrawParams.nftContract = null;
      this.withdrawParams.requestId = null;
      this.withdrawParams.signature = null;
      this.currentStep = 1;
      this.$bvModal.hide('withdraw-nft-modal');
    },
    handleNextStep() {
      if(!this.withdrawType) return;
      this.currentStep += 1;
    },
    previousStep() {
      this.currentStep -= 1;
    },
    chooseType(type) {
      this.withdrawType = type;
    },
    handleSelectNft(nftList) {
      this.withdrawParams.selectedNftIds = nftList;
    },
    isDisabledConfirm() {
      return !this.withdrawParams.selectedNftIds
        || this.withdrawParams.selectedNftIds <= 0
        || this.currentStep < 2;
    },
    async handleConfirm() {
      if(this.isDisabledConfirm()) return;
      this.isDepositing = true;
      try {
        if(this.withdrawType === 'trainer') {
          this.withdrawParams.nftContract = config.addresses.trainerContract;
          const params = {
            trainerIds: this.withdrawParams.selectedNftIds
          };
          const res = await rf.getRequest('UserRequest').getMyTrainersSignatureWithdraw(params);
          if (res) {
            console.log(res, 'sig====');
            this.withdrawParams.requestId =  res.requestId;
            this.withdrawParams.signature =  res.signature;
          }
        } else if (this.withdrawType === 'bunicorn') {
          this.withdrawParams.nftContract = config.addresses.bunicornContract;
          const params = {
            bunicornIds: this.withdrawParams.selectedNftIds
          };
          const res = await rf.getRequest('UserRequest').getMyBunicornsSignatureWithdraw(params);
          if (res) {
            this.withdrawParams.requestId =  res.requestId;
            this.withdrawParams.signature =  res.signature;
          }
        }
        const res = await withdrawNft(this.withdrawParams);
        if(res) {
          this.$dialog.notify.success(
            `You've withdrawn ${this.withdrawType} ID ${this.withdrawParams.selectedNftIds} successfully!`
          );
          this.closeModal();
          await sleep(1500);
          window.location.reload();
        }
      } catch(error) {
        if(isUserDenyTxMetaMask(error)){
          this.$dialog.notify.error('You\'ve rejected this transaction');
        } else {
          this.$dialog.notify.error(error);
        }
      }
      this.isDepositing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/config/_variables";

.title {
  font-size: 2.5rem;
  text-align: center;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px;
  font-size: 1.25rem;
  cursor: pointer;
}
.content-wrapper {
  display: flex;
  justify-content: center;
  .type-wrapper {
    margin: 40px 40px 0;
    text-align: center;
    cursor: pointer;
    &.selected {
      .type-img {
        box-shadow: 1px 1px 5px 0 #000;
        border-radius: 8px;
        transform: scale(1.05);
      }
    }
    .type-img {
      height: 235px;
      width: auto;
    }
    .type-name {
      margin-top: 20px;
    }
  }
}
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin: 20px 8px;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .title {
    font-size: 1.5rem;
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    .type-wrapper {
      margin: 10px 10px 0;
      .type-img {
        height: 210px;
        width: auto;
      }
    }
  }
}
</style>
