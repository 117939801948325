import rf from '@/requests/RequestFactory';
const state = {
  islands: null,
  buildings: null,
  buniHouses: null,
};
const getters = {};
const mutations = {
  setIslandData(state: any, payload: any) {
    state.islands = payload;
  },
  setBuildingData(state: any, payload: any) {
    state.buildings = payload;
  },
  setBuniHouseData(state: any, payload: any) {
    state.buniHouses = payload;
  }
};

const actions = {
  async getMyIslands({ commit }: { commit: any }) {
    const res = await rf.getRequest('UserRequest').getMyIslands();
    if (res) {
      commit('setIslandData', res);
    }
  },
  async getBuildingList({ commit }: { commit: any }) {
    const res = await rf.getRequest('IsLandRequest').getBuildingList();
    if (res) {
      commit('setBuildingData', res);
    }
  },
  async getMyBuniHouses({commit}: any){
    const res = await rf.getRequest('UserRequest').getMyBuniHouses();
    if (res) {
      commit('setBuniHouseData',res);
    }
  },
  getMetadata({ dispatch }: { dispatch: any }) {
    dispatch('getMyIslands');
    dispatch('getBuildingList');
    dispatch('getMyBuniHouses');
    dispatch('account/getUserProfile',null, { root: true });
  },
};
export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
