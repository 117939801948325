import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
// import IslandDetail from '@/views/IslandDetail.vue';
// import FarmDetail from '@/views/FarmDetail.vue';
// import TempleDetail from '@/views/TempleDetail.vue';
// import BuniHouseDetail from '@/views/BuniHouseDetail.vue';
import TrainerDetail from '@/views/TrainerDetail.vue';
import BunicornDetail from '@/views/BunicornDetail.vue';
// import Battle from '@/views/Battle.vue';
// import TeamDetail from '@/views/TeamDetail.vue';
// import BattleDetail from '@/views/BattleDetail.vue';
// import TrainingCenter from '@/views/TrainingCenter.vue';
// import Inventory from '@/views/Inventory.vue';
import PendingTransactions from '@/views/PendingTransactions.vue';
// import BreedingDetail from '@/views/BreedingDetail.vue';
// import HatcheryDetail from '@/views/HatcheryDetail.vue';
// import MentoringHouseDetail from '@/views/MentoringHouseDetail.vue';
// import StorageDetail from '@/views/StorageDetail.vue';
// import BattleLogDetail from '@/views/BattleLogDetail.vue';
// import FunctionalTeamDetail from '@/views/FunctionalTeamDetail.vue';

function createRouter() {
  const router = new VueRouter({
    mode: 'history',
    routes: [
      { path: '/', name: 'home', component: Home },
      { path: '/trainer/detail', name: 'trainer-detail', component: TrainerDetail},
      { path: '/bunicorn/detail', name: 'bunicorn-detail', component: BunicornDetail},
      { path: '/pending-transactions', name: 'pending-transactions', component: PendingTransactions },
    ],
  });

  return router;
}

export default createRouter;
