import BaseRequest from './BaseRequest';
import config from '@/config';

export default class BattleRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  battles(){
    const url = '/my/battles';
    return this.post(url, {});
  }
  createTeam(params: any){
    const url = '/my/teams';
    return this.post(url,params);
  }
  getMyTeam(){
    const url = '/my/teams';
    return this.get(url, {});
  }
  updateTeam(params: any){
    const url = '/my/teams/update';
    return this.post(url, params);
  }
  getEnemy(){
    const url = '/my/battles/enemy';
    return this.post(url, {});
  }
  skipEnemy(){
    const url = '/my/battles/skip-enemy';
    return this.post(url, {});
  }
  getBattleDetail(id: string){
    const url = `/battles/${id}`;
    return this.get(url);
  }
  getSkipEnemyFee(){
    const url = '/my/battles/skip-enemy';
    return this.get(url, {});
  }
}