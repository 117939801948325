<template>
  <b-modal
    hide-footer
    hide-header
    id="inventory-modal"
    size="md"
  >
    <div class="title">Inventory</div>
    <div class="close" @click="$emit('close-modal')">x</div>
    <div class="content-wrapper">
      <template v-for="(value, elementToken) in elementTokens">
        <div class="inventory-item" :key="elementToken.toLowerCase()">
          <img class="element" :src="`/images/${elementToken.toLowerCase()}.png`"/>
          <div class="mt-1">{{ value }}</div>
          <div class="item-name">{{elementToken.toLowerCase()}} stone</div>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'InventoryModal',
  computed: {
    ...mapState('account', ['profile']),
    elementTokens() {
      if (!this.profile) {
        return [];
      }
      return {
        fire: this.profile.fireStone,
        water: this.profile.waterStone,
        earth: this.profile.earthStone,
        air: this.profile.airStone,
      };
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/config/_variables';
.blank-state {
  height: 150px;
  text-align: center;
  margin: auto;
  font-size: 2rem;

}
.title {
  font-size: 2.5rem;
  text-align: center;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px;
  font-size: 1.25rem;
  cursor: pointer;
}
.content-wrapper {
  display: flex;
  justify-content: center;
  margin: 0 0;
  padding: 20px 0;
}
.inventory-item {
  padding: 20px 10px;
  width: 100px;
  text-align: center;
}
</style>
