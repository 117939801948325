<template>
  <b-modal
    id="select-buni-house-modal"
    size="lg"
    hide-footer
    hide-header
    centered
  >
    <div class="title">Move {{ name }}</div>
    <div class="close" @click="$emit('close-modal')">x</div>
    <div v-if="buniHouses.length > 0" class="buniHouses-wrapper">
      <div
        v-for="(buniHouse, index) in buniHouses"
        class="option"
        :class="selectedBuniHouseId === buniHouse.id ? 'selected' : ''"
        :key="index"
        @click="selectedBuniHouseId = buniHouse.id"
      >
        <div class="title">{{ buniHouse.name }}</div>
        <div class="subtitle">({{ getIslandName(buniHouse.islandId) }})</div>
        <div class="buniHouse-image">
          <img :src="buniHouse.imageUrl" />
        </div>
        <div>ID: {{ buniHouse.id }}</div>
      </div>
    </div>
    <div v-else class="blank-state">
      There is no buniHouse available to move.
    </div>
    <div class="btn-wrapper">
      <div
        class="custom-btn sketchy"
        :class="isDisable ? 'disable' : ''"
        @click="onMove"
      >
        Move
      </div>
    </div>
  </b-modal>
</template>
<script>
// import { ISLANDS } from '@/contants';
import { getBuniHouseSourceImage } from '@/utils/utils';

export default {
  props: ['moveNft', 'name', 'buniHouseIdOld', 'element'],
  data() {
    return {
      // islands: ISLANDS,
      selectedBuniHouseId: null,
    };
  },
  computed: {
    buniHouses() {
      const buniHouses = this.$store.state.metadata.buniHouses;
      const preFilterBunihouses = buniHouses.filter(bh => bh.id !== this.buniHouseIdOld);
      let filterdBuniHouses = preFilterBunihouses;

      if (preFilterBunihouses) {
        if (this.name.toLowerCase() === 'bunicorn') {
          filterdBuniHouses = buniHouses.filter((buniHouse) => {
            if (this.element) {
              return (
                buniHouse.totalBunicorn < buniHouse.bunicornLimitation &&
                this.element.toLowerCase() ===
                  buniHouse.element.toLowerCase() &&
                buniHouse.id !== this.buniHouseIdOld
              );
            }
            return (
              buniHouse.totalBunicorn < buniHouse.bunicornLimitation &&
              buniHouse.id !== this.buniHouseIdOld
            );
          });
        }
      }
      return filterdBuniHouses.map((buniHouse) => ({
        id: buniHouse.id,
        islandId: buniHouse.islandId,
        name: `${buniHouse.element} Buni House`,
        imageUrl: getBuniHouseSourceImage(buniHouse.element),
      }));
    },
    isDisable() {
      return !this.selectedBuniHouseId;
    },
  },
  methods: {
    async onMove() {
      this.$bvModal.hide('select-buni-house-modal');
      try {
        const res = await this.moveNft(this.selectedBuniHouseId);
        if (res) {
          this.$emit('onMove');
          this.$dialog.notify.success(`You've moved ${this.name} successfully`);
        }
      } catch (error) {
        this.$dialog.notify.error(error);
      }
    },
    getIslandName(islandID) {
      const allIsland = this.$store.state.metadata.islands;
      return allIsland
        .filter((island) => island.islandId === islandID)[0]
        .category.replace('_', ' ');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';
.title {
  font-size: 2.5rem;
  text-align: center;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 8px;
  font-size: 1.25rem;
  cursor: pointer;
}
.blank-state {
  height: 150px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buniHouses-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  margin: 30px 20px;
  padding: 20px 0;

  .option {
    border: 3px solid #000000;
    padding: 20px;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    &.selected {
      transform: scale(1.05);
      box-shadow: 1px 1px 5px 0 #000;
    }
    .buniHouse-image {
      width: 170px;
      height: 170px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .available-space {
      margin-top: 20px;
      font-size: 1.5rem;
    }
  }
}
.btn-wrapper {
  width: 100%;
  .custom-btn {
    margin: 20px auto;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
    &.disable {
      cursor: not-allowed;
      &:hover {
        background: #fff;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .title {
    font-size: 1.5rem;
  }
  .buniHouse-wrapper {
    margin: 20px 10px;
    .option {
      .buniHouse-image {
        width: 110px;
        height: 110px;
      }
    }
  }
}
</style>
