import Vue from 'vue';
import { Web3Provider } from '@ethersproject/providers';
import Storage from '@/utils/storage';
import config from '@/config';
import BigNumber from 'bignumber.js';
import lock, { getConnectorLogo, getConnectorName } from '@/utils/connectors';
import {verifySign, verifyTypedData, createSignature} from '@/utils/sign-utils';
import axios from 'axios';
import rf from '@/requests/RequestFactory';
const jwt = require('jsonwebtoken');

const state = {
  account: {
    accessToken: null,
    connector: null,
    address: '',
    chainId: 0,
    provider: null,
    wrongNetwork: false,
    isLoadingAccountAndNetwork: false,
  },
  accounts: [],
  profile: null,
};

const getters = {
  userProvider: async (state: any) => {
    if (
      state.account.connector &&
      state.account.connector.id &&
      !state.account.wrongNetwork
    ) {
      const connector = lock.getConnector(state.account.connector.id);
      const provider = await connector.connect();
      // @ts-ignore
      return new Web3Provider(provider);
    }
  },
  currentRewardsClaimTaxFactor(state: any) {
    return new BigNumber(state.rewardsClaimTax).dividedBy(
      new BigNumber(2).exponentiatedBy(64)
    );
  },
  totalWeiOffBuniBalance(state: any) {
    return new BigNumber(state.offChainAssets.buni || 0).toString();
  },
  totalWeiOnBuniBalance(state: any) {
    return new BigNumber(state.onChainAssets.buni || 0).toString();
  },
  totalWeiBurBalance(state: any, getter: any) {
    return new BigNumber(getter.totalWeiOffBurBalance).plus(getter.totalWeiOnBurBalance).toString();
  },
  totalWeiOffBurBalance(state: any) {
    return state.offChainAssets.bur || 0;
  },
  totalWeiOnBurBalance(state: any) {
    return new BigNumber(state.onChainAssets.bur || 0).plus(state.onChainAssets.burRewards || 0).toString();
  },
  userAddress: (state: any) => state.account.address,
  burInGame: (state: any) => state.account.profile.burInGame,
};

const mutations = {
  setAccessToken(state: any, data: any) {
    state.account.accessToken = data;
  },

  setOpenAccountModal(state: any, payload: any) {
    state.isShowAccountModal = payload;
  },
  setIsLoadingAccountAndNetwork(state: any, payload: any) {
    state.account.isLoadingAccountAndNetwork = payload;
  },
  setConnector(state: any, payload: any) {
    state.account.connector = payload;
  },
  setAddress: (state: any, payload: any): void => {
    state.account.address = payload;
  },
  setWrongNetwork: (state: any, payload: any): void => {
    state.account.wrongNetwork = payload;
  },
  setChainId: (state: any, payload: any): void => {
    state.account.chainId = payload;
  },
  setAccounts(state: any, payload: any) {
    state.accounts = payload.accounts;
  },
  clear(state: any): void {
    state.account.wrongNetwork = false;
  },
  setUserProfile(state: any, payload: any) {
    state.profile = payload;
  }
};

const actions = {
  async connect(
    { dispatch, commit }: any,
    connectorId: string
  ) {
    if (!connectorId) {
      console.warn('Empty connectorId');
      return;
    }
    const connector = lock.getConnector(connectorId);
    if (!connector) {
      console.warn('Empty connector');
      return;
    }


    let provider;
    try {
      provider = await connector.connect();
      commit('setConnector', {
        id: connectorId,
        name: getConnectorName(connectorId),
        logo: getConnectorLogo(connectorId)
      });
      //@ts-ignore
      const web3Provider = new Web3Provider(provider);
      const accounts = await web3Provider.listAccounts();
      console.log(accounts, 'accounts');
      if (accounts.length === 0) {
        dispatch('disconnect');
        return;
      }
      dispatch('saveProvider', provider);
      Storage.saveConnectorId(connectorId);
    } catch (e: any) {
      if (e.reason === 'missing provider') {
        Vue.prototype.$dialog.notify.error('Provider not found');
      }
      console.error('==== connecting wallet error=====', JSON.stringify(e));
    }
  },
  async saveProvider(
    { state, commit, dispatch }: { rootState: any, state: any; commit: any; dispatch: any },
    provider: any
  ) {
    if (provider.removeAllListeners) {
      provider.removeAllListeners();
    }
    if (provider && provider.on) {
      provider.on('chainChanged', async () => {
        commit('clear');
        console.log('chainChanged');
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
        dispatch('saveProvider', provider);
      });
      provider.on('accountsChanged', async () => {
        commit('clear');
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
        console.log('accountsChanged');
        dispatch('saveProvider', provider);
      });
      provider.on('disconnect', async () => {
        console.log('TRIGGER');
        dispatch('disconnect');
      });
    }
    // @ts-ignore
    const web3Provider = new Web3Provider(provider);
    const connectorId = state.account.connector
      ? state.account.connector.id
      : '';
    let networkChainId = 0;
    switch (connectorId) {
    case 'safepalwallet': {
      networkChainId = provider.chainId;
      break;
    }
    default: {
      const network = await web3Provider.getNetwork();
      networkChainId = network.chainId;
      break;
    }
    }

    commit('setChainId', networkChainId);
    const accounts = await web3Provider.listAccounts();
    if (accounts.length > 0) {
      commit('setAccounts', { accounts });
    }
    const account = accounts[0];
    commit('setAddress', account);
    if (
      new BigNumber(networkChainId).comparedTo(config.configWallet.chainId) !==
      0
    ) {
      commit('clear');
      commit('setWrongNetwork', true);
      const connector = lock.getConnector(
        state.account.connector?.id || 'metamask'
      );
      const isLoggedIn = connector.isLoggedIn();
      //@ts-ignore
      if (isLoggedIn) {
        await connector.logout();
      }
      Storage.saveConnectorId(null);
      return;
    }

    commit('setWrongNetwork', false);
    // await dispatch('raidboss/checkPurchasedTicket', { player: rootState.account.account.address, eventId: rootState.raidboss.eventId}, {root: true});
    Storage.saveConnectorId(connectorId);

    const auth = Storage.getAuth();

    console.log('===========auth', auth);
    if (auth && auth.address !== account) {
      Storage.removeAuth();
    }
    if (auth && auth.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
      commit('setAccessToken', auth.token);
    }
  },
  async disconnect({ commit }: { commit: any }) {
    console.log('==================DISCONNECT');
    const connectorId = Storage.getConnectorId();
    if (connectorId) {
      const connector = lock.getConnector(connectorId);
      let provider;
      try {
        // @ts-ignored
        provider = await connector.connect();
      } catch (e) {
        console.error('==== connecting wallet error=====', JSON.stringify(e));
      }
      //@ts-ignore
      if (provider && provider.removeAllListeners) {
        //@ts-ignore
        provider.removeAllListeners();
      }
      const isLoggedIn = connector.isLoggedIn();
      //@ts-ignore
      if (isLoggedIn) {
        await connector.logout();
      }
      Storage.saveConnectorId(null);
    }

    Storage.removeAuth();
    commit('setConnector', null);
    commit('setUserProfile', null);
    commit('setAddress', '');
    commit('setChainId', 0);
    commit('setWrongNetwork', false);
    commit('clear');
  },

  async generateToken({state, dispatch, commit}: any) {
    Storage.removeAuth();
    if (!state.account) {
      return;
    }
    // commit('setIsSigning', true);
    try {
      // @ts-ignore
      const signature = await createSignature(state.account.address);
      console.log('verify signature verifyTypedData', signature.signatureType, verifyTypedData(state.account.address, signature.signature));
      console.log('verify signature verifySign', signature.signatureType, verifySign(signature.signature));
      const date = new Date();
      const payload = {
        address: state.account.address,
        signature: signature.signature,
        signType: signature.signatureType,
        iat: date.getTime() / 1000,
        exp: date.getTime() / 1000 + config.token.daysExpire * 24 * 3600
      };
      const token = jwt.sign(payload, config.addresses.gameContract, { algorithm: 'HS256'});
      commit('setAccessToken', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      Storage.saveToken(token, state.account.address);
      window.location.reload();
      // await dispatch('setReferralIdIfNeed');
      // commit('setIsSigning', false);

    } catch (e) {
      // commit('setIsSigning', false);
      console.log('Sign error:', e.message);
      throw e;
    }
  },
  async getUserProfile({commit}: any) {
    const res = await rf.getRequest('UserRequest').getUserProfile();
    if (res) {
      commit('setUserProfile', res);
    }
  }
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state
};
