<template>
  <div class="content">
    <div class="title">Pending transaction</div>
    <div class="table-wrapper">
      <table>
        <tr>
          <th>Request ID</th>
          <th>Action Request</th>
          <th>Amount</th>
          <th>Action</th>
        </tr>
        <tr v-for="(transaction, index) in pendingTransactions" :key="index">
          <td>{{ transaction.requestId }}</td>
          <td>{{ actionMappings[transaction.method] || "N/A" }}</td>
          <td>
            {{ transaction.data[0] | formatWeiAmount }}
            {{ getAmountUnit(transaction.method) }}
          </td>
          <td>
            <div class="button-wrapper">
              <custom-button
                :isDisabled="isRetrying"
                @handleClick="handleRetry(transaction, index)"
                :isLoading="isRetrying && indexClicked === index"
              >
                Retry
              </custom-button>
              <!-- <custom-button>
                  Cancel
                </custom-button> -->
            </div>
          </td>
        </tr>
      </table>
    </div>
    <b-pagination
      class="pending-tx-pagination"
      v-model="pagination.page"
      :total-rows="pagination.totalItems"
      :per-page="pagination.perPage"
    />
  </div>
</template>

<script>
import rf from '@/requests/RequestFactory';
import CustomButton from '@/components/CustomButton.vue';
import config from '@/config';
import { moveToOnChain } from '@/utils/utils';
import { sleep, handleTransactionError } from '@/utils/helpers';

const actionMappings = {
  claimBurInGameBuniIslandWithSig: 'Withdraw Bur',
  claimBuniInGameBuniIslandWithSig: 'Withdraw Buni',
};

export default {
  data() {
    return {
      actionMappings,
      pendingTransactions: [],
      pagination: {
        page: 1,
        perPage: 10,
        totalItems: 0,
      },
      isRetrying: false,
      indexClicked: undefined,
    };
  },
  components: { CustomButton },
  computed: {
    myAccessToken() {
      return this.$store.state.account.account.accessToken;
    },
  },
  watch: {
    myAccessToken() {
      this.fetchPendingTransactions();
    },
    pagination: {
      deep: true,
      handler() {
        this.fetchPendingTransactions();
      },
    }
  },
  methods: {
    handleTransactionError,
    async fetchPendingTransactions() {
      try {
        const res = await rf
          .getRequest('UserRequestV2')
          .getSignatureRequests(
            'pending',
            this.pagination.page,
            this.pagination.perPage
          );
        if (res) {
          this.pendingTransactions = res.docs;
          this.pagination.totalItems = res.totalDocs;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getAmountUnit(method) {
      switch (method) {
      case 'claimBurInGameBuniIslandWithSig':
        return 'BUR';
      case 'claimBuniInGameBuniIslandWithSig':
        return 'BUNI';
      default:
        return 'N/A';
      }
    },
    async handleRetry(transaction, index) {
      if (transaction.method === 'claimBurInGameBuniIslandWithSig') {
        this.isRetrying = true;
        this.indexClicked = index;
        try {
          const moveToOnChainRes = await moveToOnChain(
            config.addresses.burTokenContract,
            transaction.method,
            transaction.data[0],
            transaction.requestId,
            transaction.signature
          );
          if (moveToOnChainRes) {
            await sleep(3000);
            await this.$store.dispatch('account/getUserProfile');
            await this.fetchPendingTransactions();
            this.$dialog.notify.success('You have withdrawn BUR successfully');
          }
        } catch (error) {
          const msg = this.handleTransactionError(error);
          this.$dialog.notify.error(msg);
        }
        this.isRetrying = false;
        this.indexClicked = undefined;
      } else if (transaction.method === 'claimBuniInGameBuniIslandWithSig') {
        this.isRetrying = true;
        this.indexClicked = index;
        try {
          const moveToOnChainRes = await moveToOnChain(
            config.addresses.buniTokenContract,
            transaction.method,
            transaction.data[0],
            transaction.requestId,
            transaction.signature
          );
          if (moveToOnChainRes) {
            await sleep(3000);
            await this.$store.dispatch('account/getUserProfile');
            await this.fetchPendingTransactions();
            this.$dialog.notify.success('You have withdrawn BUNI successfully');
          }
        } catch (error) {
          const msg = this.handleTransactionError(error);
          this.$dialog.notify.error(msg);
        }
        this.isRetrying = false;
        this.indexClicked = undefined;
      } else {
        return;
      }
    },
  },
  created() {
    if (this.myAccessToken) {
      this.fetchPendingTransactions();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/config/_variables';

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .title {
    font-size: 2.5rem;
  }
  .table-wrapper {
    overflow: auto;
    table,
    td,
    th {
      border: 2px solid;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    td,
    th {
      text-align: center;
      padding: 10px;
      .button-wrapper {
        display: flex;
        justify-content: center;
        div {
          margin: 0 10px;
        }
      }
    }
  }
  .pending-tx-pagination {
    margin-top: 20px;
    align-self: flex-end;
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  .content {
    display: block;
    .title {
      font-size: 2rem;
    }
  }
}
</style>
