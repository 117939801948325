import BaseRequest from './BaseRequest';
import config from '@/config';

export default class StatisticsBoardRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getMyStatistics(params: any) {
    const url = '/my/statistics';
    return this.get(url,params);
  }
  getOverviewToday() {
    const url =  '/public/dashboard/overview-today';
    return this.get(url,{});
  }
}
