import BaseRequest from './BaseRequest';
import config from '@/config';

export default class MentoringHouseRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  addTrainer(mentoringHouseId: number, params: any) {
    const url = `/my/mentoring-houses/${mentoringHouseId}/trainers`;
    return this.put(url, params);
  }
  getMentoringHouse(mentoringHouseId: number) {
    const url = `/my/mentoring-houses/${mentoringHouseId}`;
    return this.get(url, {});
  }
  getSpeedUpFee(mentoringHouseId: number) {
    const url = `/my/mentoring-houses/${mentoringHouseId}/speedup`;
    return this.get(url, {});
  }
  speedUp(mentoringHouseId: number) {
    const url = `/my/mentoring-houses/${mentoringHouseId}/speedup`;
    return this.put(url, {});
  }
  claimTrainer(mentoringHouseId: number) {
    const url = `/my/mentoring-houses/${mentoringHouseId}/claim-trainer`;
    return this.post(url, {});
  }
  moveMentoringHouse(mentoringHouseId: number, params: any) {
    const url = `/my/mentoring-houses/${mentoringHouseId}/move-building`;
    return this.patch(url, params);
  }
  getTrainerCanDoMentoringSession(params: any) {
    const url = '/my/trainers/mentoring-available';
    return this.get(url, params);
  }
  getMentoringFee(trainerId: number){
    const url = `/public/mentoring-houses/mentoring/${trainerId}`;
    return this.get(url, {});
  }
}
