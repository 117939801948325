import BaseRequest from './BaseRequest';
import config from '@/config';

export default class TempleRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getDetail(templeId: number) {
    const url = `/my/temples/${templeId}`;
    return this.get(url, {});
  }
  getFeeLevelUpTemple(templeId: number){
    const url = `/my/temples/${templeId}/levelup`;
    return this.get(url,{});
  }
  upgrade(templeId: number){
    const url = `/my/temples/${templeId}/levelup`;
    return this.patch(url,{});
  }
  move(templeId: number,params: any){
    const url =  `/my/temples/${templeId}/move-building`;
    return this.patch(url,params);
  }
  getSpeedUpCostTemple(templeId: number) {
    const url = `/my/temples/${templeId}/speedup`;
    return this.get(url, {});
  }
  speedUp(templeId: number) {
    const url = `/my/temples/${templeId}/speedup`;
    return this.patch(url,{});
  }
  getLevelUp(templeId: number) {
    const url = `/my/temples/${templeId}/levelup`;
    return this.get(url, {});
  }
}
