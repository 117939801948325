export const ISLANDS = [
  {
    id: 1,
    name: 'Lava Island',
    imageUrl: '/images/island.jpeg',
    description: `Lorem ipsum dolor sit amet, consectetur
      adipiscing elit, sed do eiusmod tempor incididunt ut 
      labore et dolore magna aliqua. Ut enim ad minim veniam.`,
    lockedSpace: 665,
    expandableSpace: 450,
    availableSpace: 450,
    farms: [
      {
        id: 12323,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12324,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12325,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12326,
        imageUrl: '/images/farm.jpeg',
      },
    ],
    buniHouses: [
      {
        id: 98341,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98342,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98343,
        imageUrl: '/images/buni-house.png',
      },
    ],
  },
  {
    id: 2,
    name: 'Main Island',
    imageUrl: '/images/main-island.jpeg',
    description: `Lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor incididunt ut 
    labore et dolore magna aliqua. Ut enim ad minim veniam.`,
    lockedSpace: 665,
    expandableSpace: 250,
    availableSpace: 350,
    farms: [
      {
        id: 12323,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12324,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12325,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12326,
        imageUrl: '/images/farm.jpeg',
      },
    ],
    buniHouses: [
      {
        id: 98341,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98342,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98343,
        imageUrl: '/images/buni-house.png',
      },
    ],
  },
  {
    id: 3,
    name: 'Ice Island',
    imageUrl: '/images/island.jpeg',
    description: `Lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor incididunt ut 
    labore et dolore magna aliqua. Ut enim ad minim veniam.`,
    lockedSpace: 165,
    expandableSpace: 450,
    availableSpace: 550,
    farms: [
      {
        id: 12323,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12324,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12325,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12326,
        imageUrl: '/images/farm.jpeg',
      },
    ],
    buniHouses: [
      {
        id: 98341,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98342,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98343,
        imageUrl: '/images/buni-house.png',
      },
    ],
  },
  {
    id: 4,
    name: 'Water Island',
    imageUrl: '/images/island.jpeg',
    description: `Lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor incididunt ut 
    labore et dolore magna aliqua. Ut enim ad minim veniam.`,
    lockedSpace: 365,
    expandableSpace: 650,
    availableSpace: 250,
    farms: [
      {
        id: 12323,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12324,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12325,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12326,
        imageUrl: '/images/farm.jpeg',
      },
    ],
    buniHouses: [
      {
        id: 98341,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98342,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98343,
        imageUrl: '/images/buni-house.png',
      },
    ],
  },
  {
    id: 5,
    name: 'Desert Island',
    imageUrl: '/images/island.jpeg',
    description: `Lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor incididunt ut 
    labore et dolore magna aliqua. Ut enim ad minim veniam.`,
    lockedSpace: 635,
    expandableSpace: 430,
    availableSpace: 460,
    farms: [
      {
        id: 12323,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12324,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12325,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12326,
        imageUrl: '/images/farm.jpeg',
      },
    ],
    buniHouses: [
      {
        id: 98341,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98342,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98343,
        imageUrl: '/images/buni-house.png',
      },
    ],
  },
  {
    id: 6,
    name: 'Krypton Island',
    imageUrl: '/images/island.jpeg',
    description: `Lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor incididunt ut 
    labore et dolore magna aliqua. Ut enim ad minim veniam.`,
    lockedSpace: 615,
    expandableSpace: 250,
    availableSpace: 440,
    farms: [
      {
        id: 12323,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12324,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12325,
        imageUrl: '/images/farm.jpeg',
      },
      {
        id: 12326,
        imageUrl: '/images/farm.jpeg',
      },
    ],
    buniHouses: [
      {
        id: 98341,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98342,
        imageUrl: '/images/buni-house.png',
      },
      {
        id: 98343,
        imageUrl: '/images/buni-house.png',
      },
    ],
  },
];
export const OBSTACLES = [
  {
    name: 'Tree',
    imageUrl: '/images/tree.png',
    price: 100,
    status: 'available',
    remainingTime: '0',
  },
  {
    name: 'Big Rock',
    imageUrl: '/images/rock.png',
    price: 100,
    status: 'available',
    remainingTime: '109100',
  },
  {
    name: 'Big Rock',
    imageUrl: '/images/rock.png',
    price: 100,
    status: 'processing',
    remainingTime: '109100',
  },
  {
    name: 'Tree',
    imageUrl: '/images/tree.png',
    price: 100,
    status: 'available',
    remainingTime: '0',
  },
];
export const FARM_DATA = {
  name: 'Buni Farm',
  imageUrl: '/images/farm.jpeg',
  level: 2,
  description: 'The huge farm will produce a lot of food for your bunicorns',
  foods: [
    {
      name: 'Vegetable',
      time: '30',
      collect: 5,
      price: 50,
      xp: 25,
      imageUrl: '/images/food-icon.png',
    },
    {
      name: 'Vegetable',
      time: '300',
      collect: 25,
      price: 250,
      xp: 25,
      imageUrl: '/images/food-icon.png',
    },
    {
      name: 'Vegetable',
      time: '1800',
      collect: 75,
      price: 100,
      xp: 1000,
      imageUrl: '/images/food-icon.png',
    },
    {
      name: 'Vegetable',
      time: '7200',
      collect: 2005,
      price: 5000,
      xp: 5000,
      imageUrl: '/images/food-icon.png',
    },
    {
      name: 'Vegetable',
      time: '21600',
      collect: 10000,
      price: 25000,
      xp: 10000,
      imageUrl: '/images/food-icon.png',
    },
  ],
};
export const BUNI_HOUSE_DATA = {
  name: 'Water Buni House',
  imageUrl: '/images/buni-house.png',
  level: 2,
  type: 'Water',
  maxBunicorn: 4,
  maxGold: 30000,
  space: 16,
  description:
    'Bring tranquility to your island with this beautiful Water Buni House. It\'s just the perfect place for your water bunicorn to splash around',
  bunicorns: [
    {
      id: 24343456,
      level: 10,
      imageUrl:
        'https://static.nft.bunicorn.exchange/bunicorns/Water_Seabu_1_v2.1_450x600.png',
    },
    {
      id: 24343457,
      level: 20,
      imageUrl:
        'https://static.nft.bunicorn.exchange/bunicorns/Water_Seasabu_2_v2.1_450x600.png',
    },
    {
      id: 24343458,
      level: 30,
      imageUrl:
        'https://static.nft.bunicorn.exchange/bunicorns/Water_Oceabu_3_v2.1_450x600.png',
    },
    {
      id: 24343496,
      level: 45,
      imageUrl:
        'https://static.nft.bunicorn.exchange/bunicorns/Water_Abysbu_5_v2.1_450x600.png',
    },
  ],
  trainers: [
    {
      id: 24343456,
      level: 10,
      imageUrl:
        'https://static.nft.bunicorn.exchange/trainers/Black_robot_water_v2_450x600.png',
    },
    {
      id: 24343457,
      level: 20,
      imageUrl:
        'https://static.nft.bunicorn.exchange/trainers/Female_trainer_02_water_v2_450x600.png',
    },
    {
      id: 24343458,
      level: 30,
      imageUrl:
        'https://static.nft.bunicorn.exchange/trainers/Female_trainer_04_water_v2_450x600.png',
    },
  ],
};
export const TRAINER_DATA = {
  name: 'William Wilson',
  level: 30,
  element: 'Water',
  xp: 1000,
  claimableXp: 3000,
  baseLine: 0.3,
  imageUrl:
    'https://static.nft.bunicorn.exchange/trainers/Male_trainer_01_water_v2_450x600.png',
};
export const BUNICORN_DATA = {
  name: 'Oceabu',
  level: 30,
  element: 'Water',
  imageUrl: 'https://static.nft.bunicorn.exchange/bunicorns/Water_Oceabu_3_v2.1_450x600.png',
  stars: 3,
  hp: {
    max: 1000,
    current: 1000,
  },
  maxHp: 1000,
  currentHp: 1000,
  damage: {
    max: 2000,
    current: 3000,
  },
  winCount: 50,
  power: 2,
  goldRate: 200,
  nextCountdown: 3000,
  nextGoldRate: 10,
  skills: [
    {
      name: 'Punch',
      damage: 338,
      requireLevel: null,
      imageUrl: '/images/storm.png'
    },
    {
      name: 'Tsunami',
      damage: 550,
      requireLevel: null,
      imageUrl: '/images/storm.png'
    },
    {
      name: 'Flooding',
      damage: 650,
      requireLevel: 10,
      imageUrl: '/images/storm.png',
    },
    {
      name: 'Storm',
      damage: 1050,
      requireLevel: 10,
      imageUrl: '/images/storm.png',
    },
  ]
};

export const EGG_META_DATA = {
  fire: [
    '/images/eggs/fire_egg_lvl_1_2.png',
    '/images/eggs/fire_egg_lvl_3_4.png',
    '/images/eggs/fire_egg_lvl_5_6.png',
  ],
  earth: [
    '/images/eggs/earth_egg_lvl_1_2.png',
    '/images/eggs/earth_egg_lvl_3_4.png',
    '/images/eggs/earth_egg_lvl_5_6.png',
  ],
  air: [
    '/images/eggs/air_egg_lvl_1_2.png',
    '/images/eggs/air_egg_lvl_3_4.png',
    '/images/eggs/air_egg_lvl_5_6.png',
  ],
  water: [
    '/images/eggs/water_egg_lvl_1_2.png',
    '/images/eggs/water_egg_lvl_3_4.png',
    '/images/eggs/water_egg_lvl_5_6.png',
  ]
};