import BaseRequest from './BaseRequest';
import config from '@/config';

export default class BreedingHouseRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getBreedingFee(params: any) {
    const url = '/public/breeding-house/breeding-fee';
    return this.get(url, params);
  }
  getMyBreedingHouse(breadingHouseId: number) {
    const url = `/my/breeding-houses/${breadingHouseId}`;
    return this.get(url,{});
  }
  breed(breedingHouseId: number, bunicornIds: Array<number>) {
    const url = `/my/breeding-houses/${breedingHouseId}/breeding`;
    return this.put(url, {
      bunicornIds,
    });
  }
  getCostToSkipBreedingHouse(breedingHouseId: number){
    const url = `/my/breeding-houses/${breedingHouseId}/speedup`;
    return this.get(url,{});
  }
  speedUp(breedingHouseId: number) {
    const url = `/my/breeding-houses/${breedingHouseId}/speedup`;
    return this.put(url, {});
  }
  claimEgg(breedingHouseId: number) {
    const url = `/my/breeding-houses/${breedingHouseId}/claim-egg`;
    return this.put(url, {});
  }
  moveBreedingHouse(breedingHouseId: number, params: any) {
    const url = `/my/breeding-houses/${breedingHouseId}/move-building`;
    return this.patch(url, params);
  }
  getBunicornsCanBreed(params: any) {
    const url = '/my/bunicorns/breeding-available';
    return this.get(url, params);
  }
  getEgg(id: number) {
    const url = `/my/eggs/${id}`;
    return this.get(url, {});
  }
}
