<template>
  <div class="home-container">
    <!-- <div class="left-container" v-if="myAccount && accessToken">
      <template v-if="!isMobileScreen">
        <div class="menu-item" @click="$router.push({ name: 'inventory' })">
          Inventory
        </div>
        <div
          v-if="$route.path !== '/battle'"
          class="menu-item"
          key="battle-item"
          @click="$router.push({ name: 'battle' })"
        >
          Battle
        </div>
        <div class="menu-item" @click="$bvModal.show('deposit-nft-modal')">
          Deposit NFT
        </div>
        <div class="menu-item" @click="markView">
          Steal Gold <template v-if="stolenGoldInfo && stolenGoldInfo.totalGoldStolen > 0">&#9888; </template>
        </div>
      </template>
      <template v-else>
        <div class="menu-item" @click="leftSidebar = !leftSidebar">
          <b-icon icon="caret-right"></b-icon>
        </div>
      </template>
    </div> -->
    <div class="general-info-wrapper">
      <table>
        <tr>
          <th>Pool Reward</th>
          <th>Next Distribution Reward</th>
          <th>Daily Collected</th>
          <th>My Daily Collected</th>
          <th>My Reward Earned Today</th>
        </tr>
        <tr>
          <td>
            <div>{{ overviewToday.burPoolReward | formatWeiAmount }} BUR</div>
            <div>{{ overviewToday.buniPoolReward | formatWeiAmount }} BUNI</div>
          </td>
          <td>
            <div>{{ overviewToday.newDailyBurPoolReward | formatWeiAmount }} BUR</div>
            <div>{{ overviewToday.newDailyBuniPoolReward | formatWeiAmount }} BUNI</div>
          </td>
          <td>
            <div>{{ overviewToday.dailyCollectedGold | formatNumber }} GOLD</div>
          </td>
          <td>
            <div>{{ myStatistics.goldEarned | formatNumber }} GOLD</div>
          </td>
          <td>
            <div>{{ convertWeiToDec(myStatistics.burEarned) | formatNumber }} BUR</div>
            <div>{{ convertWeiToDec(myStatistics.buniEarned) | formatNumber }} BUNI</div>
          </td>
        </tr>
      </table>
    </div>
    <!-- <div class="right-container" v-if="myAccount && accessToken">
      <template v-if="overviewToday && !isMobileScreen">
        <div class="menu-item" key="pool-reward-item">
          Pool Reward

          <p>{{ overviewToday.burPoolReward | formatWeiAmount }} BUR</p>
          <p>{{ overviewToday.buniPoolReward | formatWeiAmount }} BUNI</p>
        </div>
        <div class="menu-item" key="daily-pool-reward-item">
          Next Distribution Reward
          <p>{{ overviewToday.newDailyBurPoolReward | formatWeiAmount }} BUR</p>
          <p>{{ overviewToday.newDailyBuniPoolReward | formatWeiAmount }} BUNI</p>
        </div>
        <div class="menu-item" key="daily-collected-gold-item">
          Daily Collected

          <p>{{ overviewToday.dailyCollectedGold | formatNumber }} GOLD</p>
        </div>
        <div
          v-if="myStatistics"
          class="menu-item"
          key="my-daily-collected-gold-item"
        >
          My Daily <br />Collected

          <p>{{ myStatistics.goldEarned | formatNumber }} GOLD</p>
        </div>
        <div
          v-if="myStatistics"
          class="menu-item"
          key="my-daily-bur-earned-item"
        >
          My Reward Earned <br />Today

          <p>
            {{ convertWeiToDec(myStatistics.burEarned) | formatNumber }} BUR
          </p>
          <p>
            {{ convertWeiToDec(myStatistics.buniEarned) | formatNumber }} BUNI
          </p>
        </div>
      </template>
      <template v-else>
        <div class="menu-item" @click="rightSidebar = !rightSidebar">
          <b-icon icon="caret-left"></b-icon>
        </div>
      </template>
    </div> -->
    <!-- <div class="islands-container">
      <div
        @click="goToDetail(island.islandId)"
        class="island"
        v-for="island in islands"
        :key="island.islandId"
      >
        <img :src="getIslandSourceImage(island.category)" />
        <div class="island-name">{{ formatIslandName(island.category) }}</div>
      </div>
    </div> -->
    <Inventory v-if="myAccount && accessToken"/>
    <b-sidebar
      id="right-sidebar"
      v-if="overviewToday"
      v-model="rightSidebar"
      right
      no-header
    >
      <div class="close-sidebar" @click="rightSidebar = !rightSidebar">X</div>
      <div class="menu-item" key="pool-reward-item">
        Pool Reward

        <p>{{ overviewToday.burPoolReward | formatWeiAmount }} BUR</p>
        <p>{{ overviewToday.buniPoolReward | formatWeiAmount }} BUNI</p>
      </div>
      <div class="menu-item" key="daily-pool-reward-item">
        Daily Pool Reward

        <p>{{ overviewToday.newDailyBurPoolReward | formatWeiAmount }} BUR</p>
        <p>{{ overviewToday.newDailyBuniPoolReward | formatWeiAmount }} BUNI</p>
      </div>
      <div class="menu-item" key="daily-collected-gold-item">
        Daily Collected Gold

        <p>{{ overviewToday.dailyCollectedGold | formatNumber }} GOLD</p>
      </div>
      <div class="menu-item" key="my-daily-collected-gold-item">
        My Daily <br />Collected Gold

        <p>{{ (myStatistics.goldEarned || 0) | formatNumber }} GOLD</p>
      </div>
      <div class="menu-item" key="my-daily-bur-earned-item">
        My BUR Earned <br />Today

        <p>
          {{
            (convertWeiToDec(myStatistics.burEarned) || 0) | formatNumber
          }}
          BUR
        </p>
        <p>
          {{
            (convertWeiToDec(myStatistics.buniEarned) || 0) | formatNumber
          }}
          BUNI
        </p>
      </div>
    </b-sidebar>
    <b-sidebar v-model="leftSidebar" id="left-sidebar" no-header>
      <div class="close-sidebar" @click="leftSidebar = !leftSidebar">X</div>
      <div class="menu-item" @click="$router.push({ name: 'inventory' })">
        Inventory
      </div>
      <div
        v-if="$route.path !== '/battle'"
        class="menu-item"
        key="battle-item"
        @click="$router.push({ name: 'battle' })"
      >
        Battle
      </div>
      <div class="menu-item" @click="$bvModal.show('deposit-nft-modal')">
        Deposit NFT
      </div>
      <div class="menu-item" @click="markView">
        Steal Gold <template v-if="stolenGoldInfo && stolenGoldInfo.totalGoldStolen > 0">&#9888; </template>
      </div>
    </b-sidebar>
    <DepositNftModal />
    <!-- <stolen-gold-notification :stolenGoldInfo="stolenGoldInfo" @close-modal="$bvModal.hide('stolen-gold-notification-modal')"/> -->
  </div>
</template>

<script>
import rf from '@/requests/RequestFactory';
import { getIslandSourceImage } from '@/utils/utils';
import _ from 'lodash';
import { mapState } from 'vuex';
import moment from 'moment';
import { convertWeiToDec } from '@/utils/helpers';
import DepositNftModal from '@/components/DepositNftModal/DepositNftModal.vue';
// import StolenGoldNotification from '@/components/StolenGoldNotification.vue';
import Inventory from '@/views/Inventory.vue';

export default {
  data() {
    return {
      islands: [],
      overviewToday: null,
      myStatistics: {},
      windowWidth: window.innerWidth,
      resizeEvent: null,
      rightSidebar: false,
      leftSidebar: false,
      stolenGoldInfo: null,
    };
  },
  components: {
    DepositNftModal,
    // StolenGoldNotification,
    Inventory
  },
  computed: {
    ...mapState('account', ['profile']),
    myAccount() {
      return this.$store.state.account.account.address;
    },
    accessToken() {
      return this.$store.state.account.account.accessToken;
    },
    isMobileScreen() {
      if (this.windowWidth > 768) return false;
      return true;
    },
  },
  watch: {
    accessToken() {
      // this.fetchMyIslands();
      this.getMyStatistics();
      // this.init();
    },
  },
  methods: {
    convertWeiToDec,
    formatIslandName(category) {
      return _.upperCase(category);
    },
    getIslandSourceImage,
    async markView(){
      try {
        await rf.getRequest('StorageRequest').markSeenNotifications();
      } catch (error) {
        console.log(error);
      }
      this.$router.push('/storage-detail');
    },
    async getStolenGoldInfo(){
      const res = await rf.getRequest('StorageRequest').getStorageNotification();
      if (res) {
        this.stolenGoldInfo = res;
        if (res.totalGoldStolen > 0) {
          this.$bvModal.show('stolen-gold-notification-modal');
        }
      }
    },
    goToDetail(islandId) {
      this.$router.push({
        name: 'island-detail',
        params: {
          id: islandId,
        },
      });
    },
    async fetchMyIslands() {
      const res = await rf.getRequest('IsLandRequest').getMyList();
      const groupedRes = _.groupBy(res, 'category');
      this.islands = [
        _.first(groupedRes['bane_island']),
        _.first(groupedRes['main_island']),
        _.first(groupedRes['lala_island']),
        _.first(groupedRes['kala_island']),
        _.first(groupedRes['keone_island']),
        _.first(groupedRes['moana_island']),
      ];
    },
    async getOverviewToday() {
      try {
        const res = await rf
          .getRequest('StatisticsBoardRequest')
          .getOverviewToday();
        if (res) {
          this.overviewToday = res;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getMyStatistics() {
      try {
        const params = {
          page: 1,
          limit: 1,
          fromDate: moment().utc().format('YYYY-MM-DD'),
          toDate: moment().utc().format('YYYY-MM-DD'),
        };
        const res = await rf
          .getRequest('StatisticsBoardRequest')
          .getMyStatistics(params);
        if (res) {
          this.myStatistics = res.docs[0] || {};
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.resizeEvent = window.addEventListener('resizeWindow', () => {
      this.windowWidth = window.innerWidth;
    });
    this.getOverviewToday();
    if (this.accessToken) {
      // this.fetchMyIslands();
      this.getMyStatistics();
      this.getStolenGoldInfo();
    }
  },
};
</script>
<style lang="scss" scoped></style>

<style scoped lang="scss">
@import "@/config/_variables";

.home-container {
  position: relative;
  .general-info-wrapper {
    overflow: auto;
    padding: 0 0.5rem;
    margin-bottom: 16px;
    table, td, th {
      border: 1px solid black;
    }

    td, th {
      padding: 10px 5px;
      text-align: center;
      min-width: 100px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }
  }
  .islands-container {
    display: flex;
    flex-wrap: wrap;
    width: 1024px;
    margin: auto;
    .island {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition: 1s ease;
      }
      img {
        width: 50%;
        margin: 15px auto;
        object-fit: contain;
      }
      .island-name {
        font-size: 1.25rem;
      }
      &:nth-child(2) {
        img {
          width: 100%;
        }
      }
      &:nth-child(2),
      &:nth-child(5) {
        margin-top: 100px;
      }
      &:nth-child(4),
      &:nth-child(6) {
        margin-top: 0px;
      }
    }
  }
  .right-container {
    position: fixed;
    top: 200px;
    right: 20px;
    width: 130px;

    border: 1px solid #333333;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    word-spacing: 0.3ch;
    background: #ffffff;
    .menu-item {
      padding: 10px 5px;
      p {
        margin-bottom: 0px;
      }
    }
  }
  .left-container {
    position: fixed;
    top: 200px;
    left: 20px;
    width: 85px;

    border: 1px solid #333333;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    word-spacing: 0.3ch;
    background: #ffffff;
  }
}
@media screen and (max-width: $breakpoint-tablet) {
  .home-container {
    .islands-container {
      display: flex;
      flex-wrap: wrap;
      width: 95vw;
      margin: auto;
      .island {
        width: 50%;
        img {
          width: 70%;
        }
        &:nth-child(2),
        &:nth-child(5) {
          margin-top: 0px;
        }
        &:nth-child(4),
        &:nth-child(6) {
          margin-top: 0px;
        }
      }
    }
    .right-container {
      right: 0;
      height: fit-content;
      width: fit-content;
    }
    .left-container {
      left: 0;
      height: fit-content;
      width: fit-content;
      .menu-item {
        padding: 10px 5px;
      }
    }
  }
}

.menu-item {
  padding: 20px 5px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    border-bottom: 2px solid #333333;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
      border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
    }
  }
}
::v-deep #right-sidebar {
  background-color: transparent !important;
  .close-sidebar {
    position: absolute;
    right: 0;
    top: -20px;
    padding: 5px 10px;
    background: #ffffff;
    border: 1px solid #333333;
    z-index: 1040;
  }
  .b-sidebar-body {
    position: fixed;
    overflow: visible;
    top: 200px;
    right: 0;
    width: 130px;
    height: fit-content;
    border: 1px solid #333333;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    word-spacing: 0.3ch;
    background: #ffffff;
    .menu-item {
      padding: 10px 5px;
      p {
        margin-bottom: 0px;
      }
    }
  }
}
::v-deep #left-sidebar {
  background-color: transparent !important;
  .close-sidebar {
    position: absolute;
    left: 0;
    top: -20px;
    padding: 5px 10px;
    background: #ffffff;
    border: 1px solid #333333;
    z-index: 1040;
  }
  .b-sidebar-body {
    position: fixed;
    overflow: visible;
    top: 200px;
    left: 0;
    width: 130px;
    height: fit-content;
    border: 1px solid #333333;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    word-spacing: 0.3ch;
    background: #ffffff;
    .menu-item {
      text-align: right;
      padding: 10px 5px;
      p {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
