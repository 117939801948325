<template>
  <div>
    <div class="search-filter">
      <div class="stars-filter">
        <div
          v-for="starNumber in [1, 2, 3, 4, 5]"
          :key="starNumber"
          @click="filterStar(starNumber)"
          @mouseover="hoverStarFilter = starNumber"
          @mouseout="hoverStarFilter = 0"
          :class="
            hoverStarFilter <= starFilter &&
            starFilter > starNumber - 1 &&
            (hoverStarFilter > starNumber - 1 || hoverStarFilter == 0)
              ? 'active-star'
              : hoverStarFilter > starNumber - 1 &&
                hoverStarFilter >= starFilter
              ? 'active-star'
              : ''
          "
        />
      </div>
      <div class="clear-filter sketchy" @click="clearFilter">Clear filter</div>
    </div>
    <div class="content-wrapper">
      <template v-if="bunicorns.length > 0">
      <div
        class="bunicorn-card"
        :class="selectedBunicorns.includes(bunicorn.bunicornId) ? 'active' : ''"
        v-for="bunicorn in bunicorns"
        :key="bunicorn.bunicornId"
        @click="selectBunicorn(bunicorn.bunicornId)"
      >
        <img :src="bunicorn.metaData.medium_image" />
        <div class="info-wrapper">
          <div class="id">ID:{{ bunicorn.bunicornId }}</div>
          <div class="name">{{ bunicorn.metaData.name}}</div>
        </div>
      </div>
      </template>
      <template v-else >
        <div class="blank-state">
        There is no Bunicorn matched this filter.
        </div>
      </template>
    </div>
    <div class="pagination-wrapper">
      <b-pagination
        v-model="params.page"
        :total-rows="totalItem"
        :per-page="params.limit"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import rf from '@/requests/RequestFactory';
import { cleanObject } from '@/utils/helpers';

export default {
  data() {
    return {
      bunicorns: [],
      totalItem: 0,
      hoverStarFilter: 0,
      selectedBunicorns: [],
      starFilter: '',
      params: {
        limit: 4,
        page: 1,
        element: '',
        sortById: 1,
        sortByStar: 1,
        inBuniHouse: false,
        isNft: true
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    openModal() {
      if (this.openModal) {
        this.selectedBunicorns = [];
      }
    },
    starFilter(){
      this.params = {
        ...this.params,
        starsFrom: this.starFilter,
        starsTo: this.starsTo,
      };
    },
    params: {
      deep: true,
      handler(){
        this.fetchData();
      }
    }
  },
  computed: {
    isDisable() {
      return this.selectedBunicorns.length === 0;
    },
  },
  methods: {
    cleanObject,
    clearFilter(){
      this.params = {
        limit: 4,
        page: 1,
        sortById: 1,
        sortByStar: 1,
        inBuniHouse: false
      };
      this.starFilter = '';
    },
    selectBunicorn(bunicornId) {
      if (this.selectedBunicorns.includes(bunicornId)) {
        this.selectedBunicorns = this.selectedBunicorns.filter(
          (id) => id !== bunicornId
        );
      } else {
        this.selectedBunicorns.push(bunicornId);
      }
      this.$emit('handleSelectNft', this.selectedBunicorns);
    },
    filterStar(starNumber) {
      this.starFilter = starNumber;
    },
    async fetchData() {
      const params = {
        ...this.params,
        starsFrom:this.starFilter ?  Number(this.starFilter) - 1 : '',
        starsTo: this.starFilter ? Number(this.starFilter) - 1 : '',
        isNft: true,
      };
      const res = await rf.getRequest('UserRequest').getMyBunicornInGameV2(this.cleanObject(params));
      if (res) {
        this.bunicorns = res.docs;
        this.totalItem = res.totalDocs;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';
.blank-state {
  height: 150px;
  text-align: center;
  margin: auto;
  font-size: 2rem;

}
.search-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px;
  .stars-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    & > div {
      margin: 0 3px;
      background-image: url('../../assets/icon/star.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: 0.5s ease;
      width: 22px;
      height: 22px;
    }
    & > div:hover,
    .active-star {
      background-image: url('../../assets/icon/rate-star.png') !important;
    }
    & > img {
      margin: 0 1px;
      opacity: 0.4;
      cursor: pointer;
      transition: 0.5s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
  .clear-filter {
    padding: 8px;
    width: 120px;
    text-transform: uppercase;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    &:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
  }
}
.content-wrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  padding: 20px 0;

  .bunicorn-card {
    width: 20%;
    min-width: 210px;
    margin: 0 10px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 5px 0 #000;
      transform: scale(1.05);
    }
    &.active {
      box-shadow: 1px 1px 5px 0 #000;
      transform: scale(1.05);
    }
    img {
      width: 100%;
      height: auto;
      margin: 0 auto 20px auto;
      object-fit: contain;
    }
    .info-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: $breakpoint-desktop) {
  .content-wrapper {
    overflow-x: auto;
    justify-content: flex-start;
  }
  .search-filter {
    flex-wrap: wrap;
    .level-filter,
    .element-filter,
    .clear-filter {
      margin: 8px;
    }
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .search-filter {
    flex-wrap: wrap;
    .level-filter,
    .element-filter {
      width: 100%;
    }
    .element-filter {
      justify-content: center;
    }
    .clear-filter {
      margin: 8px auto;
    }
  }
}
</style>

