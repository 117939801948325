import BaseRequest from './BaseRequest';
import config from '@/config';
import Vue from 'vue';
import axios from 'axios';
export default class FarmRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getDetail(farmId: number) {
    const url = `/my/farms/${farmId}`;
    return this.get(url, {});
  }
  move(farmId: number, params: any){
    const url = `/my/farms/${farmId}/move-building`;
    return this.patch(url,params);
  }
  upgrade(farmId: number) {
    const url = `/my/farms/${farmId}/levelup`;
    return this.patch(url, {});
  }
  sell(farmId: number) {
    const url = `/my/farms/${farmId}/sell`;
    return this.del(url, {});
  }
  async harvest(farmId: number) {
    if (Vue.prototype.$recaptchaLoaded) {
      await Vue.prototype.$recaptchaLoaded();
    }
    const recaptcha = await Vue.prototype.$recaptcha('recaptcha');
    axios.defaults.headers.common['recaptchaV3'] = recaptcha;
    const url = `/my/farms/${farmId}/harvest`;
    return this.post(url, {});
  }
  getSpeedupCost(farmId: number) {
    const url = `/my/farms/${farmId}/speedup`;
    return this.get(url, {});
  }
  speedUp(farmId: number) {
    const url = `/my/farms/${farmId}/speedup`;
    return this.patch(url, {});
  }
  getAllCrops(farmId: number){
    const url = `/public/farms/crops?farmLevel=${farmId}`;
    return this.get(url);
  }
  growCrops(farmId: number, params: any) {
    const url = `/my/farms/${farmId}/grow-crops`;
    return this.post(url,params);
  }
  havest(farmId: number){
    const url =  `/my/farms/${farmId}/harvest`;
    return this.post(url,{});
  }
  getLevelUp(farmId: number) {
    const url = `/my/farms/${farmId}/levelup`;
    return this.get(url, {});
  }
}
