<template>
  <b-modal id="enhance-modal" size="xl" @show="clearDefault" hide-footer hide-header centered>
    <div class="title">Choose bunicorn to enhance</div>
    <div class="close" @click="$emit('close-modal')">x</div>
    <div class="search-filter">
      <div class="stars-filter">
        <div
          v-for="starNumber in [1, 2, 3, 4, 5]"
          :key="starNumber"
          @click="filterStar(starNumber)"
          @mouseover="hoverStarFilter = starNumber"
          @mouseout="hoverStarFilter = 0"
          :class="
            hoverStarFilter <= starFilter &&
            starFilter > starNumber - 1 &&
            (hoverStarFilter > starNumber - 1 || hoverStarFilter == 0)
              ? 'active-star'
              : hoverStarFilter > starNumber - 1 &&
                hoverStarFilter >= starFilter
              ? 'active-star'
              : ''
          "
        />
      </div>
      <div class="clear-filter sketchy" @click="clearFilter">Clear filter</div>
    </div>
    <div class="content-wrapper">
      <template v-if="bunicorns.length > 0">
        <div
          class="bunicorn-card"
          :class="selectedBunicorn === bunicorn.bunicornId ? 'active' : ''"
          v-for="bunicorn in bunicorns"
          :key="bunicorn.bunicornId"
          @click="selectBunicorn(bunicorn.bunicornId)"
        >
          <img :src="bunicorn.metaData.medium_image" />
          <div class="info-wrapper">
            <div class="id">ID: {{ bunicorn.bunicornId }}</div>
            <div class="name">{{ bunicorn.metaData.name}}</div>
          </div>
          <div class="info-wrapper">
            <div class="id">Level: {{ bunicorn.level + 1 }}</div>
          </div>
        </div>
      </template>
      <template v-else >
        <div class="blank-state">No data</div>
      </template>
    </div>
    <div class="pagination-wrapper">
      <b-pagination
        v-model="page"
        :total-rows="totalItem"
        :per-page="limit"
      ></b-pagination>
    </div>
    <div class="footer-wrapper">
      <div
        id='confirm-enhance-bunicorn'
        class="confirm-btn sketchy"
        :class="isDisable || isCapped ? 'disable' : ''"
        @click="enhanceBunicorn"
      >
        <p>CONFIRM</p>
        <p v-if="enhanceFee > 0">{{this.enhanceFee | formatWeiAmount}} BUR</p>
      </div>
      <b-tooltip v-if="!selectedBunicorn" target="confirm-enhance-bunicorn" triggers="hover">
        Please choose a bunicorn
      </b-tooltip>
      <b-tooltip v-else-if="isCapped" target="confirm-enhance-bunicorn" triggers="hover">
        Selected bunicorn has max enhance
      </b-tooltip>
      <div
        class="cancel-btn sketchy"
        @click="$emit('close-modal')"
      >
        CANCEL
      </div>
    </div>
  </b-modal>
</template>

<script>
import rf from '@/requests/RequestFactory';
import { cleanObject } from '@/utils/helpers';
import { mapState } from 'vuex';
import BigNumber from 'bignumber.js';

export default {
  props: {
    bunicornId: {
      default: null
    },
    bunicornElement: {
      default: null
    },
    bunicornStar: {
      default: null
    }
  },
  data(){
    return {
      bunicorns: [],
      hoverStarFilter: 0,
      starFilter: '',
      selectedBunicorn: null,
      enhanceFee: -1,
      enhanceFailureRatio: '',
      isCapped: false,
      minimumEnhanceLevel: 0,
      page: 1,
      totalItem: 0,
      limit: 4,
    };
  },
  computed: {
    ...mapState('account', ['profile']),
    burBalance() {
      return this.profile.burInGame;
    },
    isDisable() {
      return !this.selectedBunicorn;
    },
  },
  watch: {
    page() {
      this.fetchData();
    },
    selectedBunicorn(){
      this.fetchEnhanceFee();
    },
    starFilter(){
      this.fetchData();
    },
  },
  methods: {
    cleanObject,
    clearDefault(){
      this.page = 1;
      this.totalItem = 0;
      this.hoverStarFilter = 0;
      this.starFilter = '';
      this.selectedBunicorn = null;
      this.bunicorns = [];

      this.fetchData();
    },
    clearFilter(){
      this.page = 1;
      this.starFilter = '';
    },
    filterStar(starNumber) {
      this.starFilter = Math.min(this.bunicornStar + 1, Number(starNumber)).toString();
    },
    selectBunicorn(bunicornId) {
      this.selectedBunicorn = this.selectedBunicorn === bunicornId ? null : bunicornId;
    },
    async fetchEnhanceFee(){
      if(!this.selectedBunicorn){
        this.enhanceFee = -1;
        return;
      }
      const res = await rf.getRequest('BunicornRequest').getEnhanceFee(this.bunicornId, this.selectedBunicorn);
      if(res){
        this.enhanceFee = res.burRequired;
        this.isCapped = res.isCapped;
      }
    },
    async fetchData() {
      const params = {
        page: this.page,
        limit: 4,
        starsFrom: this.starFilter
          ? Number(this.starFilter) - 1 > this.bunicornStar ? this.bunicornStar : Number(this.starFilter) - 1
          : 0,
        starsTo: this.starFilter
          ? Number(this.starFilter) - 1 > this.bunicornStar ? this.bunicornStar : Number(this.starFilter) - 1
          : this.bunicornStar,
        inBuniHouse: false,
        enhanced: this.bunicornId
      };
      // Change API later
      const res = await rf.getRequest('IsLandRequest').getAvailableForEnhanceBunicorns(this.cleanObject(params));
      if (res) {
        this.bunicorns = res.docs;
        this.totalItem = res.totalDocs;
      }
    },
    async enhanceBunicorn(){
      if(this.isDisable || this.isCapped) return;
      if(new BigNumber(this.burBalance).comparedTo(this.enhanceFee) < 0){
        this.$dialog.notify.warning('Your BUR balance is not enough.');
        return;
      }
      try {
        const res = await rf.getRequest('IsLandRequest')
          .enhanceBunicorn(this.bunicornId, this.selectedBunicorn);
        if (res) {
          this.$dialog.notify.success(`You've enhanced bunicorn ${this.bunicornId}!`);
          this.$store.dispatch('account/getUserProfile');
          this.$emit('onEnhanceBunicornSuccessfully');
        }else {
          this.$dialog.notify.error('An error has occurred. Please try again!');
        }
      } catch (error) {
        this.$dialog.notify.error(error);
      }
    },
    redirectToBunicornGame() {
      if(this.bunicornId === null || this.bunicornElement === null || this.bunicornStar === null) return;
      const routeData = process.env.VUE_APP_BUNICORN_GAME
        + `bunicorns/detail?id=${this.bunicornId}&element=${this.bunicornElement}&stars=${this.bunicornStar}`;
      window.open(routeData, '_blank');
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/config/_variables";

#enhance-modal {
  .title {
    font-size: 2.5rem;
    text-align: center;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px;
    font-size: 1.25rem;
    cursor: pointer;
  }
  .search-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px;
  .stars-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    & > div {
      margin: 0 3px;
      background-image: url('../assets/icon/star.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: 0.5s ease;
      width: 22px;
      height: 22px;
    }
    & > div:hover,
    .active-star {
      background-image: url('../assets/icon/rate-star.png') !important;
    }
    & > img {
      margin: 0 1px;
      opacity: 0.4;
      cursor: pointer;
      transition: 0.5s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
  .clear-filter {
    padding: 8px;
    width: 120px;
    text-transform: uppercase;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    &:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
  }
}
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .custom-btn {
      margin: 10px 20px 0;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: #e0e0e0;
      }
      &.disable {
        cursor: not-allowed;
        opacity: 0.7;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    padding: 20px 0;
    overflow-x: auto;
    .bunicorn-card {
      width: 20%;
      min-width: 210px;
      margin: 0 8px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 5px 0 #000;
        transform: scale(1.05);
      }
      &.active {
        box-shadow: 1px 1px 5px 0 #000;
        transform: scale(1.05);
      }
      img {
        width: 100%;
        height: auto;
        margin: 0 auto 20px auto;
        object-fit: contain;
      }
      .info-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .blank-state {
      height: 150px;
      font-size: 2rem;
      display: flex;
      align-items: center;
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .confirm-btn,
    .cancel-btn {
      margin: 0 8px;
      padding: 8px;
      min-width: 140px;
      min-height: 86px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 8px;
      font-size: 1.4rem;
      cursor: pointer;
      &:hover {
        background-color: #e0e0e0;
      }
      &.disable {
        cursor: not-allowed;
        &:hover {
          background-color: #ffffff;
        }
      }
      p{
        margin: 0;
        padding: 0;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-desktop) {
  #enhance-modal {
    .content {
      padding: 30px 20px;
    }
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  #enhance-modal {
    .content-wrapper {
      justify-content: flex-start;
    }
  }
}
</style>
