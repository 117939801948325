<template>
  <b-modal
    v-model="modalShow"
    centered
    id="sign-up-account-popup"
    hide-footer
    hide-header
  >
    <div class="popup-title">REGISTER ACCOUNT</div>
    <div class="popup-body">
      <div>Username</div>
      <div class="popup-input-wrapper">
        <input
          v-model="username"
          type="text"
          placeholder="Username"
          class="form-control inputModel"
        />
        <div class="max-button sketchy" @click="checkUsername()">Check</div>
      </div>
      <div>Password</div>
      <input
        v-model="password"
        type="password"
        placeholder="Password"
        class="form-control inputModel"
      />
      <div>Retype Password</div>
      <input
        v-model="retypePassword"
        type="password"
        placeholder="Retype Password"
        class="form-control inputModel"
      />
      <div v-html="modalDescriptions()" class="popup-description"></div>
      <div class="popup-button-wrapper">
        <custom-button
          @handleClick="$bvModal.hide('sign-up-account-popup')"
        >
          Cancel
        </custom-button>
        <custom-button
          @handleClick="signUpAccount()"
          :isDisabled="disableConfirm()"
          :isLoading="isSigningUp"
        >
          Confirm
        </custom-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { handleTransactionError } from '@/utils/helpers';
import CustomButton from '@/components/CustomButton.vue';
import rf from '@/requests/RequestFactory';
import { validUsername, validPassword } from '@/utils/authentication';

export default {
  data() {
    return {
      modalShow: false,
      isSigningUp: false,
      username: '',
      password: '',
      retypePassword: '',
      isUsernameInUse: false,
      isCheckedUsername: false,
      isInvalid: {
        username: false,
        password: false,
        retypePassword: false
      }
    };
  },
  watch: {
    modalShow() {
      this.isSigningUp = false;
      this.username = '';
      this.password = '';
      this.retypePassword = '';
      this.isUsernameInUse = false;
      this.isInvalid.username = false;
      this.isInvalid.password = false;
      this.isInvalid.retypePassword = false;
    },
    username() {
      if(!validUsername.test(this.username)) {
        this.isInvalid.username = true;
      } else {
        this.isInvalid.username = false;
      }
      this.isCheckedUsername = false;
      this.isUsernameInUse = false;
    },
    password() {
      if(!validPassword.test(this.password)) {
        this.isInvalid.password = true;
      } else {
        this.isInvalid.password = false;
      }
      if(this.password !== this.retypePassword) {
        this.isInvalid.retypePassword = true;
      } else {
        this.isInvalid.retypePassword = false;
      }
    },
    retypePassword() {
      if(this.retypePassword !== this.password) {
        this.isInvalid.retypePassword = true;
      } else {
        this.isInvalid.retypePassword = false;
      }
    },
  },
  components: { CustomButton },
  methods: {
    async checkUsername() {
      try {
        const res = await rf.getRequest('AuthenticationRequest').checkExistedUsername(this.username);
        if (res) {
          this.isCheckedUsername = true;
          this.isUsernameInUse = res.isExist;
        }
      } catch (error) {
        const msg = handleTransactionError(error);
        this.$dialog.notify.error(msg);
      }
    },
    async signUpAccount() {
      try {
        this.isSigningUp = true;
        const params = {
          username: this.username,
          password: this.password
        };
        const res = await rf.getRequest('AuthenticationRequest').updateUserAccount(params);
        if (res) {
          this.$bvModal.hide('sign-up-account-popup');
          this.$dialog.notify.success('You have signed up successfully');
          this.$emit('refreshUsername');
        }
      } catch (error) {
        const msg = handleTransactionError(error);
        this.$dialog.notify.error(msg);
      }
      this.isSigningUp = false;
    },
    disableConfirm() {
      return !this.username
          || !this.password
          || !this.retypePassword
          || this.isInvalid.username
          || this.isInvalid.password
          || this.isInvalid.retypePassword
          || this.isUsernameInUse
          || !this.isCheckedUsername
          || this.isSigningUp;
    },
    modalDescriptions() {
      if (!this.username) {
        return '<span style="color: #FF5A3A;">Username can not be blank</span>';
      }
      if (this.isInvalid.username) {
        return '<span style="color: #FF5A3A;">' +
          'Invalid username. Username must be between 6-50 characters length'
          + '</span>';
      }
      if (!this.isCheckedUsername) {
        return '<span style="color: #FF5A3A;">Please check your username to continue</span>';
      }
      if (this.isUsernameInUse) {
        return '<span style="color: #FF5A3A;">Username is already in use</span>';
      }
      if (!this.password) {
        return '<span style="color: #FF5A3A;">Password can not be blank</span>';
      }
      if (this.isInvalid.password) {
        return '<span style="color: #FF5A3A;">' +
          'Invalid password. Password must be between 6-50 characters length'
          + '</span>';
      }
      if (this.isInvalid.retypePassword || (!this.retypePassword)) {
        return '<span style="color: #FF5A3A;">Unmatched retype password</span>';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/config/_variables';

.max-button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 26px;
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  top: 26px;
  transform: translate(-50%, -50%);
  right: -32px;
  &:hover {
    background-color: #e0e0e0;
  }
}

::v-deep #sign-up-account-popup {
  & > .modal-dialog {
    width: 477px;
    height: 380px;
  }
  .popup-title {
    font-size: 2.5rem;
    text-align: center;
  }
  .popup-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.5rem;
    margin-top: 16px;
    .warning {
      color: #dbdb00;
      background-color: #ffff0036;
      border-radius: 4px;
      padding: 8px 16px;
    }
    .popup-input-wrapper {
      position: relative;
      .inputModel {
        margin: 8px 0;
        border-radius: 10px;
        text-transform: initial;
        padding-right: 88px;
      }
    }
    .inputModel {
      margin: 8px 0;
      border-radius: 10px;
      text-transform: initial;
      padding-right: 88px;
    }
    .popup-description {
      padding: 0 8px;
      font-style: italic;
      text-align: center;
      height: 72px;
    }
    .popup-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        margin: 20px 8px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  ::v-deep #sign-up-account-popup{
    & > .modal-dialog {
      width: 100%;
      max-width: 100%;
      padding: 0.5rem;
      margin: 0rem;
    }
    .popup-body {
    .warning {
      padding: 4px 8px;
    }
      .popup-radio-form {
        margin-top: 20px;
      }
      .popup-button-wrapper {
        margin-top: 0px;
      }
    }
  }
}
</style>
