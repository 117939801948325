import BaseRequest from './BaseRequest';
import config from '@/config';

export default class BunicornRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.bunicornApi;
  }
  getRequirementFood(bunicornId: number) {
    const url =  `/my/bunicorns/${bunicornId}/requirement-food`;
    return this.get(url,{});
  }
  feed(bunicornId: number){
    const url = `/my/bunicorns/${bunicornId}/feed-bunicorn`;
    return this.post(url,{});
  }
  getTrainedSkills(bunicornId: number){
    const url = `/public/bunicorns/${bunicornId}/trained-skills`;
    return this.get(url,{});
  }
  getEnhanceFee(bunicornId: number, burnId: number){
    const url = `/public/bunicorns/${bunicornId}/enhance-fee`;
    return this.get(url,{burnIds: burnId});
  }
}
