<template>
  <div>
    <base-detail
      v-if="trainerData"
      :imageUrl="trainerData.metaData.image"
      :id="trainerId"
      :name="trainerData.metaData.name"
      @handleBackButton="goToBuniHouse()"
      :isWarningHalvingRate="trainerData.halvingRate && trainerData.halvingRate.halvingWarning"
    >
      <template #more-info>
        <span class="level-wrapper">
          (Level {{ trainerData.level + 1 }})
          <span :class="`element ${trainerData.element.toLowerCase()}`" />
        </span>
      </template>
      <template #more-action>
        <div class="info-wrapper">
          <div>
            Xp: {{ trainerData.xp }}/{{ getXpBylevel(trainerData.level) }}
          </div>
          <div>Xp Claimable: {{ trainerData.exp }}</div>
          <div>Stamina: {{ trainerData.sta }}/{{ trainerData.maxSta }}</div>
        </div>
        <div class="info-wrapper second-row">
          <div>Baseline Rate: {{ currentBaseline | formatNumber }}</div>
          <div class="avoid-halving" v-if="trainerData.buniHouseId">
            <div>
              <div>
                Next Halving Countdown:
                <countdown
                  :date="
                    new Date(trainerData.halvingRate.currentMilestoneEnd * 1000)
                  "
                  :isPaused="trainerData.halvingRate.isPauseHalvingCountDown"
                ></countdown>
              </div>
              <div>
                Next Halving Baseline Rate:
                {{ baselineAfterHalving | formatNumber }}
              </div>
            </div>
            <!-- <div
              class="custom-btn sketchy"
              :class="disableAvoidHalving ? 'disable' : ''"
              @click="avoidHalvingRate"
              v-b-tooltip.hover.top="tooltipAvoidHalving"
            >
              Avoid Halving
              <template v-if="!isSkipHalving">
                ({{
                  trainerData.halvingRate.burWeiNeedToAvoid | formatWeiAmount
                }}
                BUR)
              </template>
            </div> -->
          </div>
        </div>
        <div v-if="trainerId > 0" class="info-wrapper second-row">
        Mentoring Time: {{ trainerData.mentoringCount }}
        </div>
        <div class="button-wrapper">
          <!--          <div class="custom-btn sketchy">Sell</div>-->
          <!-- <div
            v-if="trainerData.buniHouseId"
            class="custom-btn sketchy"
            @click="$bvModal.show('select-buni-house-modal')"
          >
            Move
          </div> -->
          <!-- <div class="custom-btn sketchy" @click="openFusionClaimXpTrainerModal('claim xp')">
            Claim XP
          </div> -->
          <!-- <div id="fusion-trainer"
            v-if="trainerId > 0"
            class="custom-btn sketchy"
            :class="trainerData.hasFused ? 'disable' : ''"
            @click="openFusionClaimXpTrainerModal('fusion')"
          >
            Fusion
          </div> -->
          <b-tooltip v-if="trainerData.hasFused" target="fusion-trainer" triggers="hover">
            The trainer has fused for this milestone
          </b-tooltip>
<!--           <div
            v-if="trainerId > 0"
            class="custom-btn sketchy"
            @click="$bvModal.show('direct-sell-nft-modal')"
          >
            Sell
          </div> -->
        </div>
      </template>
    </base-detail>
    <select-buni-house-modal
      v-if="trainerId && trainerData"
      :buniHouseIdOld="trainerData.buniHouseId"
      :name="'Trainer'"
      :moveNft="moveTrainer"
      @onMove="fetchData(trainerId)"
      @close-modal="$bvModal.hide('select-buni-house-modal')"
    />
    <fusion-claim-xp-trainer-modal
      v-if="trainerId && trainerData"
      @close-modal="$bvModal.hide('fusion-claim-xp-modal')"
      @onFuseTrainersSuccessfully="goToBuniHouse"
      :action="fusionClaimXpAction"
      :trainerId="trainerId"
      :trainerElement="trainerData.element"
      :trainerLevel="trainerData.level"
    />
    <DirectSellingNftModal
      v-if="trainerId && trainerData"
      :nftType="'trainer'"
      :typeDisplay="'Trainer'"
      :buniHouseId="trainerData.buniHouseId"
      :inGameNftId="trainerData.trainerId"
      @onSellNftSuccess="goToBuniHouse"
    />
  </div>
</template>
<script>
// import { TRAINER_DATA } from '@/contants';
import BaseDetail from '@/components/BaseDetail.vue';
import rf from '@/requests/RequestFactory';
import SelectBuniHouseModal from '@/components/SelectBuniHouseModal.vue';
import { convertWeiToDec } from '@/utils/helpers';
import Countdown from '@/components/CountDown.vue';
import BigNumber from 'bignumber.js';
import { getXpBylevel } from '@/utils/utils';
import * as _ from 'lodash';
import FusionClaimXpTrainerModal from '@/components/FusionClaimXpTrainerModal.vue';
import DirectSellingNftModal from '@/components/DirectSellingNftModal.vue';

export default {
  components: { BaseDetail, Countdown, SelectBuniHouseModal, DirectSellingNftModal, FusionClaimXpTrainerModal },
  data() {
    return {
      trainerData: null,
      trainerId: null,
      fusionClaimXpAction: null
    };
  },
  computed: {
    burBalance() {
      return _.get(this.$store,'state.account.profile.burInGame',0);
    },
    tooltipAvoidHalving() {
      if (!this.trainerData) return 'Can not get avoid halving fee.';
      if (!this.burBalance) return 'Can not get your balance';
      if (
        new BigNumber(this.burBalance).comparedTo(
          this.trainerData.halvingRate.burWeiNeedToAvoid
        ) < 0
      ) {
        return 'You don\'t have enough fee to avoid halving.';
      }
      return '';
    },
    disableAvoidHalving() {
      if (!this.trainerData || !this.burBalance || this.trainerData.halvingRate.isPauseHalvingCountDown) return true;
      return (
        new BigNumber(this.burBalance).comparedTo(
          this.trainerData.halvingRate.burWeiNeedToAvoid
        ) < 0
      );
    },
    currentBaseline() {
      if (!this.trainerData) {
        return 0;
      }
      return this.trainerData.baseline * this.trainerData.halvingRate.rate;
    },
    baselineAfterHalving() {
      if (!this.trainerData || !this.trainerData.halvingRate) {
        return 0;
      }
      return this.trainerData.baseline * this.trainerData.halvingRate.nextRate;
    },
    isSkipHalving() {
      if (!this.trainerData || !this.trainerData.halvingRate) {
        return false;
      }
      return (
        new BigNumber(this.trainerData.halvingRate.nextRate).comparedTo(
          this.trainerData.halvingRate.rate
        ) === 0
      );
    }
  },
  methods: {
    getXpBylevel,
    convertWeiToDec,
    async moveTrainer(buniHouseId) {
      const params = {
        trainerIds: [Number(this.trainerId)],
        buniHouseIdOld: this.trainerData.buniHouseId,
        buniHouseIdNew: buniHouseId,
      };
      return await rf.getRequest('TrainerRequest').move(params);
    },
    async fetchData(trainerId) {
      const res = await rf.getRequest('TrainerRequest').getMyTrainer(trainerId);
      if (res) {
        this.trainerData = res;
      }
    },
    async avoidHalvingRate() {
      if (this.disableAvoidHalving) return;
      if (this.isSkipHalving) {
        return this.$dialog.notify.warning(
          'You skipped the halving success rate this time, please wait for the next halving'
        );
      }
      try {
        const res = await rf
          .getRequest('TrainerRequest')
          .avoidHalvingRate(this.trainerId);
        if (res) {
          await this.$store.dispatch('account/getUserProfile');
          await this.fetchData(this.trainerId);
          this.$dialog.notify.success('You\'ve avoid halving rate successfully');
        }
      } catch (error) {
        this.$dialog.notify.error(error);
      }
    },
    goToBuniHouse() {
      return this.$router.back();
    },
    openFusionClaimXpTrainerModal(action) {
      if(action === 'fusion' && this.trainerData.hasFused)
        return;
      this.fusionClaimXpAction = action;
      this.$bvModal.show('fusion-claim-xp-modal');
    }
  },
  created() {
    this.trainerId = this.$route.query.id;
  },
  async mounted() {
    await this.fetchData(this.trainerId);
  },
};
</script>
<style lang="scss" scoped>
@import '@/config/_variables';
::v-deep .name {
  display: flex;
  align-items: center;
  .level-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;
    .element {
      margin-left: 8px;
      margin-top: -8px;
    }
  }
}
.info-wrapper,
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-wrapper {
  font-size: 1.5rem;
  width: 100%;
  &.first-row {
    margin-top: 30px;
  }
  &.second-row {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .avoid-halving {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .custom-btn {
      width: fit-content;
      padding: 4px 20px;
      font-size: 1rem;
      border-radius: 8px;
      cursor: pointer;
      &.disable {
        cursor: not-allowed;
        &:hover {
          background: #fff;
        }
      }
      &:hover {
        background-color: #e0e0e0;
      }
    }
    div {
      margin: 0 10px;
    }
  }
}
.button-wrapper {
  .custom-btn {
    margin: 30px 20px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0;
    border-radius: 8px;
    cursor: pointer;
    &.disable {
      cursor: not-allowed;
      &:hover {
        background: #fff;
      }
    }
    &:hover {
      background-color: #e0e0e0;
    }
  }
}
@media screen and (max-width: $breakpoint-tablet) {
  .button-wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    .custom-btn {
      margin: 10px 10px;
    }
  }
}
</style>
