const prefixKey = `${process.env.VUE_APP_NETWORK}-${process.env.VUE_APP_ENV}`;
let suffix;
if (process.env.VUE_APP_VERSION_DEPLOY) {
  suffix = process.env.VUE_APP_VERSION_DEPLOY;
}

const PREFERENCES = `${prefixKey}-buni-preferences-display-info-${suffix}`;

interface Preferences {
  connectorId: string | null,
  auth: any | null,
  referralId: any | null,
}

function getPreferences(): Preferences {
  const defaultPreferences = {
    connectorId: null,
    auth: {
      token: null,
      address: null
    },
    referralId: null,
  };
  const preferenceString = localStorage.getItem(PREFERENCES);
  const preferences = JSON.parse(preferenceString || '{}');
  return {
    ...defaultPreferences,
    ...preferences
  };
}

export default class Storage {
  static getConnectorId(): string | null {
    const preferences = getPreferences();
    return preferences.connectorId;
  }

  static getReferralId(): string | null {
    const preferences = getPreferences();
    return preferences.referralId;
  }

  static saveConnectorId(connectorId: string | null) {
    const preferences = getPreferences();

    preferences.connectorId = connectorId;
    localStorage.setItem(PREFERENCES, JSON.stringify(preferences));
  }

  static saveReferralId(referralId: any) {
    const preferences = getPreferences();

    preferences.referralId = referralId;
    localStorage.setItem(PREFERENCES, JSON.stringify(preferences));
  }

  static removeWalletLinkInfo() {
    localStorage.removeItem('-walletlink:https://www.walletlink.org:session:id');
    localStorage.removeItem('-walletlink:https://www.walletlink.org:session:secret');
    localStorage.removeItem('-walletlink:https://www.walletlink.org:session:linked');
    localStorage.removeItem('-walletlink:https://www.walletlink.org:Addresses');
  }

  static removeWalletConnect() {
    localStorage.removeItem('walletconnect');
  }

  static saveToken(token: string, address: string) {
    const preferences = getPreferences();
    preferences.auth = {
      token,
      address
    };
    localStorage.setItem(PREFERENCES, JSON.stringify(preferences));
  }

  static removeAuth() {
    const preferences = getPreferences();
    preferences.auth = null;
    localStorage.setItem(PREFERENCES, JSON.stringify(preferences));
  }
  static removeAccessToken() {
    const preferences = getPreferences();
    if (preferences.auth && preferences.auth.token) {
      preferences.auth.token = null;
    }
    localStorage.setItem(PREFERENCES, JSON.stringify(preferences));
  }
  static getToken() {
    const auth = Storage.getAuth();
    if (auth) {
      return auth.token;
    }
    return null;
  }

  static getAuth() {
    const preferences = getPreferences();
    return preferences.auth;
  }
}
