<template>
  <b-modal
    hide-footer
    hide-header
    id="account-modal"
    size="md"
    @show="isShowConnectList = false"
    centered
  >
    <div class="connect-wallet-modal">
      <div class="title">
        {{
          isShowConnectList || !myAccount || wrongNetwork
            ? "CONNECT TO A WALLET"
            : "ACCOUNT"
        }}
      </div>
      <div class="close-icon" @click="$emit('close-connect-wallet-modal')" >x</div>
      <div
        v-if="isShowConnectList || !myAccount || wrongNetwork"
        class="list-connect"
      >
        <a
          v-for="(connector, index) in connectors"
          :key="index"
          target="_blank"
        >
          <div class="connector sketchy" @click="handleLogin(connector.id)">
            <div class="connector-icon">
              <img
                :src="`/images/connectors/${connector.id}.png`"
                height="21"
                width="21"
              />
            </div>
            <div class="connector-name">
              {{ connector.name }}
            </div>
          </div>
        </a>
      </div>
      <div v-else class="list-connect">
        <a :href="_etherscanLink(myAccount)" target="_blank">
          <div class="connector sketchy">
            <span v-text="shortenAddress(myAccount)" class="link" />
          </div>
        </a>
        <div class="connector sketchy" @click="isShowConnectList = true">
          Connect To A Wallet
        </div>
        <router-link :to="{ name: '#' }"> </router-link>
        <div class="connector sketchy" @click="handleLogout">Logout</div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import config from '@/config';
import { mapState} from 'vuex';

export default {
  data() {
    return {
      config,
      isShowConnectList: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState('account', [
      'account',
      'currentNetworkId',
    ]),
    myAccount() {
      return this.account.address;
    },
    wrongNetwork() {
      return this.account.wrongNetwork;
    },
    connectors() {
      const connectors = [];

      for (const connector in config.configWallet.connectors) {
        if (
          this.isMobileScreen &&
          (connector === 'bsc' || connector === 'mathwallet')
        ) {
          continue;
        }
        connectors.push(config.configWallet.connectors[connector]);
      }
      return connectors;
    },
    isConnected() {
      return this.account && this.account.address;
    },
  },
  methods: {
    async handleLogin(connectorId) {
      this.$emit('close-connect-wallet-modal');
      this.isShowConnectList = false;
      if (
        connectorId === 'metamask' &&
        (!window.ethereum || !window.ethereum.isMetaMask)
      ) {
        const link = document.createElement('a');
        link.href = config.metamaskDeeplink;
        document.body.appendChild(link);
        link.click();
        return;
      }
      await this.$store.dispatch('account/connect',connectorId);
    },
    async handleLogout() {
      this.$emit('close-connect-wallet-modal');
      await this.$store.dispatch('account/disconnect');
    },
    shortenAddress(str) {
      return str ? `${str.slice(0, 6)}...${str.slice(str.length - 4)}` : str;
    },
    _etherscanLink(str, type = 'address') {
      return `${config.configWallet.explorer}/${type}/${str}`;
    },
  }

};
</script>
<style lang="scss" scoped>
.connect-wallet-modal {
  .title {
    font-size: 1.5rem;
  }
  .close-icon {
    width: 22px;
    height: 22px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    position: absolute;
    text-align: center;
    line-height: 1;
  }
  .list-connect {
    margin-top: 14px;

    .connector {
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #000;
      padding: 4px 10px;
      margin: 10px 8px;
      cursor: pointer;
      &:hover {
        background-color: #6c6868;
        color: #ffffff;
      }
      .connector-name {
        font-size: 16px;
      }
      .connector-icon {
        margin-right: 8px;
      }
    }
  }
}
</style>
