import BaseRequest from './BaseRequest';
import config from '@/config';

export default class TrainingCenterRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getDetail(trainingCenterId: number) {
    const url = `/my/training-centers/${trainingCenterId}`;
    return this.get(url, {});
  }
  getSpeedUpCostTrainingCenter(trainingCenterId: number) {
    const url = `/my/training-centers/${trainingCenterId}/speedup`;
    return this.get(url, {});
  }
  getSpeedupCostForTrainingBunicorn(trainingCenterId: number) {
    const url = `/my/training-centers/${trainingCenterId}/speedup-train`;
    return this.get(url, {});
  }
  speedUp(trainingCenterId: number) {
    const url = `/my/training-centers/${trainingCenterId}/speedup`;
    return this.patch(url,{});
  }
  speedUpTraining(trainingCenterId: number) {
    const url = `/my/training-centers/${trainingCenterId}/speedup-train`;
    return this.patch(url, {});
  }
  trainSkill(trainingCenterId: number, params: any) {
    const url = `/my/training-centers/${trainingCenterId}/training`;
    return this.patch(url, params);
  }
  claimSkill(trainingCenterId: number) {
    const url = `/my/training-centers/${trainingCenterId}/claim-skill`;
    return this.patch(url, {});
  }
  moveTrainingCenter(trainingCenterId: number, params: any) {
    const url = `/my/training-centers/${trainingCenterId}/move-building`;
    return this.patch(url, params);
  }
  getTrainingSkills(bunicornId: number){
    const url = `/my/training-centers/${bunicornId}/training-skills`;
    return this.get(url,{});
  }
  getResetSkillPrice(bunicornId: number) {
    const url = `/my/bunicorns/${bunicornId}/reset-skill`;
    return this.get(url,{});
  }
  resetSkill(bunicornId: number) {
    const url = `/my/bunicorns/${bunicornId}/reset-skill`;
    return this.patch(url,{});
  }
}
