import BaseRequest from './BaseRequest';
import config from '@/config';

export default class UserRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getMyTrainers(params: any) {
    const url = '/my/trainers';
    return this.get(url, params);
  }
  getMyBunicorns(params: any) {
    const url = '/my/bunicorns';
    return this.get(url, params);
  }
  getMyIslands() {
    const url = '/my/islands';
    return this.get(url, {});
  }
  getMyBuniHouses(){
    const url = '/my/buni-houses';
    return this.get(url,{});
  }
  getUserProfile(){
    const url = '/my/users/profile';
    return this.get(url, {});
  }
  getDefeatedBunicorn(){
    const url = '/my/users/defeated-bunicorn';
    return this.get(url, {});
  }
  openChest(){
    const url = '/my/users/open-chest';
    return this.post(url, {});
  }
  getMyBunicornInGameV2(params: any) {
    const url = '/v2/my/bunicorns/in-game';
    return this.get(url, params);
  }
  getMyTrainerInGameV2(params: any) {
    const url = '/v2/my/trainers/in-game';
    return this.get(url, params);
  }
  getMyBunicornsSignatureWithdraw(params: any) {
    const url = '/my/bunicorns/signature-withdraw-many';
    return this.post(url, params);
  }
  getMyTrainersSignatureWithdraw(params: any) {
    const url = '/my/trainers/signature-withdraw-many';
    return this.post(url, params);
  }
}
