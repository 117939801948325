<template>
  <b-modal
    v-model="modalShow"
    centered
    id="deposit-buni-popup"
    hide-footer
    hide-header
  >
    <div class="popup-title">DEPOSIT BUNI</div>
    <div v-if="buniBalance" class="buni-balance">BUNI Onchain Balance: {{buniBalance | formatNumber}}</div>
    <div class="popup-body">
      <div class="popup-input-wrapper">
        <input
          v-model="itemAmount"
          type="number"
          placeholder="Amount"
          class="form-control inputModel"
        />
        <div class="max-button sketchy" @click="maxItem()">Max</div>
      </div>
      <div v-html="modalDescriptions()" class="popup-description"></div>
      <div class="warning">⚠️ The BUNI amount will be updated after 15 network confirmations.</div>
      <div class="popup-button-wrapper">
        <custom-button
          @handleClick="$bvModal.hide('deposit-buni-popup')"
        >
          Cancel
        </custom-button>
        <custom-button
          @handleClick="depositBuni()"
          :isDisabled="disableConfirm()"
          :isLoading="isDepositing"
        >
          Confirm
        </custom-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { convertWeiToDec, convertDecToWei } from '@/utils/helpers';
import { sleep, handleTransactionError } from '@/utils/helpers';
import { moveToOffAsset, fetchBuniBalance, stringIsNullOrEmpty } from '@/utils/utils';
import CustomButton from '@/components/CustomButton.vue';
import config from '@/config';

export default {
  data() {
    return {
      itemAmount: null,
      buniBalance: null,
      modalShow: false,
      isDepositing: false
    };
  },
  components: { CustomButton },
  watch: {
    async modalShow() {
      this.buniBalance = await fetchBuniBalance();
      this.buniBalance = convertWeiToDec(this.buniBalance);
      if(!this.modalShow) {
        this.itemAmount = null;
      }
    }
  },
  methods: {
    maxItem() {
      this.itemAmount = this.buniBalance;
    },
    async depositBuni() {
      if (this.buniBalance <= 0 || this.isDepositing) return;
      try {
        this.isDepositing = true;
        const res = await moveToOffAsset(config.addresses.buniTokenContract, convertDecToWei(this.itemAmount));
        if (res) {
          await sleep(12 * config.configWallet.blockTime);
          await this.$store.dispatch('account/getUserProfile');
          this.$bvModal.hide('deposit-buni-popup');
          this.$dialog.notify.success('You have deposited BUNI successfully');
        }
      } catch (error) {
        const msg = handleTransactionError(error);
        this.$dialog.notify.error(msg);
      }
      this.isDepositing = false;
    },
    disableConfirm() {
      return !this.itemAmount
          || Number(this.itemAmount) > this.buniBalance
          || Number(this.itemAmount) <= 0;
    },
    modalDescriptions() {
      if (Number(this.itemAmount) > this.buniBalance) {
        return '<span style="color: #FF5A3A;">The number of BUNI can not be greater than the maximum.</span>';
      }
      if (!stringIsNullOrEmpty(this.itemAmount) && Number(this.itemAmount) <= 0) {
        return '<span style="color: #FF5A3A;">Amount must be greater than 0.</span>';
      }
      return this.itemAmount ? 'Do you want to deposit <span style="color: #e59016;">' +
          this.itemAmount +
          ' BUNI?</span>' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/config/_variables';

.max-button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 26px;
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  top: 26px;
  transform: translate(-50%, -50%);
  right: -32px;
  &:hover {
    background-color: #e0e0e0;
  }
}

::v-deep #deposit-buni-popup {
  & > .modal-dialog {
    width: 477px;
    height: 380px;
  }
  .popup-title {
    font-size: 2.5rem;
    text-align: center;
  }
  .buni-balance {
    margin-top: 20px;
    font-size: 1.5rem;
    text-align: center;
  }
  .popup-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.5rem;
    margin-top: 16px;
    .warning {
      color: #dbdb00;
      background-color: #ffff0036;
      border-radius: 4px;
      padding: 8px 16px;
    }
    .popup-input-wrapper {
      position: relative;
      .inputModel {
        margin: 8px 0;
        border-radius: 10px;
        text-transform: initial;
        padding-right: 88px;
      }

    }
    .popup-description {
      padding: 0 8px;
      font-style: italic;
      text-align: center;
      height: 72px;
    }
    .popup-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        margin: 20px 8px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  ::v-deep #deposit-buni-popup {
    & > .modal-dialog {
      width: 100%;
      max-width: 100%;
      padding: 0.5rem;
      margin: 0rem;
    }
    .popup-body {
    .warning {
      padding: 4px 8px;
    }
      .popup-radio-form {
        margin-top: 20px;
      }
      .popup-button-wrapper {
        margin-top: 0px;
      }
    }
  }
}
</style>
