import {
  FallbackProvider,
  StaticJsonRpcProvider
} from '@ethersproject-bsc/providers';
import config from '@/config';
import _ from 'lodash';
import Web3 from 'web3';
import {Web3Provider} from '@ethersproject/providers';

function getBscProviders() {
  const rpcUrls = _.shuffle(config.configWallet.rpcUrl);

  const providers: any = [];
  rpcUrls.forEach((rpcUrl, index) => {
    const provider = new StaticJsonRpcProvider(rpcUrl, config.configWallet.chainId);
    const priority = index + 1;
    providers.push({
      provider,
      priority,
      stallTimeout: 1250 + 100 * priority
    });
  });
  return providers;
}

const providers = getBscProviders();
let provider: any;
if (Web3.givenProvider) {
  provider = new Web3Provider(Web3.givenProvider);
} else {
  // @ts-ignore
  provider = new FallbackProvider(providers, 1);
}

export default provider;
