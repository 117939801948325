import BaseRequest from './BaseRequest';
import config from '@/config';

export default class BuniHouseRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.islandApi;
  }
  getLevelUp(buniHouseId: any) {
    const url = `/my/buni-houses/${buniHouseId}/levelup`;
    return this.get(url, {});
  }
  sell(buniHouseId: number){
    const url = `/my/buni-houses/${buniHouseId}/sell`;
    return this.del(url, {});
  }
  getHarvestBuniHouseInfo(buniHouseId: number) {
    const url = `/my/buni-houses/${buniHouseId}/harvest`;
    return this.get(url, {});
  }
  toggleAutoCollectMode(buniHouseId: number, params: any) {
    const url = `/my/buni-houses/${buniHouseId}`;
    return this.patch(url, params);
  }
}
